import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, Pair } from '@huckleberry/sdk'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FINN, STAKE_TOM_EARN_FINN_PID, TOM } from '../../constants'

import { useActiveWeb3React } from '../../hooks'
import { useTrackedTokenPairs } from '../user/hooks'
import { WANV2_PAIR_INTERFACE, BRIDGE_MINER_ADDRESS, FINNBAR_ADDRESS } from '../../constants/abis/bridge'
import { useMultipleContractSingleData, useSingleCallResult, useSingleContractMultipleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'
import { useBridgeMinerContract, useTokenContract, useExcursionContractV3 } from '../../hooks/useContract'
import { BigNumber } from '@ethersproject/bignumber'
import { useTokenBalance } from '../wallet/hooks'
import useLend from '../lend/hooks'
import BN from 'bignumber.js'

export const STAKING_GENESIS = 1606976660

export const REWARDS_DURATION_DAYS = 365*4

const defaultBigNum = new BN(0);

export interface StakingInfo {
  pid: number
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // when the period ends
  periodStart: Date | undefined
  // calculates a hypothetical amount of token distributed to the active account per second.
  allocPoint?: any
  name?: string
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount
}

export function usePoolInfo() {
  const bridgeMinerContract = useBridgeMinerContract()
  const poolLength = useSingleCallResult(bridgeMinerContract, 'poolLength').result?.toString()
  return useSingleContractMultipleData(
    bridgeMinerContract,
    'poolInfo',
    poolLength ? new Array(Number(poolLength)).fill(poolLength).map((_v, _i) => [_i]) : []
  )
}

export function useAllStakingRewardsInfo() {
  const { chainId } = useActiveWeb3React()
  const poolInfo = usePoolInfo()
  const lpTokenAddr = useMemo(() => poolInfo?.map(_v => _v.result?.lpToken).filter(v=>v && chainId && v !== FINNBAR_ADDRESS[chainId]), [poolInfo, chainId])
  const token1Info = useMultipleContractSingleData(lpTokenAddr, WANV2_PAIR_INTERFACE, 'token1')
  const token0Info = useMultipleContractSingleData(lpTokenAddr, WANV2_PAIR_INTERFACE, 'token0')
  const trackedTokenPairs = useTrackedTokenPairs()
  return useMemo(() => {
    const info: {
      [chainId in ChainId]?: {
        tokens: [Token, Token]
        stakingRewardAddress: string
      }[]
    } = {
      [ChainId.MOON_MAINNET]: [],
      [ChainId.MOON_TESTNET]: [],
      [ChainId.CLOVER_PARACHAIN]: [],
    }
    token1Info.forEach((_v, _i) => {
      if (!_v.result || !chainId) return
      const ret = trackedTokenPairs.find(
        val => token0Info[_i].result?.[0] === val[0].address && _v.result && val[1].address === _v.result[0]
      )
      if (ret) {
        info[chainId]?.push({
          tokens: [ret[0], ret[1]],
          stakingRewardAddress: lpTokenAddr[_i]
        })
      }
    })
    return info
  }, [chainId, lpTokenAddr, token0Info, token1Info, trackedTokenPairs])
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const poolInfo = usePoolInfo()
  const { chainId, account } = useActiveWeb3React()
  const bridgeMinerContract = useBridgeMinerContract()
  const allStakingRewards = useAllStakingRewardsInfo()
  const info = useMemo(() => {
    return chainId
      ? allStakingRewards[chainId]?.filter(stakingRewardInfo =>
          pairToFilterBy === undefined
            ? true
            : pairToFilterBy === null
            ? false
            : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
              pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
        ) ?? []
      : []
  }, [allStakingRewards, chainId, pairToFilterBy])

  const uni = chainId ? FINN[chainId] : undefined
  const lpTokenAddr = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const userInfoParams = useMemo(() => {
    if (account) {
      return lpTokenAddr.map(_v => {
        const pid = poolInfo.findIndex(val => val.result?.lpToken === _v)
        return [pid.toString(), account ?? undefined]
      })
    } else {
      return []
    }
  }, [account, lpTokenAddr, poolInfo])
  // get all the info from the staking rewards contracts
  const balances = useSingleContractMultipleData(bridgeMinerContract, 'userInfo', userInfoParams)
  const totalSupplies = useMultipleContractSingleData(lpTokenAddr, WANV2_PAIR_INTERFACE, 'balanceOf', [
    chainId ? BRIDGE_MINER_ADDRESS[chainId] : undefined
  ])
  const earnedAmounts = useSingleContractMultipleData(bridgeMinerContract, 'pendingReward', userInfoParams)
  const finnPerSecond = useSingleCallResult(bridgeMinerContract, 'finnPerSecond')
  const startTime = useSingleCallResult(bridgeMinerContract, 'startTime')
  const allEndTime = useSingleCallResult(bridgeMinerContract, 'allEndTime')
  const totalAllocPoint = useSingleCallResult(bridgeMinerContract, 'totalAllocPoint')

  const radix = useMemo(() => {
    if (
      startTime.result?.[0].lt((Date.now()/1000).toFixed(0)) && 
      (allEndTime.result?.[0].gt((Date.now()/1000).toFixed(0)))
    ) {
      return 1
    } else {
      return 0
    }
  }, [allEndTime, startTime])
  return useMemo(() => {
    if (!chainId || !uni) return []
    return lpTokenAddr.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = finnPerSecond
      const periodFinishState = allEndTime
      const periodStartState = startTime

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading &&
        periodStartState &&
        !periodStartState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error ||
          periodStartState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0

        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = new TokenAmount(
          uni,
          JSBI.BigInt(
            rewardRateState.result?.[0]
              .mul(radix)
              .mul(poolInfo[poolInfo.findIndex(val => val.result?.lpToken === rewardsAddress)].result?.allocPoint)
              ?.div(totalAllocPoint.result?.[0])
          )
        )

        const allocPoint = poolInfo[poolInfo.findIndex(val => val.result?.lpToken === rewardsAddress)].result?.allocPoint

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            uni,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)
        const periodFinishMs = periodFinishState.result?.[0]
          ?.mul(1000)
          ?.toNumber()

        const periodStartMs = periodStartState.result?.[0]
          ?.mul(1000)
          ?.toNumber()

        memo.push({
          pid: poolInfo.findIndex(val => val.result?.lpToken === rewardsAddress),
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          periodStart: periodStartMs > 0 ? new Date(periodStartMs) : undefined,
          earnedAmount: new TokenAmount(uni, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          allocPoint: allocPoint,
          getHypotheticalRewardRate
        })
      }
      return memo
    }, [])
  }, [
    chainId,
    uni,
    lpTokenAddr,
    balances,
    earnedAmounts,
    totalSupplies,
    finnPerSecond,
    allEndTime,
    startTime,
    info,
    radix,
    poolInfo,
    totalAllocPoint
  ])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? FINN[chainId] : undefined
  const stakingInfos = useStakingInfo()
  // const stakingTOMEearFINNInfo = useStakeTomEarnFinnInfo()
  const { personalAccount } = useLend()
  const lendDepositEarnFINNAmount = useMemo(() => {
    const lendUni = chainId ? FINN[chainId] : FINN[1024]
    const lendAmount = Number(personalAccount ? personalAccount.comp_reward : 0)
    const lendAmountRaw = new BN(lendAmount).multipliedBy(10 ** lendUni.decimals).toFixed(0)
    return new TokenAmount(lendUni, lendAmountRaw)
  }, [chainId, personalAccount])

  return useMemo(() => {
    if (!uni) return undefined
    let result =
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(uni, '0')
      ) ?? new TokenAmount(uni, '0')

    return result.add(lendDepositEarnFINNAmount) // .add(stakingTOMEearFINNInfo.earnedAmountInTokenAmount)

  }, [lendDepositEarnFINNAmount, stakingInfos,  uni])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const { t } = useTranslation()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = t('connectWallet')
  }
  if (!parsedAmount) {
    error = error ?? t('enterAnAmount')
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = t('connectWallet')
  }
  if (!parsedAmount) {
    error = error ?? t('enterAnAmount')
  }

  return {
    parsedAmount,
    error
  }
}

export function useFinnBarInfo() {
  const { account, chainId } = useActiveWeb3React()
  // const finnBar = useFinnBarContract()

  const tom = useTokenContract(chainId ? FINNBAR_ADDRESS[chainId] : undefined, false)
  const finn = useTokenContract(chainId ? FINN[chainId].address : undefined, false)

  const totalSupply: BigNumber = useSingleCallResult(tom, 'totalSupply')?.result?.[0]

  const finnStaked: BigNumber = useSingleCallResult(finn, 'balanceOf', [chainId ? FINNBAR_ADDRESS[chainId] : undefined])?.result?.[0]

  let times = finnStaked && totalSupply && (Number(finnStaked) / Number(totalSupply))

  const finnBalance = useTokenBalance(account ? account : undefined, chainId ? FINN[chainId] : undefined)

  const tomBalance = useTokenBalance(account ? account : undefined, chainId ? TOM[chainId] : undefined)

  // console.debug('!11 times', account, chainId, tom, finn, totalSupply, finnStaked, times, finnBalance, tomBalance)

  return {
    finnBalance,
    tomBalance,
    times,
  }
}

export function useStakeTomEarnFinnInfo() {
  const bridgeMinerContract = useBridgeMinerContract()
  const { account, chainId } = useActiveWeb3React()
  const pid = STAKE_TOM_EARN_FINN_PID[chainId ? chainId : 1024];

  // console.debug(account, pid)

  const poolInfo = useSingleContractMultipleData(
    bridgeMinerContract,
    'poolInfo',
    pid ? [[pid]] : []
  )

  const userInfo = useSingleContractMultipleData(account ? bridgeMinerContract : undefined, 'userInfo', [[pid, account?account:undefined]])

  const earnedAmounts = useSingleContractMultipleData(account ? bridgeMinerContract : undefined, 'pendingReward', [[pid, account?account:undefined]])
  const finnPerSecond = useSingleCallResult(bridgeMinerContract, 'finnPerSecond')
  const totalAllocPoint = useSingleCallResult(bridgeMinerContract, 'totalAllocPoint')
  const balance = useTokenBalance(account ? account : undefined, chainId ? TOM[chainId] : undefined)

  const totalStaked = useTokenBalance(chainId ? BRIDGE_MINER_ADDRESS[chainId] : undefined, chainId ? TOM[chainId] : undefined)

  const totalRewardRate = useMemo(()=>{
    if (!poolInfo[0] || !poolInfo[0].result || !finnPerSecond.result || !totalAllocPoint.result) {
      return new BN(0)
    }

    return new BN(finnPerSecond.result[0].toString()).multipliedBy(poolInfo[0].result.allocPoint.toString()).div(totalAllocPoint.result[0].toString()).multipliedBy(3600*24*7).div(1e18)
  }, [poolInfo, finnPerSecond, totalAllocPoint])


  const stakedAmount = useMemo(()=>{
    if (!userInfo[0]?.result) {
      return new BN(0)
    }

    return new BN(userInfo[0].result.amount.toString()).div(1e18)
  }, [userInfo])

  const calcNewRewardRate = (addAmount: any) => {
    const newRate = totalStaked && totalRewardRate ? totalRewardRate.multipliedBy(stakedAmount.plus(addAmount ? addAmount : 0)).div(new BN(totalStaked.toExact()).plus(addAmount ? addAmount : 0)) : new BN(0);
    return newRate.lt(totalRewardRate) ? newRate : totalRewardRate
  }


  // console.debug('!88', earnedAmounts[0].result, earnedAmounts && earnedAmounts[0].result ? new BN(earnedAmounts[0]?.result[0].toString()).div(1e18) : 0);

  return {
    stakedAmount,
    totalStaked,
    totalRewardRate,
    rewardRate: totalStaked && totalRewardRate ? totalRewardRate.multipliedBy(stakedAmount).div(totalStaked.toExact()) : new BN(0),
    balance,
    earnedAmount: earnedAmounts[0]?.result ? new BN(earnedAmounts[0]?.result[0].toString()).div(1e18) : 0,
    earnedAmountInTokenAmount: new TokenAmount(chainId ? FINN[chainId] : FINN[1024], earnedAmounts[0]?.result ? earnedAmounts[0]?.result[0].toString() : '0'),
    pid,
    // the address of the reward contract
    stakingRewardAddress: chainId ? FINN[chainId].address : FINN[1024].address,
    totalStakedAmount: totalStaked,
    // the tokens involved in this pair
    tokens: [chainId ? TOM[chainId]: TOM[1024], chainId ? FINN[chainId]: FINN[1024]],
    // the total amount of token staked in the contract
    // when the period ends
    periodFinish: undefined,
    // when the period ends
    periodStart:  undefined,
    calcNewRewardRate,
  }
}

export function useStakeTomEarnTomInfo() {
  const bridgeMinerContract = useBridgeMinerContract()
  const v3Contract = useExcursionContractV3();
  const { account, chainId } = useActiveWeb3React();

  const stakeBalance = useSingleCallResult(account ? v3Contract : undefined, 'balanceOf', account ? [account] : undefined).result?.[0];
  const stakeBalancePrice = useSingleCallResult(v3Contract, 'getPricePerFullShare').result?.[0]
  const stakedAmount = (stakeBalance && stakeBalancePrice && account) ? new BN(stakeBalance.toString()).times(stakeBalancePrice.toString()).div(1e36) : defaultBigNum;

  const amount = useSingleCallResult(v3Contract, 'totalBalance').result?.[0];
  const totalStaked = amount ? new BN(amount.toString()).div(1e18) : defaultBigNum;

  const callFee = useSingleCallResult(v3Contract, 'callFee').result?.[0];
  const performanceFee = useSingleCallResult(v3Contract, 'performanceFee').result?.[0];
  const apyRate = (callFee && performanceFee) ? new BN(callFee.toString()).plus(performanceFee.toString()).div(10000).negated().plus(1).toNumber() : 9975/10000;


  const pid = STAKE_TOM_EARN_FINN_PID[chainId ? chainId : 1024];
  const poolInfo = useSingleContractMultipleData(
    bridgeMinerContract,
    'poolInfo',
    pid ? [[pid]] : []
  )
  const finnPerSecond = useSingleCallResult(bridgeMinerContract, 'finnPerSecond')
  const totalAllocPoint = useSingleCallResult(bridgeMinerContract, 'totalAllocPoint')
  const totalRewardRate = useMemo(()=>{
    if (!poolInfo[0] || !poolInfo[0].result || !finnPerSecond.result || !totalAllocPoint.result) {
      return new BN(0)
    }

    return new BN(finnPerSecond.result[0].toString()).multipliedBy(poolInfo[0].result.allocPoint.toString()).div(totalAllocPoint.result[0].toString()).multipliedBy(3600*24*7).div(1e18)
  }, [poolInfo, finnPerSecond, totalAllocPoint])

  const balance = useTokenBalance(account ? account : undefined, chainId ? TOM[chainId] : undefined)


  // console.debug('!88', earnedAmounts[0].result, earnedAmounts && earnedAmounts[0].result ? new BN(earnedAmounts[0]?.result[0].toString()).div(1e18) : 0);

  return {
    stakedAmount,
    totalStaked,
    totalRewardRate,
    balance,
    pid,
    apyRate,
    // the address of the reward contract
    stakingRewardAddress: chainId ? TOM[chainId].address : TOM[1024].address,
    totalStakedAmount: totalStaked,
    // the tokens involved in this pair
    tokens: [chainId ? TOM[chainId]: TOM[1024], chainId ? FINN[chainId]: FINN[1024]],
    // the total amount of token staked in the contract
    // when the period ends
    periodFinish: undefined,
    // when the period ends
    periodStart:  undefined,
  }
}
