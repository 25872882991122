
import React, { useState } from 'react';
import styled from 'styled-components';
import musicIcon from '../../assets/lottie/chrimas/music_btn.svg';
import banMusicIcon from '../../assets/lottie/chrimas/music_ban_btn.svg';
// import { isMobile } from 'react-device-detect';

const moonriverMusic = require('../../assets/lottie/chrimas/moonriver.mp3');

const MusicBtn = styled.div<{play?:boolean}>`
  width: 35px;
  height: 35px;
  background: url(${({play}) => play ? musicIcon : banMusicIcon});
  background-repeat: no-repeat;
  background-size: 35px;
  background-position: center;
  cursor: pointer;
  cursor: pointer;
`;

const BgmBtn = () => {
  const [play, setPlay] = useState(false);
  return (
    <MusicBtn
      play={play}
      onClick={
        () => {
          setPlay(!play);
          const dom = document.getElementById('music') as HTMLMediaElement;
          if (!play) {
            dom.play();
          } else {
            dom.pause();
          }
        }
      }
    >
      <audio src={moonriverMusic} loop id="music"></audio>
    </MusicBtn>
    
  )
}

export default BgmBtn;