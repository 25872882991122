import React from 'react'
import styled from 'styled-components'
import { darken, lighten } from 'polished'

import { RowBetween } from '../Row'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'

const Base = styled(RebassButton)<{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
}>`
  padding: ${({ padding }) => (padding ? padding : '18px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 500;
  text-align: center;
  border-radius:20px;
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`

export const ButtonSmall = styled(Base)`
  width: auto;
  height: auto;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  height: 1.135rem;
  line-height: 1.135rem;
  background-color: #42B5D9;
`

// background:linear-gradient(90deg, rgba(0,169,157,1) 0%, rgba(40,171,224) 100%);
export const ButtonPrimary = styled(Base)`
  background: #42B5D9;
  color: white;
  text-decoration: none;
  border-radius:20px;
  &:focus {
    
    background: linear-gradient(90deg, rgba(0,169,157,1) 0%, rgba(40,171,224) 100%);
    opacity:0.9;
  }
  &:hover {
    background:linear-gradient(90deg, rgba(0,169,157,1) 0%, rgba(40,171,224) 100%);
    opacity:0.9;
  }
  &:active {
    
    opacity:0.9;
    background:linear-gradient(90deg, rgba(0,169,157,1) 0%, rgba(40,171,224) 100%);
  }
  &:disabled {
   
    background: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? '#4d4d4d' : '#4d4d4d')} !important;
    color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? '#999999' :  '#999999')} !important;
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '1')};
  }
`

export const ButtonLight = styled(Base)`
  /* background: linear-gradient(90deg, rgba(0,169,157,1) 0%, rgba(0,113,188,1) 100%); */
  color: black!important;
  background: #FFC70B;
  border-radius: 16px;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 500;
  &:focus {
    /* box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.2, '#0071bc')}; */
    /* background-color: ${({ theme, disabled }) => !disabled && darken(0.2, '#0071bc')}; */
    background: #FFC70B;
  }
  &:hover {
    /* background-color: ${({ theme, disabled }) => !disabled && darken(0.2, '#0071bc')}; */
    background: #ffc60bc9;
  }
  &:active {
    /* box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, '#0071bc')}; */
    /* background-color: ${({ theme, disabled }) => !disabled && darken(0.2, '#0071bc')}; */
    background: #FFC70B;
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      /* background-color: #0071bc; */
      background: #b69d4a;
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonCircle = styled(Base)`
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(0,0,0,0);
  border-radius: 100px;
  padding: 0 12px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    background: #42B5D9;
    border-color: #42B5D9;
  }
`

export const ButtonBlueCircle = styled(ButtonCircle)`
  width: 88px;
  height: 24px;
  background: #42B5D9;
  padding: 0;
  border: none;
  font-size: 12px;

  :disabled {
    background: #4D4D4D;
    color: #999999;
    cursor: not-allowed;
  }
`;

export const ButtonHarvestAll = styled(Base)`
  background: #0088B3;
  width: 112px;
  border-radius: 8px;
  padding: 10px;
  text-decoration: none;
  color: #9BCEDC;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `};

  &:focus {
    background: #0088B3;
    color: #fff;
  }
  &:hover {
    background: #0088B3;
    color: #fff;
  }
  &:active {
    background: #0088B3;
    color: #fff;
  }
`

export const ButtonGray = styled(Base)`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  font-weight: 500;
  
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg2)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg2)};
  }
`

export const ButtonSecondary = styled(Base)`
  border: 1px solid ${({ theme }) => theme.primary4};
  color: ${({ theme }) => theme.primary1};
  background-color: transparent;
  font-size: 16px;
  border-radius:10px;
  padding: ${({ padding }) => (padding ? padding : '10px')};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonOutlined = styled(Base)`
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: transparent;
  color: ${({ theme }) => theme.text1};

  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonWhite = styled(Base)`
  border: 1px solid #edeef2;
  background-color: ${({ theme }) => theme.bg1};
  color: #313131;

  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    box-shadow: 0 0 0 1pt ${darken(0.05, '#edeef2')};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonConfirmedStyle = styled(Base)`
  background-color: ${({ theme }) => lighten(0.5, theme.green1)};
  color: ${({ theme }) => theme.green1};
  border: 1px solid ${({ theme }) => theme.green1};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(Base)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.red1)};
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red1)};
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
  &:disabled {
    opacity:0.5
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.red1};
    border: 1px solid ${({ theme }) => theme.red1};
  }
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({ error, altDisabledStyle, ...rest }: { error?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle}/>
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined style={{background:'transparent',borderColor:'rgb(200,200,200)'}} {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} color={"#999"} />
      </RowBetween>
    </ButtonOutlined>
  )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}
