
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`


export default Bg;