import React, { useCallback, useState, useMemo, useContext } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { JSBI, TokenAmount, ETHER } from '@huckleberry/sdk'
import { RouteComponentProps } from 'react-router-dom'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import CurrencyLogo from '../../components/CurrencyLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'
import { ERC20_INTERFACE } from '../../constants/abis/bridge'
import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
import { ButtonPrimary, ButtonCircle } from '../../components/Button'
import StakingModal from '../../components/earn/StakingModal'
import { useStakingInfo } from '../../state/stake/hooks'
import { useMultipleContractSingleData } from '../../state/multicall/hooks'
import UnstakingModal from '../../components/earn/UnstakingModal'
import ClaimRewardModal from '../../components/earn/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { currencyId } from '../../utils/currencyId'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO } from '../../constants'
import arrowleft from '../../assets/images/arrowleft.png'
import { ThemeContext } from 'styled-components'

import finnImg from '../../assets/images/FINN_1.png'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  position: relative;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 10px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: linear-gradient(90deg,rgb(0,169,157) 0%,rgb(40,171,224) 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  
`

const StyledBottomCard = styled(DataCard)<{ dim: any }>`
  background: #222E33;
  opacity: ${({ dim }) => (dim ? 1 : 1)};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const PoolData = styled(DataCard)`
  background: ${({ theme }) => theme.liquidityCardBg };
  border: 1px solid rgba(255, 255, 255, 0.2);;
  padding: 1rem;
  z-index: 1;
`

const VoteCard = styled(DataCard)`
  overflow: hidden;
  height: 180px;
  background: ${({ theme }) => theme.liquidityCardBg };
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  grid-gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    grid-gap: 12px;
  `};
`

export default function Manage({
  match: {
    params: { currencyIdA, currencyIdB }
  },
  history
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const { account, chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // get currencies and pair
  const [currencyA, currencyB] = [useCurrency(currencyIdA), useCurrency(currencyIdB)]
  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

  const [, stakingTokenPair] = usePair(tokenA, tokenB)
  const stakingInfo = useStakingInfo(stakingTokenPair)?.[0]
  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))
  const lpBalanceOf = useMultipleContractSingleData(stakingInfo?.tokens?.map(i => i.address), ERC20_INTERFACE, 'balanceOf', [stakingInfo?.stakingRewardAddress])

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))

  const token = currencyA === ETHER ? tokenB : tokenA
  const WETH = currencyA === ETHER ? tokenA : tokenB
  const backgroundColor = useColor(token)

  const selfTokensAmount = useMemo(() => {
    return lpBalanceOf.map((item, index) => new TokenAmount(stakingInfo?.tokens[index], JSBI.BigInt(item?.result?.balance ?? 0)))
  }, [stakingInfo, lpBalanceOf])

  // get WETH value of staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  let valueOfTotalStakedAmountInWLSP: TokenAmount | undefined
  let selfTokens0Amount: number | undefined
  let selfTokens1Amount: number | undefined

  if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH && stakingInfo.totalStakedAmount.toExact() !== '0') {
    // take the total amount of LP tokens staked, multiply by MOVR value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
    valueOfTotalStakedAmountInWLSP = new TokenAmount(
      WETH,
      JSBI.multiply(stakingInfo.totalStakedAmount.raw, JSBI.BigInt(1))
    )
    //@ts-ignore
      const rate = stakingInfo?.stakedAmount?.toSignificant(10) / totalSupplyOfStakingToken?.toSignificant(10)
      //@ts-ignore
    selfTokens0Amount = rate * selfTokensAmount[0]?.toSignificant(10)
      //@ts-ignore
    selfTokens1Amount = rate * selfTokensAmount[1]?.toSignificant(10)
  }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  // console.debug(USDPrice, 'USDPrice')
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
  <PageWrapper gap="lg" justify="center">
    <GoBack onClick={() => history.push('/farm')}><img alt="Go Back" src={arrowleft}/></GoBack>
    <RowBetween style={{ gap: '24px' }}>
      <TYPE.mediumHeader style={{ margin: 0 }} color={theme.liquidityTxt}>
        {currencyA?.symbol}-{currencyB?.symbol} Liquidity Mining
      </TYPE.mediumHeader>
      <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={40} />
    </RowBetween>

    <DataRow style={{ gap: '24px' }}>
      <PoolData>
        <AutoColumn gap="sm">
          <TYPE.white style={{ margin: 0 }}>Total deposits</TYPE.white>
          <TYPE.body fontSize={22} fontWeight={500}>
            {valueOfTotalStakedAmountInUSDC
              ? `$${valueOfTotalStakedAmountInUSDC.toSignificant(6, { groupSeparator: ',' })}`
              : `${valueOfTotalStakedAmountInWLSP?.toSignificant(6, { groupSeparator: ',' }) ?? '-'} HBLP`}
          </TYPE.body>
        </AutoColumn>
      </PoolData>
      <PoolData>
        <AutoColumn gap="sm">
          <TYPE.white style={{ margin: 0 }}>Pool Rate</TYPE.white>
          <TYPE.body fontSize={22} fontWeight={500}>
            {stakingInfo?.totalRewardRate
              ?.multiply((60 * 60 * 24 * 7).toString())
              ?.toFixed(0, { groupSeparator: ',' }) ?? '-'}
            {' FINN / week'}
          </TYPE.body>
        </AutoColumn>
      </PoolData>
    </DataRow>

    {showAddLiquidityButton && (
      <VoteCard>
        <CardBGImage />
        <CardNoise />
        <CardSection>
          <AutoColumn gap="md">
            <RowBetween>
              <TYPE.white fontWeight={400}>Step 1. Get Huckleberry Liquidity Pool token HBLP</TYPE.white>
            </RowBetween>
            <RowBetween style={{ marginBottom: '1rem' }}>
              <TYPE.white fontSize={14} fontWeight={400}>
                {`HBLP tokens are required. Once you've added liquidity to the ${currencyA?.symbol}-${currencyB?.symbol} pool you can stake your liquidity tokens on this page.`}
              </TYPE.white>
            </RowBetween>
            <ButtonPrimaryCus
              padding="11px"
              borderRadius="8px"
              width={'fit-content'}
              as={Link}
              to={`/add/${currencyA && currencyId(currencyA)}/${currencyB && currencyId(currencyB)}`}
            >
              {`Add ${currencyA?.symbol}-${currencyB?.symbol} liquidity`}
            </ButtonPrimaryCus>
          </AutoColumn>
        </CardSection>
        <CardBGImage />
        <CardNoise />
      </VoteCard>
    )}

    {stakingInfo && (
      <>
        <StakingModal
          isOpen={showStakingModal}
          onDismiss={() => setShowStakingModal(false)}
          stakingInfo={stakingInfo}
          userLiquidityUnstaked={userLiquidityUnstaked}
        />
        <UnstakingModal
          isOpen={showUnstakingModal}
          onDismiss={() => setShowUnstakingModal(false)}
          stakingInfo={stakingInfo}
        />
        <ClaimRewardModal
          isOpen={showClaimRewardModal}
          onDismiss={() => setShowClaimRewardModal(false)}
          stakingInfo={stakingInfo}
        />
      </>
    )}

    <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
      <BottomSection gap="lg" justify="center">
        <StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showAddLiquidityButton}>
          <CardSectionCus>
            <CardBGImage desaturate />
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Your liquidity deposits</TYPE.white>
              </RowBetween>
              <RowBetween style={{ alignItems: 'center', display:'flex',flexWrap:'wrap' }}>
                <TYPE.white fontSize={36} fontWeight={600} style={{wordBreak: 'break-all'}}>
                  {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                </TYPE.white>
                <TYPE.white>
                  HBLP {currencyA?.symbol}-{currencyB?.symbol}
                </TYPE.white>
              </RowBetween>
            </AutoColumn>
            <div style={{ marginTop: "30px" }}>
              <span style={{ display: "inline-block" }}>
                <CurrencyLogo currency={currencyA ?? undefined} size={"24px"} />
                <TokenAmountCon margin={"0 3px 0 10px"} fontWeight={"600"}>{selfTokens0Amount?.toFixed(3) || 0}</TokenAmountCon>
                <TokenAmountCon>{currencyA?.symbol}</TokenAmountCon>
              </span>
              <span style={{ display: "inline-block", marginLeft: "25px" }}>
                <CurrencyLogo currency={currencyB ?? undefined} size={"24px"} />
                <TokenAmountCon margin={"0 3px 0 10px"} fontWeight={"600"}>{selfTokens1Amount?.toFixed(3) || 0}</TokenAmountCon>
                <TokenAmountCon>{currencyB?.symbol}</TokenAmountCon>
              </span>
            </div>
          </CardSectionCus>
        </StyledDataCard>
        <StyledBottomCard dim={stakingInfo?.stakedAmount?.equalTo(JSBI.BigInt(0))}>
          <CardBGImage desaturate />
          <CardNoise />
          <AutoColumn gap="sm">
            <RowBetween>
              <div>
                <TYPE.white>Your unclaimed FINN</TYPE.white>
              </div>
            </RowBetween>
            <RowBetween style={{ alignItems: 'center', display:'flex',flexWrap:'wrap' }}>
              <div style={{display: 'flex'}}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                  <ButtonCircle
                    style={{margin: '8px 0 8px 12px'}}
                    width="fit-content"
                    onClick={() => setShowClaimRewardModal(true)}
                  >
                    Claim
                  </ButtonCircle>
                )}
              </div>
              <TYPE.black fontSize={20} fontWeight={500} style={{display: 'flex', alignItems: 'center'}}>
                {/* <span id="animate-zoom" role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                💗
                </span> */}
                <img alt='' src={finnImg} style={{width: '26px', marginRight: '8px'}} />
                {stakingInfo?.rewardRate
                  ?.multiply((60 * 60 * 24 * 7).toString())
                  ?.toFixed(0, { groupSeparator: ',' }) ?? '-'}
                {' FINN / week'}
              </TYPE.black>
            </RowBetween>
          </AutoColumn>
        </StyledBottomCard>
      </BottomSection>
      <TYPE.white style={{ textAlign: 'center' }} fontSize={14} color={theme.liquidityTxt}>
        <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
          ⭐️
        </span>
        When you withdraw, the contract will automagically claim FINN on your behalf!
      </TYPE.white>

      {!showAddLiquidityButton && (
        <DataRow style={{ marginBottom: '1rem', gap:0 }}>
          <ButtonPrimary height="60px" padding="8px" borderRadius="8px" width="240px" margin="6px" onClick={handleDepositClick}>
            {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ? 'Deposit' : 'Deposit HBLP Tokens'}
          </ButtonPrimary>

          {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
            <>
              <ButtonPrimary
                height="60px"
                margin="6px"
                padding="8px"
                borderRadius="8px"
                width="240px"
                onClick={() => setShowUnstakingModal(true)}
              >
                Withdraw
              </ButtonPrimary>
            </>
          )}
        </DataRow>
      )}
      {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : (
        <TYPE.main color={theme.liquidityTxt}>{userLiquidityUnstaked.toSignificant(6)} HBLP tokens available</TYPE.main>
      )}
    </PositionInfo>
  </PageWrapper>
  )
}

const ButtonPrimaryCus = styled(ButtonPrimary)`
  background: #42B5D9;
  border-radius: 18px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #FFFFFF;
`

const TokenAmountCon = styled.span<{
  fontWeight?: string
  margin?: string
}>`
  color: #fff;
  display: inline-block;
  margin: ${({ margin }) => margin ? margin : "0 3px 0 0"}
  top: -6px;
  position: relative;
  font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : "400"}
`

const CardSectionCus = styled(CardSection)`
  padding: 1.2rem 2rem;
`

const GoBack = styled.div`
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  top: -35px;
  left: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({theme}) => theme.riverArrowBg};
`
