import React, { useState } from 'react'
import styled from 'styled-components'
// import { AlertTriangle, X } from 'react-feather'
import { isMobile } from 'react-device-detect'

const PhishAlert = styled.div<{ isActive: any }>`
  width: 100%;
  padding: 6px 6px;
  /* background-color: ${({ theme }) => isMobile ? theme.liquidityAddCardBg : theme.modalBG}; */
  color: white;
  font-size: 11px;
  justify-content: space-between;
  align-items: center;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  backdrop-filter: blur(10px);
`

// const StyledClose = styled(X)`
//   :hover {
//     cursor: pointer;
//   }
// `

export default function LackOfMovrWarning() {
  const [showLackOfMovr] = useState(true);
  // const toggleLackOfMovrWarning = () => {
  //   setShowLackOfMovr(!showLackOfMovr)
  // }
  console.log('showLackOfMovr', showLackOfMovr)

  return (
    <PhishAlert isActive={showLackOfMovr}>
      <div style={{ display: 'flex', flex: 1 }}>
        {/* { !isMobile ? <AlertTriangle style={{ marginRight: 6 }} size={12} /> : null } */}
          {/* If you are havin' trouble with Tom's House, Excursions or any other Huckleberry functions, please ensure that you have at least 0.05 $CLV in your wallet. The Moonbeam Foundation's RPC node is experiencin' issues. */}
      </div>
      {/* <StyledClose size={12} onClick={toggleLackOfMovrWarning} /> */}
    </PhishAlert>
  )
}
