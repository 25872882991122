import { Currency, CurrencyAmount, Fraction, Percent } from '@huckleberry/sdk'
import React from 'react'
import { Text } from 'rebass'
import { ButtonPrimary } from '../../components/Button'
import { RowBetween, RowFixed } from '../../components/Row'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd
}: {
  noLiquidity?: boolean
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  const { t } = useTranslation()
  return (
    <>
      <RowBetweenCus>
        <TYPE.white fontWeight={400}>{currencies[Field.CURRENCY_A]?.symbol} Deposited</TYPE.white>
        <RowFixed>
          <TYPE.body fontWeight={"bold"}>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</TYPE.body>
          <TYPE.white fontSize={"12px"} marginLeft={"4px"} marginTop={"3px"}>{currencies[Field.CURRENCY_A]?.symbol}</TYPE.white>
        </RowFixed>
      </RowBetweenCus>
      <RowBetweenCus>
        <TYPE.white fontWeight={400}>{currencies[Field.CURRENCY_B]?.symbol} Deposited</TYPE.white>
        <RowFixed>
          <TYPE.body fontWeight={"bold"}>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</TYPE.body>
          <TYPE.white fontSize={"12px"} marginLeft={"4px"} marginTop={"3px"}>{currencies[Field.CURRENCY_B]?.symbol}</TYPE.white>
        </RowFixed>
      </RowBetweenCus>
      <RowBetweenCus>
        <TYPE.white fontWeight={400}>Rates</TYPE.white>
        <RowD>
          <TYPE.body display={"flex"} marginBottom={"8px"}>
            1
            <TYPE.white fontSize={"12px"} marginLeft={"4px"} marginTop={"3px"}>{currencies[Field.CURRENCY_A]?.symbol}</TYPE.white>
            =
            <TYPE.body fontWeight={"bold"}>{price?.toSignificant(4)}</TYPE.body> 
            <TYPE.white fontSize={"12px"} marginLeft={"4px"} marginTop={"3px"}>{currencies[Field.CURRENCY_B]?.symbol}</TYPE.white>
          </TYPE.body>
          <TYPE.body display={"flex"}>
            1
            <TYPE.white fontSize={"12px"} marginLeft={"4px"} marginTop={"3px"}>{currencies[Field.CURRENCY_B]?.symbol}</TYPE.white>
            =
            <TYPE.body fontWeight={"bold"}>{price?.invert().toSignificant(4)}</TYPE.body>
            <TYPE.white fontSize={"12px"} marginLeft={"4px"} marginTop={"3px"}>{currencies[Field.CURRENCY_A]?.symbol}</TYPE.white>
          </TYPE.body>
        </RowD>
      </RowBetweenCus>
      <RowBetweenCus>
        <TYPE.white fontWeight={400}>{t('shareOfPool')}:</TYPE.white>
        <TYPE.body fontWeight={"bold"}>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</TYPE.body>
      </RowBetweenCus>
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={20}>
          {noLiquidity ? t('createPoolSupply') : t('confirmSupply')}
        </Text>
      </ButtonPrimary>
    </>
  )
}

const RowBetweenCus = styled(RowBetween)`
  height: 60px;
  background: ${({theme}) => theme.liquidityAddCardItemBg};
  border-radius: 16px;
  padding: 10px 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
`

const RowD = styled.div`
  display: flex;
  flex-direction: column;
`