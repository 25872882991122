import React, { useCallback, useContext, useMemo, useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled, { ThemeContext } from 'styled-components'
import Row, { RowBetween, RowFixed } from '../Row'
import { CloseIcon, TYPE } from '../../theme'
import { ButtonError } from '../Button'
import { useExcursionContractV3 } from '../../hooks/useContract'
import { SubmittedView, LoadingView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { SUGGEST_GAS_PRICE } from '../../constants'
import { AppBodyV1 } from '../../pages/AppBody'
import { Wrapper } from '../swap/styleds'
import { Text } from 'rebass'
import Slider from '../Slider'
import { LightCard } from '../Card'
import { MaxButton } from '../../pages/Pool/styleds'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'
import { useCurrency } from '../../hooks/Tokens'
import { JSBI } from '@huckleberry/sdk'
import CurrencyLogo from '../CurrencyLogo'
import { isMobile } from 'react-device-detect'
import BN from 'bignumber.js'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  grid-row-gap: 0;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: any
}

export default function UnstakingAutoTomAmountModal({ isOpen, onDismiss, stakingInfo }: StakingModalProps) {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()
  const [percent, setPercent] = useState('100')
  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  const { t } = useTranslation()
  const currencyA = useCurrency(stakingInfo?.tokens[0].address)

  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const autoTomContract = useExcursionContractV3()

  const withdrawAmount = useMemo(() => {
    if (stakingInfo?.stakedAmount) {
      const amount = stakingInfo?.stakedAmount.multipliedBy(1e18).toFixed(0);
      if (percent === '100') {
        return JSBI.BigInt(amount)
      }
      return JSBI.divide(
        JSBI.multiply(JSBI.BigInt(amount), JSBI.BigInt(percent)),
        JSBI.BigInt(100)
      )
    } else {
      return 0
    }
  }, [stakingInfo, percent])

  async function onWithdraw() {
    if (autoTomContract && stakingInfo?.stakedAmount) {
      setAttempting(true)
      if (+percent === 100) {
        await autoTomContract
          .withdrawAll({ gasLimit: 1000000, gasPrice: SUGGEST_GAS_PRICE(), value: '0' })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Withdraw deposited TOM`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      } else {
        await autoTomContract
          .withdraw(`0x${withdrawAmount.toString(16)}`, { gasLimit: 1000000, gasPrice: SUGGEST_GAS_PRICE() })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Withdraw deposited TOM`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      }
    }
  }

  let error: string | undefined
  if (!account) {
    error = t('connectWallet')
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? t('enterAnAmount')
  }

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      setPercent(value.toString())
    },
    [setPercent]
  )

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(percent),
    liquidityPercentChangeCallback
  )

  const bg = useMemo(() => {
    return (!attempting && !hash) ? 'transparent' : undefined
  }, [attempting, hash])

  return (
    <Modal bg={bg} isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      <Bg />
      {!attempting && !hash && (
        <AppBodyV1>
          <ContentWrapper gap="lg">
            <RowBetweenCus1>
              <span style={{ display: 'inline-block', color: '#fff', width: '100%', textAlign: 'center', fontSize: '22px' }}>Withdraw</span>
              <CloseIcon onClick={wrappedOndismiss} color={'#fff'}/>
            </RowBetweenCus1> 
            <Wrapper>
              <AutoColumn gap="md">
                <LightCardCus>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <Text fontWeight={500} color={"#fff"}>{t('amount')}</Text>
                    </RowBetween>
                    <Row style={{ alignItems: 'flex-end' }}>
                      <Text fontSize={72} fontWeight={"bold"} color={"#96B5BB"}>
                        {percent}%
                      </Text>
                    </Row>
                    <div>
                      <Slider value={innerLiquidityPercentage} onChange={setInnerLiquidityPercentage} />
                      <RowBetween>
                        <MaxButtonCus onClick={() => setPercent('25')} width="20%">
                          25%
                        </MaxButtonCus>
                        <MaxButtonCus onClick={() => setPercent('50')} width="50%">
                          50%
                        </MaxButtonCus>
                        <MaxButtonCus onClick={() => setPercent('75')} width="75%">
                          75%
                        </MaxButtonCus>
                        <MaxButtonCus onClick={() => setPercent('100')} width="100%">
                          Max
                        </MaxButtonCus>
                      </RowBetween>
                      </div>
                  </AutoColumn>
                </LightCardCus>
                <LightCardCus1>
                  <AutoColumn>
                    <RowBetweenCus>
                      <Text fontSize={18} color={"#fff"}>
                        <IconCon><CurrencyLogo currency={currencyA ?? undefined} size={"24px"} /></IconCon>
                        <SpanSty color={'#42B5D9'}>{currencyA?.symbol}</SpanSty>
                      </Text>
                      <RowFixed>
                        <Text fontSize={18} fontWeight={400} id="remove-liquidity-tokena-symbol" color={"#42B5D9"}>
                          {new BN(stakingInfo?.stakedAmount).times(percent).div(100).toFixed(4) ?? '-'}
                        </Text>
                      </RowFixed>
                    </RowBetweenCus>
                  </AutoColumn>
                </LightCardCus1>
              </AutoColumn>
              <Con>
                <ButtonError disabled={!!error || +percent === 0} error={!!error && !!stakingInfo?.stakedAmount} onClick={onWithdraw}>
                  {error ?? 'Withdraw'}
                </ButtonError>
              </Con>
            </Wrapper>
          </ContentWrapper>
        </AppBodyV1>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss} bg={theme.finnbarModalBg}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.white fontSize={20}>Withdrawing {new BN(stakingInfo?.stakedAmount?.toString()).multipliedBy(percent).div(100).toFixed(4)} TOM</TYPE.white>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash} bg={theme.finnbarModalBg}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.white fontSize={20}>Withdrew TOM!</TYPE.white>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}

const LightCardCus = styled(LightCard)`
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid #42B5D9;
`

const LightCardCus1 = styled(LightCard)`
  background: rgba(0, 0, 0, 0.05);
  padding: 0 1.25rem;
`

const MaxButtonCus = styled(MaxButton)`
  background: #2B3A3E;
  color: #42B5D9;
  border: 1px solid #152025;
`

const SpanSty = styled.span<{
  color?: string
}>`
  color: ${({ color }) => color ? color : '#fff'};
  position: relative;
  margin-left: 10px;
  top: -5px;
  left: -5px;
  font-size: 16px;
`

const IconCon = styled.div`
  display: inline-block;
  width: 50px;
  text-align: center;
`

const Con = styled.div`
  width: 95%;
  padding-left: 5%;
  margin-top: 10px;
`

const RowBetweenCus = styled(RowBetween)`
  height: 45px;
`

const RowBetweenCus1 = styled(RowBetween)`
  height: 45px;
  padding: 0 20px;
  margin-bottom: 10px;
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`