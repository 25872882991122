import { Trade, TradeType } from '@huckleberry/sdk'
import React, { useContext, useMemo } from 'react'
import { AlertTriangle, ArrowRightCircle } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { TYPE } from '../../theme'
import { ButtonPrimary } from '../Button'
import { isAddress, shortenAddress } from '../../utils'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import { RowItem, RowBetween, RowFixed, AutoRow } from '../Row'
import { TruncatedText, SwapShowAcceptChanges } from './styleds'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges
}: {
  trade: Trade
  allowedSlippage: number
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    trade,
    allowedSlippage
  ])
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const theme = useContext(ThemeContext)
  const {t} = useTranslation()

  return (
    <AutoColumn gap={'md'}>
      <RowBetween align="center">
        <RowItem padding={ isMobile ? '0.625rem 1.25rem' : '20px 30px' }>
          <AutoRow>
            <RowFixed gap={'0px'}>
              <CurrencyLogo currency={trade.inputAmount.currency} size={'24px'} style={{ marginRight: isMobile ? '0.5rem' : '22px' }} />
              <Text fontSize={16} fontWeight={500}>
                {trade.inputAmount.currency.symbol}
              </Text>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <TruncatedText
                fontSize={isMobile ? '1.6rem' : 32}
                paddingTop={isMobile ? '4px': '0'}
                fontWeight={500}
                color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? theme.primary1 : ''}
              >
                {trade.inputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
          </AutoRow>
        </RowItem>
        <RowFixed>
          <ArrowRightCircle size="24" color={theme.text2} style={{ margin: '0 4px', minWidth: '24px' }} />
        </RowFixed>
        <RowItem padding={ isMobile ? '0.625rem 1.25rem' : '20px 30px' }>
          <AutoRow>
            <RowFixed gap={'0px'}>
              <CurrencyLogo currency={trade.outputAmount.currency} size={'24px'} style={{ marginRight: isMobile ? '0.5rem' : '22px' }} />
              <Text fontSize={16} fontWeight={500}>
                {trade.outputAmount.currency.symbol}
              </Text>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <TruncatedText
                fontSize={isMobile ? '1.6rem' : 32}
                paddingTop={isMobile ? '4px': '0'}
                fontWeight={500}
                color={
                  priceImpactSeverity > 2
                    ? theme.red1
                    : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
                    ? theme.primary1
                    : ''
                }
              >
                {trade.outputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
          </AutoRow>
        </RowItem>
      </RowBetween>
      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap={'0px'}>
          <RowBetween>
            <RowFixed>
              <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24, color: '#999999' }} />
              <TYPE.main color={'#999999'}> Price Updated</TYPE.main>
            </RowFixed>
            <ButtonPrimary
              style={{ padding: '.5rem', width: 'fit-content', fontSize: '0.825rem', borderRadius: '12px', color: '#999999' }}
              onClick={onAcceptChanges}
            >
              Accept
            </ButtonPrimary>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <TYPE.italic textAlign="left" style={{ width: '100%', color: '#999999', fontSize: '14px' }}>
            {t('outputIsEstimated')}
            <b>
              {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)} {trade.outputAmount.currency.symbol}
            </b>
            {t('orTheTransaction')}
          </TYPE.italic>
        ) : (
          <TYPE.italic textAlign="left" style={{ width: '100%', color: '#999999', fontSize: '14px' }}>
            {t('inputIsEstimated')}
            <b>
              {slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)} {trade.inputAmount.currency.symbol}
            </b>
            {t('orTheTransaction')}
          </TYPE.italic>
        )}
      </AutoColumn>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <TYPE.main style={{color: '#999999'}}>
            {t('outputWillBeSentTo') + ' '}
            <b title={recipient}>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
          </TYPE.main>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
