import React, { useState, useEffect } from 'react'
import { ChainId, TokenAmount } from '@huckleberry/sdk'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { TYPE, ExternalLink } from '../../theme'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { RowBetween } from '../Row'
// import Web3Status from '../Web3Status'
import Settings from '../Settings'
// import Menu from '../Menu'
import { YellowCard } from '../Card'
import { useActiveWeb3React } from '../../hooks'
import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useToggleSelfClaimModal, useShowClaimPopup } from '../../state/application/hooks'
import { CardNoise } from '../earn/styled'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import { Dots } from '../swap/styleds'
import { useAggregateUniBalance } from '../../state/wallet/hooks'
import { CountUp } from 'use-count-up'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { FINN } from '../../constants'
import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
import { MdHowToVote } from 'react-icons/md'
import { IoLogoTwitter } from 'react-icons/io';
import { FaTelegramPlane } from 'react-icons/fa';
import { RiGithubLine } from 'react-icons/ri';
import { AiOutlineFolderOpen, AiOutlineAudit, AiOutlineMedium } from 'react-icons/ai'
import NetworkSwitch from '../NetworkSwitch'

import logo from '../../assets/svg/logo_font.svg'
// import mobileLogo from '../../assets/png/Header/mobile_logo.png'


const ICONSIZE = 16;

const MenuCon = styled.div<{ show?: boolean }>`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 300ms ease-in-out;
  height: 100vh;
  background: #172329;
  flex-direction: column;
`

const List = styled.div`
  flex: 1;
  overflow-y: auto;
`

const HeaderLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.75rem 1.25rem;
`

const HeaderFooterLinks = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0.25rem 1.75rem 1.5rem;
`

const activeClassName = 'ACTIVE'

const RiverNavCon = styled.div.attrs({
  activeClassName
})`
  color: rgba(255, 255, 255, 0.8);
  padding: 1rem 0 1rem 1.5rem;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 0.625rem;

  img {
    width: 1rem;
    height: 1.1875rem;
    margin-right: 0.65rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.text1};
    background: rgba(77, 212, 254, 0.1);
    border-color: ${({ theme }) => theme.text1};
  }
`
const RiverNav = styled.div<{openRiver?:boolean}>`
  padding-left: 2rem;
  overflow: hidden;
  // display: ${({openRiver}) => openRiver ? 'block' : 'none'};
  height: ${({openRiver}) => openRiver ? '5.5rem' : '0px'};
`

const RiverNavLink = styled(NavLink).attrs({
  activeClassName
})`
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  margin-top: 0.625rem;
  display: flex;
  align-items: center;
  position: relative;

  &::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-200%, -50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: #fff;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.text1};
  }

  &.${activeClassName}::before {
    background: ${({ theme }) => theme.text1};
  }

`

const RiverGroup = styled.div`
  display: flex;
`

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  color: rgba(255, 255, 255, 0.8);
  padding: 1rem 0 1rem 1.5rem;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;

  img {
    width: 1rem;
    margin-right: 0.65rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.text1};
    background: rgba(77, 212, 254, 0.1);
    border-color: ${({ theme }) => theme.text1};
  }
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  color: rgba(255, 255, 255, 0.8);
  padding: 1rem 0 1rem 1.5rem;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;

  img {
    width: 1rem;
    margin-right: 0.65rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.text1};
    background: rgba(77, 212, 254, 0.1);
    border-color: ${({ theme }) => theme.text1};
  }
`

const StyledExternalLink2 = styled(ExternalLink)<{ isActive?: boolean }>`
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  background: rgba(255, 255, 255, 0.05);
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
`

const Top = styled.div`
  padding: 0 1rem;
  background: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
`

// const TopBtnGroup = styled.div`
//   display: flex;
// `

const BtnBase = styled.div`
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`

const CloseIcon = styled(BtnBase)`
  border-color: ${({ theme }) => theme.primary7};
  margin-right: 0.625rem;
`

const CloseColor = styled(Close)`
  width: 1rem;
  height: 1rem;
  margin-left: -0.08rem;
  margin-top: -0.08rem;
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`
// const LogoIcon = styled(BtnBase)`
//   border-color: ${({ theme }) => theme.primary7};
//   background-image: url(${mobileLogo});
//   background-repeat: no-repeat;
//   background-size: 320%;
//   background-position: left;
// `

const LogoBtn = styled.div`
  width: 8.5rem;
  height: 2.25rem;
  background: url(${logo}) center no-repeat;
  background-size: 100%;
`

const Bottom = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.05);
  position: relative;
  padding: 1rem;
  width: 100%;
  display: flex;
`

const HideSmallCus = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    /*display: none;*/
  `};
  margin-right: 8px;
`

const NetworkCard = styled(YellowCard)`
  border-radius:10px;
  padding: 8px 12px;
  background:white;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    
  `};
`
const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;
  margin-right: 8px;
  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`
const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0071bc;
  border-radius:10px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid #0071bc;
  }
`
const UNIAmount = styled(AccountElement)`
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background: #42B5D9;
  border-radius: 8px;
  color: white;
`
const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    /*display: none;*/
  `};
`
const PriceText = styled.div`
  position: relative;
  z-index: -1;
  margin-left: -12px;
  background-color: #2C2F36;
  border: 1px solid #42B5D9;
  padding: 0 8px 0 20px;
  color: #4DD4FE;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`

const DividingLine = styled.p`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.05);
  margin: 0 1.75rem;
`

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.MOON_MAINNET]: '',
  [ChainId.MOON_TESTNET]: 'Testnet',
  [ChainId.CLOVER_PARACHAIN]: ''
}

export default function SwapMenu({show, onOpen}:{show:boolean, onOpen:() => void}) {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const availableClaim: boolean = useUserHasAvailableClaim(account)
  const [curIndex, setCurIndex] = useState('')
  const [riveActive, setRiveActive] = useState(false)
  const [openRiver, setOpenRiver] = useState(false)
  const showClaimPopup = useShowClaimPopup()
  const toggleClaimModal = useToggleSelfClaimModal()
  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)
  const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()
  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'
  const uniPrice = useUSDCPrice(chainId ? FINN[chainId] : undefined)

  useEffect(() => {
    let river = /rivers/.test(curIndex)
    let active = /excursion|farm|tomhouse/.test(curIndex)
    setOpenRiver(river)
    setRiveActive(active)
  }, [curIndex, show])


  return (
    <MenuCon show={show}>
      <Top>
        <RowBetween>
          <RowBetween>
            <CloseIcon onClick={onOpen}>
              <CloseColor />
            </CloseIcon>
            {/* <NavLink id={`swap-nav-link`} to={'/swap'} onClick={onOpen}>
              <LogoIcon />
            </NavLink> */}
            <NavLink id={`swap-nav-link`} to={'/swap'} onClick={onOpen}>
              <LogoBtn />
            </NavLink>
          </RowBetween>
          {/* <div onClick={onOpen}>
            <Web3Status />
          </div> */}
        </RowBetween>
      </Top>
      <List>
        <HeaderLinks id="header-link">
          <div onClick={() => {
            setCurIndex('swap')
            onOpen()
          }}>
            <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
              <img src={require(`./../../assets/png/Header/swap${/swap/.test(curIndex) ? '_active':''}.png`)} alt="" />
              {t('swap')}
            </StyledNavLink>
          </div>
          <div onClick={() => {
            setCurIndex('liquidity')
            onOpen()
          }}>
            <StyledNavLink
              id={`pool-nav-link`}
              to={'/pool'}
              isActive={(match, { pathname }) => Boolean(match) ||
                  pathname.startsWith('/add') ||
                  pathname.startsWith('/remove') ||
                  pathname.startsWith('/create') ||
                  pathname.startsWith('/find')
              }
            >
              <img src={require(`./../../assets/png/Header/liquidity${/liquidity/.test(curIndex) ? '_active':''}.svg`)} alt="" />
              {t('pool')}
            </StyledNavLink>
          </div>
          <RiverNavCon
            className={riveActive ? activeClassName : ''}
            onClick={() => setOpenRiver(true)}
          >
            <RiverGroup>
              <img src={require(`./../../assets/png/Header/rivers${riveActive ? '_active':''}.png`)} alt="" />
              {t('Adventures')}
            </RiverGroup>
            <RiverNav openRiver={Boolean(openRiver)} onClick={() => onOpen()}>
              <RiverNavLink
                id={`stake-nav-link`}
                to={'/farm'}
                onClick={() => {
                  setRiveActive(true)
                  setCurIndex('rivers/farm')
                }}
              >
                {t('sampleRiver')}
              </RiverNavLink>
              <RiverNavLink
                id={`stake-nav-link`}
                to={'/tomhouse'}
                onClick={() => {
                  setRiveActive(true)
                  setCurIndex('rivers/tomhouse')
                }}
              >
                {t('Tom\'s House')}
              </RiverNavLink>
              <RiverNavLink
                id={`stake-nav-link`}
                to={'/excursion'}
                onClick={() => {
                  setRiveActive(true)
                  setCurIndex('rivers/excursion')
                }}
              >
                {t('sampleExcursions')}
              </RiverNavLink>
            </RiverNav>
          </RiverNavCon>
          {/* <div
            onClick={() => {
              setCurIndex('lend')
              onOpen()
            }}
          >
            <StyledNavLink
              id={`swap-nav-link`}
              to={'/lend/dashboard'}
              isActive={(match, { pathname }) =>
                Boolean(match) ||
                pathname.startsWith('/lend/reservoir/info') ||
                pathname.startsWith('/lend/deposit') ||
                pathname.startsWith('/lend/borrow') ||
                pathname.startsWith('/lend/dashboard')
              }
            >
              <img src={require(`./../../assets/png/Header/lend${/lend/.test(curIndex) ? '_active':''}.png`)} alt="" />
              {t('Lend')}
            </StyledNavLink>
          </div> */}
          {/* <div
            onClick={() => {
              setCurIndex('faucet')
              onOpen()
            }}
          >
            <StyledNavLink id={`swap-nav-link`} to={'/faucet'}>
              <img src={require(`./../../assets/png/Header/faucet${/faucet/.test(curIndex) ? '_active':''}.png`)} alt="" />
              {t('faucet')}
            </StyledNavLink>
          </div> */}
          <div onClick={onOpen}>
            <StyledExternalLink id={`stake-nav-link`} href={'https://clover-info.huckleberry.finance/'}>
              <img src={require(`./../../assets/png/Header/analytics${curIndex === 'analytics' ? '_active':''}.png`)} alt="" />
              {t('Analytics')}
            </StyledExternalLink>
          </div>
          <div onClick={onOpen}>
            <StyledExternalLink id={`stake-nav-link`} href={'https://bridge.wanchain.org/'}>
              <img src={require(`./../../assets/png/Header/bridge${curIndex === 'bridge' ? '_active':''}.png`)} alt="" />
              {t('crossChain')} <span style={{ fontSize: '11px' }}>↗</span>
            </StyledExternalLink>
          </div>
        </HeaderLinks>
        <DividingLine />
        <HeaderLinks>
          <StyledExternalLink id={`stake-nav-link`} href={'https://huckleberry.gitbook.io/introduction'}>
            <AiOutlineFolderOpen color='rgba(255, 255, 255, 0.4)' size={ICONSIZE} style={{marginRight: '0.65rem'}} />
            Docs
          </StyledExternalLink>
          <StyledExternalLink id={`stake-nav-link`} href={'https://vote.huckleberry.finance'}>
          <MdHowToVote  color='rgba(255, 255, 255, 0.4)' size={ICONSIZE} style={{marginRight: '0.65rem'}} />
            Vote
          </StyledExternalLink>
          <StyledExternalLink id={`stake-nav-link`} href={'https://github.com/HuckleberryDex/audit-reports'}>
            <AiOutlineAudit  color='rgba(255, 255, 255, 0.4)' size={ICONSIZE} style={{marginRight: '0.65rem'}} />
            Audit
          </StyledExternalLink>
        </HeaderLinks>
        <HeaderFooterLinks>
          <StyledExternalLink2 id={`stake-nav-link`} href={'https://twitter.com/HuckleberryDex'}>
            <IoLogoTwitter  color='rgba(255, 255, 255, 0.4)' size={ICONSIZE}/>
          </StyledExternalLink2>
          <StyledExternalLink2 id={`stake-nav-link`} href={'https://t.me/HuckleberryDrifters'}>
            <FaTelegramPlane  color='rgba(255, 255, 255, 0.4)' size={ICONSIZE} />
          </StyledExternalLink2>
          <StyledExternalLink2 id={`stake-nav-link`} href={'https://medium.com/@HuckleberryDEX'}>
            <AiOutlineMedium  color='rgba(255, 255, 255, 0.4)' size={ICONSIZE}/>
          </StyledExternalLink2>
          <StyledExternalLink2 id={`stake-nav-link`} href={'https://github.com/huckleberrydex'}>
            <RiGithubLine  color='rgba(255, 255, 255, 0.4)' size={ICONSIZE}/>
          </StyledExternalLink2>
        </HeaderFooterLinks>
      </List>
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
      <Bottom>
        {/* <NavLink id={`swap-nav-link`} to={'/swap'} onClick={onOpen}>
          <LogoBtn />
        </NavLink> */}
        <RowBetween>
          <div style={{display: 'flex', flex: 1}}>
            <HideSmallCus>
              {chainId && NETWORK_LABELS[chainId] && (
                <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
              )}
            </HideSmallCus>
            {availableClaim && !showClaimPopup && (
              <UNIWrapper onClick={toggleClaimModal}>
                <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                  <TYPE.white padding="0 2px">
                    {claimTxn && !claimTxn?.receipt ? <Dots>Claiming FINN</Dots> : 'Claim FINN'}
                  </TYPE.white>
                </UNIAmount>
                <CardNoise />
              </UNIWrapper>
            )}
            {!availableClaim && aggregateBalance && (
              <UNIWrapper onClick={() => {
                onOpen()
                setShowUniBalanceModal(true)
              }} style={{display: 'flex'}}>
                <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                  {account && (
                    <HideSmall>
                      <TYPE.white
                        style={{
                          paddingRight: '.4rem',
                          color:'rgb(0,169,157)'
                        }}
                      >
                        <CountUp
                          key={countUpValue}
                          isCounting
                          start={parseFloat(countUpValuePrevious)}
                          end={parseFloat(countUpValue)}
                          thousandsSeparator={','}
                          duration={1}
                        />
                      </TYPE.white>
                    </HideSmall>
                  )?
                  (
                    <HideSmall>
                      <TYPE.white
                        style={{
                          paddingRight: '.4rem',
                          color:'white'
                        }}
                      >
                        <CountUp
                          key={countUpValue}
                          isCounting
                          start={parseFloat(countUpValuePrevious)}
                          end={parseFloat(countUpValue)}
                          thousandsSeparator={','}
                          duration={1}
                        />
                      </TYPE.white>
                    </HideSmall>
                  )
                  :<img style={{marginLeft:'-20px',marginRight:'5px',marginTop:'-1px'}} height={'37px'} src="./images/FINN/05.png" alt="logo" />
                  // :<img style={{marginLeft:'-15px',marginRight:'5px'}} height={'46px'} src="./images/HUCKToken.svg" alt="logo" />
                  
                  
                }
                  FINN
                </UNIAmount>
                <CardNoise />
                <PriceText>${uniPrice?.toFixed(4) ?? '-'}</PriceText>
              </UNIWrapper>
            )}
          </div>
          {
            show &&
            (
              <div style={{display: 'flex'}}>
                <Settings />
                <NetworkSwitch />
                {/* <Menu /> */}
              </div>
            )
          }
        </RowBetween>
      </Bottom>
    </MenuCon>
  )
}
