import { ChainId } from '@huckleberry/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x1Fe0C23940FcE7f440248e00Ce2a175977EE4B16',
  [ChainId.MOON_MAINNET]: '0x1Fe0C23940FcE7f440248e00Ce2a175977EE4B16',
  [ChainId.MOON_TESTNET]: '0x136333217C18Cd6E018B85Aaf8Bd563EB72E97Fd',
  [ChainId.CLOVER_PARACHAIN]: '0xc95a4bc5C14CC6a23AF46BA50D3785d5fd55446d',

}

export { MULTICALL_ABI, MULTICALL_NETWORKS }