import { ChainId, TokenAmount } from '@huckleberry/sdk'
import React, { useState, useEffect } from 'react'
// import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { useActiveWeb3React } from '../../hooks'

import { 
  // useETHBalances, 
  useAggregateUniBalance } from '../../state/wallet/hooks'
import { CardNoise } from '../earn/styled'
import { CountUp } from 'use-count-up'
import { TYPE, 
  ExternalLink 
} from '../../theme'

import { YellowCard } from '../Card'
import Settings from '../Settings'
// import Menu from '../Menu'
import NetworkSwitch from '../NetworkSwitch'

import Row, { RowFixed, RowBetween } from '../Row'
import Web3Status from '../Web3Status'
import ClaimModal from '../claim/ClaimModal'
import { useToggleSelfClaimModal, useShowClaimPopup } from '../../state/application/hooks'
import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import { Dots } from '../swap/styleds'
import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
// import AirDropContent from './AirDropContent'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { FINN } from '../../constants'
import Bg from './../Faucet/Bg'
import SwapMenu from './SwapMenu'
import { isMobile } from 'react-device-detect'

import headerMenu from '../../assets/png/Header/header_menu_btn.png'
import mobileLogo from '../../assets/png/Header/mobile_logo.png'
// import { ChevronDown } from 'react-feather'

import BgmBtn from '../Bgm'

const HeaderMobile = styled.div`
  padding: 0 1rem;
  background: rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  // z-index: 9;
  backdrop-filter: blur(10px);
`
const BtnBase = styled.div`
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  border: 1px solid;
  background-size: 1rem 1rem;
  background-position: center;
  background-repeat: no-repeat;
`

const NavButton = styled(BtnBase)`
  background-image: url(${headerMenu});
  background-color: rgba(66, 181, 217, 0.1);
  border-color: ${({ theme }) => theme.primary7};
  margin-right: 3.75rem;
`

const LogoButton = styled(BtnBase)`
  background-image: url(${mobileLogo});
  background-color: rgba(66, 181, 217, 0.1);
  background-size: contain;
  border: none;
`

const MobileBtnGroup = styled.div`
  display: flex;
`

const FooterMobile = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;
  background: rgba(0, 0, 0, 0.55);
  border-radius: 1.5rem 1.5rem 0px 0px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  backdrop-filter: blur(10px);
`

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 0;
  padding: 1rem;
  z-index: 2;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 10px 10px 0 0;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  margin-left:280px;
  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    padding: 1rem 1rem 1rem 1rem;
    justify-content: center;
  `};
`

// const fadeIn = keyframes`
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//     display: block;
//   }
// `
// const UpNav = styled.div`
//   position: relative;
//   &:hover .river_nav {
//     display: block;
//     opacity: 1;
//     animation: 0.4s ${fadeIn} ease-out;
//   }
// `

// const RiverGroup = styled.div`
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   margin-right: ${100 / 1130 * 8 * 1.2}vw;
//   :hover .downNav {
//     color: ${ ({ theme }) => theme.navTxtActive };
//     stroke: ${ ({ theme }) => theme.navTxtActive };
//   }
// `

// const RiverNav = styled.div`
//   background: ${ ({ theme }) => theme.navDown };
//   box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
//   border-radius: 10px;
//   padding: 0 1rem;
// `

// const RiverNavCon = styled.div`
//   position: absolute;
//   bottom: 1px;
//   left: 50%;
//   transform: translate(-50%, 100%);
//   padding-top: 10px;
//   display: none;
//   opacity: 0;
// `

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0071bc;
  border-radius:10px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid #0071bc;
  }
  /* :hover {
    background-color: ${({ theme, active }) => (!active ? theme.bg2 : theme.bg4)};
  } */
  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    margin-left: 8px;
  `};
`

const UNIAmount = styled(AccountElement)`
  
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background: #42B5D9;
  border-radius: 8px;
  color: white;
`

// const AirDrop = styled(AccountElement)`
//   padding: 4px 8px;
//   height: 36px;
//   font-weight: 500;
//   background: #572FA3;
//   border-radius: 8px;
//   border:2px solid #572FA3;
//   color: white;
// `

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;
  margin-right: 8px;
  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    /*display: none;*/
  `};
`

const HideSmallCus = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    /*display: none;*/
  `};
  margin-right: 8px;
`

const NetworkCard = styled(YellowCard)`
border-radius:10px;
  padding: 8px 12px;
  background:white;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    
  `};
`

// const BalanceText = styled(Text)`
// color:white;
//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     display: none;
//   `};
// `

// const Title = styled.a`
//   display: flex;
//   align-items: center;
//   pointer-events: auto;
//   justify-self: flex-start;
//   margin-right: 12px;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     justify-self: center;
//   `};
//   :hover {
//     cursor: pointer;
//   }
// `

// const UniIcon = styled.div`
//   margin-bottom: -6px;
// `

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius:10px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.navTxt };
  font-size: 18px;
  width: fit-content;
  margin-right: ${100 / 1130 * 8 * 1.2}vw;
  font-weight: 400;

  &.${activeClassName} {
    border-radius:10px;
    color: ${({ theme }) => theme.navTxtActive};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.navTxtActive)};
  }
`
/* const ImgNavLine = styled(StyledNavLink).attrs({
  activeClassName
})`
  margin-right: 8px;
` */
/* const RiverNavLink = styled(StyledNavLink).attrs({
  activeClassName
})`
  color: ${ ({theme}) => theme.navDownTxt };
  font-size: 0.875rem;
  padding: 0.7rem 0;
  white-space: nowrap;
  font-weight: normal;

  &.${activeClassName} {
    color: ${ ({theme}) => theme.navDownTxtActive };
    zoom: 1.1;
  }
  :hover,
  :focus {
    color: ${({ theme }) => theme.navDownTxtActive };
  }
` */

const PriceText = styled.div`
  position: relative;
  z-index: -1;
  margin-left: -12px;
  background-color: #2C2F36;
  border: 1px solid #42B5D9;
  padding: 0 8px 0 20px;
  color: #4DD4FE;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius:10px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.navTxt};
  font-size: 18px;
  width: fit-content;
  margin-right: ${100 / 1130 * 8 * 1.2}vw;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 10px;
    font-weight: 600;
    color: ${({ theme }) => theme.navTxtActive};
  }

  :hover,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => darken(0.1, theme.navTxtActive)};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      /*display: none;*/
`}
`
/* const StyledSkinTxt = styled(Text)<{riverActive:boolean}>`
  color: ${ ({riverActive, theme}) => riverActive ? theme.navTxtActive : theme.navTxt };
` */
const BottomLine = styled.div`
  width: 35px;
  height: 35px;
  background-color: #404450;
  padding: 0.15rem 0.5rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
`;

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.MOON_MAINNET]: '',
  [ChainId.MOON_TESTNET]: 'Testnet',
  [ChainId.CLOVER_PARACHAIN]: '',
}

interface AddEthereumChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  /* const theme = useContext(ThemeContext) */

  /* const airDropEnable = true; // UI */

  // const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  const uniPrice = useUSDCPrice(chainId ? FINN[chainId] : undefined)

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  /* const [showAirDropModal, setShowAirDropModal] = useState(false) */
  const showClaimPopup = useShowClaimPopup()

  const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'

  const [showSwapMenu, setShowSwapMenu] = useState(false)
  const [riverIndex, setRiverIndex] = useState(0)
  const [, setRiveActive] = useState(false)

  useEffect(()=>{
    if (window.ethereum && Number(chainId) !== 1024) {
      const params : AddEthereumChainParameter = {
        chainId: '0x400', // A 0x-prefixed hexadecimal string
        chainName: 'CLV Parachain',
        nativeCurrency: {
          name: 'CLV',
          symbol: 'CLV', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://api-para.clover.finance'],
        blockExplorerUrls: ['https://clv.org'],
      } as AddEthereumChainParameter

      (window.ethereum as any)
        .request({
          method: 'wallet_addEthereumChain',
          params: [params],
        })
        .then((result: any) => {
          result && console.debug(result)
        })
        .catch((error: any) => {
          error && console.debug(error)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const hash = window.location.hash;
    // console.log(/excursion|farm|tomhouse/.test(hash))
    if (/excursion|farm|tomhouse/.test(hash)) 
     setRiveActive(true);
    else
      setRiveActive(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riverIndex])

  return (
    <>
      {
        isMobile ?
          (<>
            <HeaderMobile>
              <Bg />
              <RowBetween>
                <MobileBtnGroup>
                  <NavButton onClick={() => setShowSwapMenu(!showSwapMenu)}></NavButton>
                  {
                    false && (
                      <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
                        <LogoButton />
                      </StyledNavLink>
                    )
                  }
                </MobileBtnGroup>
                <RowBetween>
                  <Web3Status />
                </RowBetween>
              </RowBetween>
            </HeaderMobile>
            <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
              <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
            </Modal>
            <FooterMobile>
              <Bg />
              <RowBetween>
                <div style={{display: 'flex', flex: 1, alignItems: 'center'}}>
                  <HideSmallCus>
                    {chainId && NETWORK_LABELS[chainId] && (
                      <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
                    )}
                  </HideSmallCus>
                  {availableClaim && !showClaimPopup && (
                    <UNIWrapper onClick={toggleClaimModal}>
                      <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                        <TYPE.white padding="0 2px">
                          {claimTxn && !claimTxn?.receipt ? <Dots>Claiming FINN</Dots> : 'Claim FINN'}
                        </TYPE.white>
                      </UNIAmount>
                      <CardNoise />
                    </UNIWrapper>
                  )}
                  {!availableClaim && aggregateBalance && (
                    <UNIWrapper onClick={() => setShowUniBalanceModal(true)} style={{display: 'flex'}}>
                      <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                        {account && (
                          <HideSmall>
                            <TYPE.white
                              style={{
                                paddingRight: '.4rem',
                                color:'rgb(0,169,157)'
                              }}
                            >
                              <CountUp
                                key={countUpValue}
                                isCounting
                                start={parseFloat(countUpValuePrevious)}
                                end={parseFloat(countUpValue)}
                                thousandsSeparator={','}
                                duration={1}
                              />
                            </TYPE.white>
                          </HideSmall>
                        )?
                        (
                          <HideSmall>
                            <TYPE.white
                              style={{
                                paddingRight: '.4rem',
                                color:'white'
                              }}
                            >
                              <CountUp
                                key={countUpValue}
                                isCounting
                                start={parseFloat(countUpValuePrevious)}
                                end={parseFloat(countUpValue)}
                                thousandsSeparator={','}
                                duration={1}
                              />
                            </TYPE.white>
                          </HideSmall>
                        )
                        :<img style={{marginLeft:'-20px',marginRight:'5px',marginTop:'-1px'}} height={'37px'} src="./images/FINN/05.png" alt="logo" />
                        // :<img style={{marginLeft:'-15px',marginRight:'5px'}} height={'46px'} src="./images/HUCKToken.svg" alt="logo" />
                        
                        
                      }
                        FINN
                      </UNIAmount>
                      <CardNoise />
                      <PriceText>${uniPrice?.toFixed(3) ?? '-'}</PriceText>
                    </UNIWrapper>
                  )}
                </div>
                {
                  !showSwapMenu &&
                  (
                    <div style={{display: 'flex'}}>
                      <BottomLine>
                        <BgmBtn />
                      </BottomLine>
                      <Settings />
                      <NetworkSwitch />
                      {/* <Menu /> */}
                    </div>
                  )
                }
              </RowBetween>
            </FooterMobile>
            <SwapMenu show={Boolean(showSwapMenu)} onOpen={() => setShowSwapMenu(false)} />
          </>)
        :
          (<HeaderFrame>
            <ClaimModal />
            <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
              <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
            </Modal>
            <HeaderRow>
              <HeaderLinks id="header-link" onClick={() => setRiverIndex(riverIndex + 1)}>
                {
                  false && (<>
                    
                    <StyledNavLink id={`stake-nav-link`} to={'/excursion'}>
                      {t('excursion')}
                    </StyledNavLink>
                    <StyledExternalLink id={`stake-nav-link`} href={'https://vote.wandevs.org/#/wanswap'}>
                      {t('vote')} <span style={{ fontSize: '11px' }}>↗</span>
                    </StyledExternalLink>
                    <StyledExternalLink id={`stake-nav-link`} href={'https://bridge.wanchain.org/'}>
                      {t('crossChain')} <span style={{ fontSize: '11px' }}>↗</span>
                    </StyledExternalLink>
                    
                    <StyledExternalLink id={`stake-nav-link`} href={'https://auction.wanswap.finance/'}>
                      {t('auction')} <span style={{ fontSize: '11px' }}>↗</span>
                    </StyledExternalLink>
                    </>
                  )
                }
              </HeaderLinks>
            </HeaderRow>
            <HeaderControls>
              <HeaderElement>
                <HideSmallCus>
                  {chainId && NETWORK_LABELS[chainId] && (
                    <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
                  )}
                </HideSmallCus>
                {availableClaim && !showClaimPopup && (
                  <UNIWrapper onClick={toggleClaimModal}>
                    <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                      <TYPE.white padding="0 2px">
                        {claimTxn && !claimTxn?.receipt ? <Dots>Claiming FINN</Dots> : 'Claim FINN'}
                      </TYPE.white>
                    </UNIAmount>
                    <CardNoise />
                  </UNIWrapper>
                )}
                {!availableClaim && aggregateBalance && (
                  <UNIWrapper onClick={() => setShowUniBalanceModal(true)} style={{display: 'flex', marginLeft: account ? '' : '12px'}}>
                    <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                      {account && (
                        <HideSmall>
                          <TYPE.white
                            style={{
                              paddingRight: '.4rem',
                              color:'rgb(0,169,157)'
                            }}
                          >
                            <CountUp
                              key={countUpValue}
                              isCounting
                              start={parseFloat(countUpValuePrevious)}
                              end={parseFloat(countUpValue)}
                              thousandsSeparator={','}
                              duration={1}
                            />
                          </TYPE.white>
                        </HideSmall>
                      )?
                      (
                        <HideSmall>
                          <TYPE.white
                            style={{
                              paddingRight: '.4rem',
                              color:'white'
                            }}
                          >
                            <CountUp
                              key={countUpValue}
                              isCounting
                              start={parseFloat(countUpValuePrevious)}
                              end={parseFloat(countUpValue)}
                              thousandsSeparator={','}
                              duration={1}
                            />
                          </TYPE.white>
                        </HideSmall>
                      )
                      :<img style={{marginLeft:'-20px',marginRight:'5px',marginTop:'-1px'}} height={'37px'} src="./images/FINN/05.png" alt="logo" />
                      // :<img style={{marginLeft:'-15px',marginRight:'5px'}} height={'46px'} src="./images/HUCKToken.svg" alt="logo" />
                      
                      
                    }
                      FINN
                    </UNIAmount>
                    <CardNoise />
                    <PriceText>${uniPrice?.toFixed(3) ?? '-'}</PriceText>
                  </UNIWrapper>
                )}
                <AccountElement active={!!account} style={{ pointerEvents: 'auto' }} >
                  {/* {account && userEthBalance ? (
                    <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                      {userEthBalance?.toSignificant(4)} MOVR
                    </BalanceText>
                  ) : null} */}
                  <Web3Status />
                </AccountElement>
              </HeaderElement>
              <HeaderElementWrap>
                <BottomLine>
                  <BgmBtn />
                </BottomLine>
                {<NetworkSwitch /> }
                <Settings />
              </HeaderElementWrap>
            </HeaderControls>
          </HeaderFrame>)
      }
    </>
  )
}
