import { transparentize } from 'polished'
import React, { useMemo } from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme
} from 'styled-components'
/*import { useIsDarkMode } from '../state/user/hooks'*/
import { Text, TextProps } from 'rebass'
import { Colors } from './styled'
import { isMobile } from 'react-device-detect'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
  upToSupLarge: 1420
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ; (accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#313131'

export function colors(darkMode: boolean): Colors {
  darkMode = false;
  
  // update skin
  const deepMode = false;
  
  return {
    
    // update skin
    navTxt: deepMode ? '#4dd5fe97' : 'rgba(30, 30, 30, 0.7)',
    navTxtActive: deepMode ? '#4DD4FE' : 'rgba(30, 30, 30, 1)',
    navDown: deepMode ? '#2B414D' : 'rgba(0, 0, 0, 0.8)',
    navDownTxt: deepMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(255, 255, 255, 0.7)',
    navDownTxtActive: deepMode ? 'rgb(26, 200, 254)' : '#42B5D9',
    swapPanelBg: deepMode ? '#222E33' : 'rgba(0, 0, 0, 0.8)',
    swapDropDownBg:  deepMode ? 'rgba(255, 255, 255, 0.01)' : 'rgba(0, 0, 0, 0.6)',
    swapDropDownBorder:  deepMode ? '#384347' : 'rgba(255, 255, 255, 0.1)',
    cardBg: deepMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.6)',
    liquidityCardBg: deepMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.6)',
    liquidityBorder: deepMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.2)',
    liquidityTxt: deepMode ? 'rgb(77, 212, 254)' : 'rgba(0, 0, 0, 0.6)',
    liquidityCircleBorder: deepMode ? 'rgba(0,169,157,1)' : 'rgba(0, 0, 0, 0.6)',
    liquidityCircleTxt: deepMode ? 'rgba(0,169,157,1)' : 'rgba(0, 0, 0, 0.6)',
    liquidityBg: deepMode ? '#fff': 'rgba(0, 0, 0, 0.6)',
    liquidityAddTxt: deepMode ? '#000': '#fff',
    liquidityAddAllow: deepMode ? '#999': '#fff',
    liquidityAddInputBorder: deepMode ? 'rgba(0, 0, 0, 0)': 'rgba(255, 255, 255, 0.2)',
    liquidityAddInputBg: deepMode ? '#fff': 'rgba(0, 0, 0, 0)',
    liquidityAddCardTxt: deepMode ? '#313131': '#fff',
    liquidityAddCardBg: deepMode ? '#FDFEFE': 'rgba(0, 0, 0, 0.4)',
    liquidityAddCardItemBg: deepMode ? '#F3F9FA': 'rgba(0, 0, 0, 0)',
    liquidityAddModalBg: deepMode ? '#42B5D9': 'rgba(0, 0, 0, 0.1)',
    lighCardBg: deepMode ? '#202B30' : 'rgba(0, 0, 0, 0.6)',
    riverArrowBg: deepMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.6)',
    riverBtnBg: deepMode ? '#222E33' : 'rgba(0, 0, 0, 0)',
    riverBtnBorder: deepMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.6)',
    riverBtnBorderActive: deepMode ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0)',
    riverBtnActiveBg: deepMode ? '#42B5D9' : '#42B5D9',
    riverBtnTxt: deepMode ? '#42B5D9' : 'rgba(0, 0, 0, 0.6)',
    riverBtnTxtActive: deepMode ? '#42B5D9' : '#fff',
    lendBg: deepMode ? 'rgba(9,60,58,0.95)' : 'rgba(0, 0, 0, 0.6)',
    lendListBg2: deepMode ? 'rgba(9,60,58,0.8)' : 'rgba(0, 0, 0, 0.7)',
    lendNavBorder: deepMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.2)',
    faucetBg: deepMode ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.6)',
    faucetBorder: deepMode ? '#42B5D9' : 'rgba(255, 255, 255, 0.2)',
    modalBg: deepMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.6)',
    modalContentBg: deepMode ? '#fff' : 'rgba(0, 0, 0, 0.2)',
    modalClose: deepMode ? '#C3C5CB' : '#fff',
    modalTxt: deepMode ? '#C3C5CB' : '#fff',
    modalCardBg: deepMode ? '#fff' : 'rgba(0, 0, 0, 0)',
    modalCardActiveBg: deepMode ? 'rgba(66, 181, 217, 0.11)' : 'rgba(66, 181, 217, 0.2)',
    modalCardTxt: deepMode ? '#172329' : '#FFFFFF',
    modalCardTxtActive: deepMode ? '#2F9CA3' : '#42B5D9',
    modalBotomTxt: deepMode ? '#313131': '#fff',
    modalWalletCardBg: deepMode ? '#F2F2F2' : 'rgba(0, 0, 0, 0)',
    modalWalletCardBorder: deepMode ? 'rgba(0, 0, 0, 0)' : 'rgba(255, 255, 255, 0.2)',
    modalWalletDropBg: deepMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0)',
    modalWalletDropTxt: deepMode ? '#999999' : '#42B5D9',
    currencySearchBg: deepMode ? '#fff': 'rgba(0, 0, 0, 0.3)',
    currencyTxt: deepMode ? '#2F9CA3' : '#fff',
    currencySeparator: deepMode ? '#fff' : 'rgba(255, 255, 255, 0.1)',
    currencyListTxt: deepMode ? 'black' : '#fff',
    currencyBtnTxt: deepMode ? '#000' : '#42B5D9',
    lendModalBg: deepMode ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.3)',
    finnbarModalBg: deepMode ? '#fff' : '#1e1e1e',
    

    // base
    white,
    black,

    // text
    text1: darkMode ? '#4DD4FE' : '#4DD4FE',
    text2: darkMode ? '#3487A1' : '#3487A1',
    text3: darkMode ? '#6C7284' : '#888D9B',
    text4: darkMode ? '#565A69' : '#C3C5CB',
    text5: darkMode ? '#2C2F36' : '#2C2F36',
    text6: darkMode ? '#313131' : '#313131',
    text7: darkMode ? '#313131' : '#172329',
    text8: darkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.3)',
    text9: darkMode ? '#FFC70B' : '#FFC70B',
    text10: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.1)',
    text11: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(255, 255, 255, 0.7)',

    // backgrounds / greys
    bg1: darkMode ? '#212429' : '#FFFFFF',
    bg2: darkMode ? '#2C2F36' : '#F7F8FA',
    bg3: darkMode ? '#40444F' : '#EDEEF2',
    bg4: darkMode ? '#565A69' : '#CED0D9',
    bg5: darkMode ? '#6C7284' : '#888D9B',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    //primary colors
    primary1: darkMode ? '#2F9CA3' : '#2F9CA3',
    primary2: darkMode ? '#2F9CA3' : '#FF8CC3',
    primary3: darkMode ? '#2F9CA3' : '#FF99C9',
    primary4: darkMode ? '#376bad70' : '#F6DDE8',
    primary5: darkMode ? '#153d6f70' : '#FDEAF1',
    primary6: darkMode ? '#42B5D9' : 'rgba(66, 181, 217, 0.11)',
    primary7: darkMode ? '#42B5D9' : '#42B5D9',
    primary8: darkMode ? '#E3E3E3' : '#E3E3E3',
    primary9: darkMode ? '#42B5D9' : 'rgba(66, 181, 217, 0.03)',
    

    // color text
    primaryText1: darkMode ? '#6da8ff' : '#0071bc',

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#0071bc',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    // other
    red1: '#FF6871',
    red2: '#F82D3A',
    red3: '#42B5D9',
    red4: '#CF0000',
    green1: '#00CF1F',
    yellow1: '#FFE270',
    yellow2: '#F3841E',
    blue1: '#2172E5',
    white_o5: 'rgba(255, 255, 255, 0.5)',
    white_o7: 'rgba(255, 255, 255, 0.7)',

    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = true;/*useIsDarkMode()*/

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text) <{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  black_main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'#000'} {...props} />
  },
  custom1(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'#42B5D9'} {...props} />
  },
  custom2(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'#999'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  body1(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'#313131'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  yellow_o(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'#FFC70B'} {...props} />
  },
  color_o(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'#40ADCF'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  white_o(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'text8'} {...props} />
  },
  white_o1(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'text11'} {...props} />
  },
  white_o5(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'white_o5'} {...props} />
  },
  white_o7(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'white_o7'} {...props} />
  },
  green(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'green1'} {...props} />
  },
  red(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'red4'} {...props} />
  },
  red_o(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'#FF5B45'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  }
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Inter', sans-serif;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Inter var', sans-serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

 a {
   color: ${colors(false).blue1}; 
 }

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background:#e6e6e6 !important;
 
}
body
{
  background-image:none !important;
  
}
div[data-reach-dialog-content] a
{
  color:#0071bc ;
}
#swap-currency-input
{
  margin-bottom:-25px;
}
#swap-currency-output-2
{
  margin-top:-25px;
}
#logo-background
{
  /* background: linear-gradient(90deg, rgb(0, 169, 157) 0%, rgb(40, 171, 224) 100%);
  padding: 8px;
  padding-left:10px;
  padding-right:10px;
  position: absolute;
  top: 0px;
  padding-top: 20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; */
}
#center-logo
{
  margin-bottom:40px;
  zoom:0.8;
  position: relative;
    z-index: 1;
}

#animate-zoom
{
  display:inline-block;
  
  animation: blink-animation 0.4s infinite;
}
@keyframes blink-animation {
  form {
    transform:scale(1)
  }
  to {
    transform:scale(1.3)
  }
}


.token-amount-input::placeholder , .recipient-address-input::placeholder
{
  color:#999 !important;
}
#pseudo-loader
{
  position: absolute;
  filter: drop-shadow(10px 10px 10px #0000002e) drop-shadow(-10px -10px 10px #ffffff90);
  animation: none;
}
/* #deco_bg_buttom
{
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  position: fixed;
  bottom: -200px;
  left: -300px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
} */
#deco_bg_top
{
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-size: cover;
  background-position: right top;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

#shadow_bottom
		{
			background:url(./images/shadow_bottom.png) repeat-x;
			width:100%;
			height:860px;
			position:fixed;
			bottom:0;
			z-index:-1;
    }
    
@media only screen and (max-width: 1320px) {
  #logo-background
  {
    background:transparent !important;
  }
  #header-link
  {
    margin-left:0;
  }
  #shadow_bottom
		{
      opacity:0.6;
    }
    #center-logo
    {
      display:none
    }

    #logo-symbol
    {
      display:none;
    }
    #logo-full
    {
      display:block !important;
      margin:0 auto;
      margin-top:20px;
    }
    #logo-wrapper
    {
      margin-right:0px;
    }
    #body-wrapper
    {
     
    }
}

#stake-liquidity-token div:first-child
{
  background: transparent !important;
}

div[data-reach-dialog-overlay] {
  /* background:rgba(255,255,255,0.70) !important; */
  backdrop-filter: blur(10px);
}
div[data-reach-dialog-content]{
  box-shadow: 10px 8px 15px 5px #0000002e, -8px -10px 15px 5px #ffffff90;
  border-radius:20px !important;
}
.bfqITV
{
  border-radius:0 !important;
}
.open-currency-select-button svg path
{
  stroke: #ccc;
}

.open-currency-select-button.sEOpx svg path
{
  stroke: white;
  stroke-width:2px;
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg2};
}

body {
  min-height: 100vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;
  background-image: ${({ theme }) =>
    `radial-gradient(50% 50% at 50% 50%, ${transparentize(0.9, theme.primary1)} 0%, ${transparentize(
      1,
      theme.bg1
    )} 100%)`};
}
`
