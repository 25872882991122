import React, { useState } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import LogoFont from '../../assets/svg/logo_font.svg'
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarFooter,
  SidebarHeader
} from 'react-pro-sidebar';
import { darken } from 'polished';
import { FaTelegramPlane } from 'react-icons/fa';
import { IoSwapVerticalOutline } from 'react-icons/io5';
import { IoMdAnalytics, IoLogoTwitter } from 'react-icons/io';
import { MdHowToVote } from 'react-icons/md';
import { RiGithubLine } from 'react-icons/ri';
import { AiOutlineDollar, AiOutlineFolderOpen, AiOutlineAudit, AiOutlineMedium } from 'react-icons/ai';
import { GiTallBridge } from 'react-icons/gi';
import 'react-pro-sidebar/dist/css/styles.css';
import './index.css';
import { useTranslation } from 'react-i18next'
import { ExternalLink } from '../../theme'
// import Lend from '../../assets/images/Lend.png'
import Adventures from '../../assets/images/Adventures.png'

const ICONSIZE = 20;

const PhishAlert = styled.div`
  width: 250px;
  height: 100vh;
  color: white;
  font-size: 11px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  display: flex;
  flex-direction: column;
`;

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100vh;
  filter: blur(21px);
  background-position: center top;
  background-size: cover;
  background-image: url('./images/christmas_bg.svg');
  background-attachment: fixed;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
`;

const LogoImg = styled.img`
  width: 220px;
`

const activeClassName = 'ACTIVE';

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius:10px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-size: 16px;
  width: fit-content;
  margin-right: ${100 / 1130 * 8 * 1.2}vw;
  font-weight: 400;

  &.${activeClassName} {
    border-radius:10px;
    color: #4DD4FE;
  }

  :hover,
  :focus {
    color: #4DD4FE;
  }
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius:10px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.navTxt};
  font-size: 16px;
  width: fit-content;
  margin-right: ${100 / 1130 * 8 * 1.2}vw;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 10px;
    font-weight: 600;
    color: ${({ theme }) => theme.navTxtActive};
  }

  :hover,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => darken(0.1, theme.navTxtActive)};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      /*display: none;*/
`}
`

export default function SideBar() {
  const { t } = useTranslation()
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return !isMobile ? (
    <PhishAlert>
      <Bg />
      <div style={{ width: '100%', position: 'relative', height: '100%' }}>
        <ProSidebar
          style={{ width: '250px', minWidth: '250px', height: 'calc(100vh - 40px)' }}
          toggled={toggled}
          breakPoint="md"
          onToggle={handleToggleSidebar}
        >
          <SidebarHeader>
            <LogoImg id="logo-symbol" src={LogoFont} alt="logo" />
          </SidebarHeader>

          <SidebarContent style={{ color: 'white', fontSize: '16px', borderTop: 'none' }}>
            <Menu iconShape="circle">
              <MenuItem
                icon={<IoSwapVerticalOutline size={ICONSIZE} color={'#D8D2CE'}/>}
              >
                <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
                  {t('swap')}
                 </StyledNavLink>
              </MenuItem>
              <MenuItem icon={<AiOutlineDollar size={ICONSIZE} color={'#D8D2CE'}/>}>
                <StyledNavLink
                  id={`pool-nav-link`}
                  to={'/pool'}
                  isActive={(match, { pathname }) =>
                    Boolean(match) ||
                    pathname.startsWith('/add') ||
                    pathname.startsWith('/remove') ||
                    pathname.startsWith('/create') ||
                    pathname.startsWith('/find')
                  }
                >
                  {t('pool')}
                </StyledNavLink>
              </MenuItem>
              <SubMenu
                title={'Adventures'}
                icon={<img src={Adventures} alt="Adventures"/>}
              >
                <MenuItem><StyledNavLink style={{ fontSize: '14px' }} id={`stake-nav-link`} to={'/farm'}>{t('sampleRiver')}</StyledNavLink></MenuItem>
                <MenuItem><StyledNavLink style={{ fontSize: '14px' }} id={`stake-nav-link`} to={'/tomhouse'}>{t('Tom\'s House')}</StyledNavLink></MenuItem>
                <MenuItem><StyledNavLink style={{ fontSize: '14px' }} id={`stake-nav-link`} to={'/excursion'}>{t('sampleExcursions')}</StyledNavLink></MenuItem>
              </SubMenu>
              {/**
              <MenuItem icon={<img src={Lend} alt="Lend" />}>
                <StyledNavLink
                  id={`lend-nav-link`}
                  to={'/lend/dashboard'}
                  isActive={(match, { pathname }) =>
                    Boolean(match) ||
                    pathname.startsWith('/lend/reservoir/info') ||
                    pathname.startsWith('/lend/deposit') ||
                    pathname.startsWith('/lend/borrow') ||
                    pathname.startsWith('/lend/dashboard')
                  }>
                  {t('Lend')}
                </StyledNavLink>
              </MenuItem>
              <MenuItem icon={<FaFaucet size={ICONSIZE} color={'#D8D2CE'}/>}>
                <StyledNavLink id={`faucet-nav-link`} to={'/faucet'}>
                  {t('faucet')}
                </StyledNavLink>
              </MenuItem>  
            */}
              <MenuItem icon={<IoMdAnalytics size={ICONSIZE} color={'#D8D2CE'}/>}>
                <StyledExternalLink id={`stake-nav-link`} href={'https://clover-info.huckleberry.finance/'}>
                  {t('Analytics')}
                </StyledExternalLink>
              </MenuItem>
              <SubMenu
                title={'Bridges'}
                icon={<GiTallBridge size={ICONSIZE} color={'#D8D2CE'}/>}
              >
                <MenuItem>
                  <StyledExternalLink id={`stake-nav-link`} href={'https://bridge.wanchain.org/'}>
                    Wan Bridge <span style={{ fontSize: '11px' }}>↗</span>
                  </StyledExternalLink>
                </MenuItem>
                <MenuItem>
                  <StyledExternalLink id={`stake-nav-link`} href={'https://bridge.clv.org/'}>
                    CLV EVM Bridge <span style={{ fontSize: '11px' }}>↗</span>
                  </StyledExternalLink>
                </MenuItem>
              </SubMenu>
            </Menu>
            <Menu iconShape="circle">
              <MenuItem icon={<AiOutlineFolderOpen size={ICONSIZE} />}>
                <StyledExternalLink id={`stake-nav-link`} href={'https://huckleberry.gitbook.io/introduction'}>
                  Docs
                </StyledExternalLink>
              </MenuItem>
              <MenuItem icon={<MdHowToVote size={ICONSIZE} />}>
                <StyledExternalLink id={`stake-nav-link`} href={'https://vote.huckleberry.finance'}>
                  Vote
                </StyledExternalLink>
              </MenuItem>
              <MenuItem icon={<AiOutlineAudit size={ICONSIZE} />}>
                <StyledExternalLink id={`stake-nav-link`} href={'https://github.com/HuckleberryDex/audit-reports'}>
                  Audit
                </StyledExternalLink>
              </MenuItem>
            </Menu>
          </SidebarContent>


          <SidebarFooter style={{ textAlign: 'center', borderTop: 'none' }}>
            <ThirdPartyCon>
              <StyledExternalLink id={`stake-nav-link`} href={'https://twitter.com/HuckleberryDex'}>
                <IoLogoTwitter size={ICONSIZE} color={'#ddd'}/>
              </StyledExternalLink>
              <StyledExternalLink id={`stake-nav-link`} href={'https://t.me/HuckleberryDrifters'}>
                <FaTelegramPlane size={ICONSIZE} color={'#ddd'} />
              </StyledExternalLink>
              <StyledExternalLink id={`stake-nav-link`} href={'https://medium.com/@HuckleberryDEX'}>
                <AiOutlineMedium size={ICONSIZE} color={'#ddd'}/>
              </StyledExternalLink>
              <StyledExternalLink id={`stake-nav-link`} href={'https://github.com/huckleberrydex'}>
                <RiGithubLine size={ICONSIZE} color={'#ddd'}/>
              </StyledExternalLink>
            </ThirdPartyCon>
          </SidebarFooter>

        </ProSidebar>
      </div>
      
    </PhishAlert>
  ) : null
}

const ThirdPartyCon = styled.div`
  display: flex;
  padding: 0 10%;
  width: 100%;
  justify-content: space-around;
`