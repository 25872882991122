import React, { useState, useCallback, useContext } from 'react'
// import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled, { ThemeContext } from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonConfirmed, ButtonError } from '../Button'
import ProgressCircles from '../ProgressSteps'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { TokenAmount } from '@huckleberry/sdk'
import { useActiveWeb3React } from '../../hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useExcursionContractV3 } from '../../hooks/useContract'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
// import { splitSignature } from 'ethers/lib/utils'
import { 
  // StakingInfo, 
  useDerivedStakeInfo } from '../../state/stake/hooks'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import { EXCURSION_ADDRESS_V3 } from '../../constants/abis/bridge'
import { SUGGEST_GAS_PRICE, TOM } from '../../constants'
// import { commafy2 } from '../../utils/commafy'


const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: any
  userLiquidityUnstaked: TokenAmount | undefined
}

export default function StakingAutoTomModal({ isOpen, onDismiss, stakingInfo, userLiquidityUnstaked }: StakingModalProps) {
  const { chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const tom = chainId ? TOM[chainId] : TOM[1024]

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, tom, stakingInfo.balance)

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  const dummyPair = undefined
  // const pairContract = usePairContract(dummyPair.liquidityToken.address)

  // approval data for stake
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(
    stakingInfo.balance,
    chainId ? EXCURSION_ADDRESS_V3[chainId] : undefined
  )

  // console.log('!99', approval, stakingInfo.balance)

  // const isArgentWallet = useIsArgentWallet()
  const tomVault = useExcursionContractV3()
  async function onStake() {
    setAttempting(true)
    if (tomVault && parsedAmount) {
      if (approval === ApprovalState.APPROVED) {
        await tomVault
          .deposit(`0x${parsedAmount.raw.toString(16)}`, { gasLimit: 1000000, gasPrice: SUGGEST_GAS_PRICE() })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Deposit TOM`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(stakingInfo.balance)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    stakingInfo.balance && onUserInput(stakingInfo.balance.toExact())
  }, [stakingInfo, onUserInput])


  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90} bg={theme.finnbarModalBg}>
      {!attempting && !hash && (
        <ContentWrapper gap="md">
          <RowBetween>
            <TYPE.mediumHeader>Deposit</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            color={theme.modalCardTxtActive}
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={tom}
            pair={dummyPair}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to deposit: '}
            id="stake-liquidity-token"
          />

          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={approveCallback}
              altDisabledStyle={true}
              confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
              disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
            >
              Approve
            </ButtonConfirmed>
            <ButtonError
              altDisabledStyle={true}
              disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {error ?? 'Deposit'}
            </ButtonError>
          </RowBetween>
          <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} />
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss} isTOM={true} bg={theme.finnbarModalBg}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Depositing TOM</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} TOM</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash} bg={theme.finnbarModalBg}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.white fontSize={20}>Deposited {parsedAmount?.toSignificant(4)} TOM</TYPE.white>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
