import { Trade, TradeType } from '@huckleberry/sdk'
import React, { useContext, useMemo, useState } from 'react'
import { Repeat } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { TYPE } from '../../theme'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  // formatExecutionPrice,
  formatExecutionSegPrice,
  warningSeverity
} from '../../utils/prices'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { StyledBalanceMaxMini, SwapCallbackError } from './styleds'
import { isMobile } from 'react-device-detect'

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm
}: {
  trade: Trade
  allowedSlippage: number
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
}) {
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const theme = useContext(ThemeContext)
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    allowedSlippage,
    trade
  ])
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)
  const { t } = useTranslation()
  return (
    <>
      <AutoColumn gap="0px">
        <RowBetween align="center" backgroundColor="#fff" padding="16px 20px" borderRadius="10px" marginBottom="8px">
          <Text fontWeight={400} fontSize={14} color={theme.text6}>
            {t('price')}
          </Text>
          <Text
            fontWeight={500}
            fontSize={14}
            style={{
              justifyContent: 'center',
              alignItems: 'flex-end',
              display: 'flex'
            }}
          >
            <Text fontSize={16} color={theme.text1}>{formatExecutionSegPrice(trade, showInverted).split('-')[0]}</Text>&nbsp;
            <Text fontSize={12} color={theme.text6}>{formatExecutionSegPrice(trade, showInverted).split('-')[1]}</Text>
            <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
              <Repeat size={14} />
            </StyledBalanceMaxMini>
          </Text>
        </RowBetween>
        <RowBetween backgroundColor="#fff" padding="16px 20px" borderRadius="10px" marginBottom="8px">
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text6}>
              {trade.tradeType === TradeType.EXACT_INPUT ? t('minimumReceived') : t('maximumSold')}
            </TYPE.black>
          </RowFixed>
          <RowFixed>
            <Text style={{
              justifyContent: 'center',
              alignItems: 'flex-end',
              display: 'flex'
            }}>
              <TYPE.black fontSize={16}>
                {trade.tradeType === TradeType.EXACT_INPUT
                  ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                  : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
              </TYPE.black>
              <TYPE.black fontSize={12} marginLeft={'4px'} color={theme.text6}>
                {trade.tradeType === TradeType.EXACT_INPUT
                  ? trade.outputAmount.currency.symbol
                  : trade.inputAmount.currency.symbol}
              </TYPE.black>
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween backgroundColor="#fff" padding="16px 20px" borderRadius="10px" marginBottom="8px">
          <RowFixed>
            <TYPE.black color={theme.text6} fontSize={16} fontWeight={400}>
              {t('priceImpact')}
            </TYPE.black>
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>
        <RowBetween backgroundColor="#fff" padding="16px 20px" borderRadius="10px" marginBottom="8px">
          <RowFixed>
            <TYPE.black fontSize={16} fontWeight={400} color={theme.text6}>
              {t('liquidityProviderFee')}
            </TYPE.black>
          </RowFixed>
          <Text
            style={{
              justifyContent: 'center',
              alignItems: 'flex-end',
              display: 'flex'
            }}
          >
            {realizedLPFee ? 
            (
              <>
                <TYPE.black fontSize={16}>{realizedLPFee?.toSignificant(6)}</TYPE.black>
                &nbsp;
                <TYPE.black fontSize={12}>{trade.inputAmount.currency.symbol}</TYPE.black>
              </>
            )
            : '-'}
          </Text>
        </RowBetween>
      </AutoColumn>

      <AutoRow>
        <ButtonError
          onClick={onConfirm}
          disabled={disabledConfirm}
          error={severity > 2}
          style={{ marginTop: isMobile ? '0' : '10px' }}
          id="confirm-swap-or-send"
        >
          <Text fontSize={20} fontWeight={500}>
            {severity > 2 ? t('swapAnyway') : t('confirmSwap2')}
          </Text>
        </ButtonError>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
