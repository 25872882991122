export function commafy(value) {
  if (value.includes(',')) {
    value = value.split(',').join('');
  }

  let num = Number(value);
  if (num > 1e9) {
    return "$" + (num / 1e9).toFixed(1) + 'B';
  }

  if (num > 1e6) {
    return "$" + (num / 1e6).toFixed(1) + 'M';
  }

  if (num > 1e3) {
    return "$" + (num / 1e3).toFixed(0) + 'K';
  }

  return "$" + num;
}

export function commafy3(value) {
  if (value.includes(',')) {
    value = value.split(',').join('');
  }

  let num = Number(value);
  if (num > 1e9) {
    return (num / 1e9).toFixed(1) + 'B';
  }

  if (num > 1e6) {
    return (num / 1e6).toFixed(1) + 'M';
  }

  if (num > 1e3) {
    return (num / 1e3).toFixed(0) + 'K';
  }

  return num;
}

export function commafy2(num) {
  if (num === 0 || (num && num.toString() === 0)) {
    return '0';
  }

  if (!num || isNaN(num)) {
    return '--';
  }

  num = num.toString();

  if (!num.includes('.')) {
    num += '.0';
  } else {
    if (num.indexOf('.') > 3) {
      num = Number(num).toFixed(1);
    } else if (num.length > 5) {
      num = Number(num).toFixed(2);
    }
  }

  return num.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
    return $1 + ',';
  });
}

export function commafy2Lend(num, decimals) {
  if (num === 0 || (num && +num.toString() === 0)) {
    return '0';
  }

  if (!num || isNaN(num)) {
    return '--';
  }

  num = Number(num).toString();

  if (num.includes('e')) {
    let m = num.match(/\d(?:\.(\d*))?e([+-]\d+)/);
    num = Number(num).toFixed(Math.max(0, (m[1] || '').length - m[2]));
  };

  if (!num.includes('.')) {
    num += '.0';
  } else {
    if (num.indexOf('.') > 3) {
      num = Number(num).toFixed(1);
    } else if (num.length > 5) {
      if (decimals) {
        num = Number(num).toFixed(decimals);
      } else {
        num = Number(num).toFixed(4);
        num = Number(num).toString();
      }
    }
  }

  return num.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
    return $1 + ',';
  });
}

