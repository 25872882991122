import { CurrencyAmount, Currency, TokenAmount, JSBI, Token, Trade, ETHER } from '@huckleberry/sdk'
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react'
import { ArrowDown, LogIn } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import PairChart from '../../components/PairChart'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink, { DefaultVersionLink } from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'
import { useMedia } from 'react-use'
import { BETTER_TRADE_LINK_THRESHOLD, INITIAL_ALLOWED_SLIPPAGE, INITIAL_GAS_PRICE } from '../../constants'
import { getTradeVersion, isTradeBetter } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useDefaultGasPrice, useExpertModeManager, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import { AppBodyV2 } from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import Panel from '../../components/Panel'
import { useTranslation } from 'react-i18next'
import { usePairData } from '../../contexts/PairData'
import styled, { ThemeContext } from 'styled-components'
import { usePair } from '../../data/Reserves'
import { isMobile } from 'react-device-detect'
import { getTokenLogoURL } from '../../components/CurrencyLogo'


export default function Swap() {
  const loadedUrlParams = useDefaultsFromURLSearch()
  const allTokens = useAllTokens();
  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])
  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()
  
  const [defaultGasPrice] = useDefaultGasPrice()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()
  const [, pair] = usePair(currencies[Field.INPUT], currencies[Field.OUTPUT])
  const {
    token0,
    reserve0,
    reserve1,
  } = usePairData(pair?.liquidityToken?.address.toLowerCase()) // default is MOVR-USTD.m
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade
  }
  const trade = showWrap ? undefined : tradesByVersion[toggledVersion]
  const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION]

  // TODO: remove
  const betterTradeLinkVersion: Version | undefined =
    toggledVersion === Version.v2 && isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
      ? Version.v1
      : toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade)
      ? Version.v2
      : undefined

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade)
          ].join('/')
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, account, priceImpactWithoutFee, recipient, recipientAddress, showConfirm, swapCallback, trade])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => {
    onCurrencySelection(Field.OUTPUT, outputCurrency)
  }, [
    onCurrencySelection
  ])
  const below1080 = useMedia('(max-width: 1080px)')

  
  const getTokenByCurrency = (currency: Currency | undefined, tokens: {[address: string]: Token}) => {
    console.log('no method to get token')
    const arr: Array<Token> = Object.values(tokens);
    const eqaulCureencyToken = arr.find((item: Token) => {
      const {
        symbol,
        decimals,
        name
      } = item;
      return symbol === currency?.symbol && decimals === currency?.decimals && name === currency?.name;
    })
    
    return eqaulCureencyToken;
  }
  
  const handleAddToken2MetaMask = useCallback(() => {
    const outputAmount = trade?.outputAmount as TokenAmount;
    const token = outputAmount?.token;

    const tokenAddress = token ? token.address : getTokenByCurrency(outputAmount?.currency, allTokens)?.address;
    const tokenSymbol = token?.symbol;
    const tokenDecimals = token?.decimals;
    const tokenImage = getTokenLogoURL(String(tokenAddress));
    if (!window.ethereum) {
      return;
    }
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    (window.ethereum as any).request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    }).then((ret:any)=>{
      console.log(ret);
      handleConfirmDismiss();
    }).catch((err:any)=>{
      console.error(err);
    });
  }, [allTokens, handleConfirmDismiss, trade])

  return (
    <div style={{width: '100%'}}>
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && urlLoadedTokens[0].symbol !== 'USDC'  && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      <FlexRow>
        <Panel
          style={{
          marginTop: isMobile ? (!showWrap && Boolean(trade)) ? '350px' : '15px' : '0',
          gridColumn: below1080 ? '1' : '2/4',
          gridRow: below1080 ? '' : '1/5',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          backdropFilter: 'blur(20px)'
        }}>
          {/* <Bg /> */}
          <PairChart
            address={pair?.liquidityToken?.address.toLowerCase()}
            base0={reserve1 / reserve0}
            base1={reserve0 / reserve1}
            positive={token0?.symbol === currencies[Field.INPUT]?.symbol}
            currencyIn={currencies[Field.INPUT]}
            currencyOut={currencies[Field.OUTPUT]}
          />
        </Panel>
        <SwapCon>
          <AppBodyV2>
            <SwapPoolTabs active={'swap'} />
            <Wrapper id="swap-page">
              <ConfirmSwapModal
                isOpen={showConfirm}
                trade={trade}
                originalTrade={tradeToConfirm}
                onAcceptChanges={handleAcceptChanges}
                attemptingTxn={attemptingTxn}
                txHash={txHash}
                recipient={recipient}
                allowedSlippage={allowedSlippage}
                onConfirm={handleSwap}
                swapErrorMessage={swapErrorMessage}
                onDismiss={handleConfirmDismiss}
                tsBg={theme.finnbarModalBg}
                onAddHandle={trade?.outputAmount?.currency === ETHER ? undefined : handleAddToken2MetaMask}
              />
              <AutoColumnCus gap={'md'}>
                <CurrencyInputPanel
                  label={independentField === Field.OUTPUT && !showWrap && trade ? t('fromEstimated') : t('from')}
                  value={formattedAmounts[Field.INPUT]}
                  showMaxButton={!atMaxAmountInput}
                  currency={currencies[Field.INPUT]}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  onCurrencySelect={handleInputSelect}
                  otherCurrency={currencies[Field.OUTPUT]}
                  id="swap-currency-input"
                  bg={"#2D383D"}
                  border={"1px solid rgba(255, 255, 255, 0.2)"}
                />
                <AutoColumn justify="space-between" style={{position:'relative',zIndex:2}}>
                  <AutoRow justify='center' style={{ padding: '0 1rem' }}>
                    <ArrowWrapper onClick={() => {
                          setApprovalSubmitted(false) // reset 2 step UI for approvals
                          onSwitchTokens()
                        }} clickable style={{borderRadius:"50%",border:'2px solid white',padding:5,marginTop:0,marginBottom:0,width:40,height:40,lineHeight:'25px',paddingTop:6, paddingLeft:3,display:'inline-flex'}}>
                      <img alt="switch" src={require('../../assets/svg/switch.svg')} width="30"/>
                    </ArrowWrapper>
                    
                  </AutoRow>
                </AutoColumn>
                <CurrencyInputPanel
                  value={formattedAmounts[Field.OUTPUT]}
                  onUserInput={handleTypeOutput}
                  label={independentField === Field.INPUT && !showWrap && trade ? t('toEstimated') : t('to')}
                  showMaxButton={false}
                  currency={currencies[Field.OUTPUT]}
                  onCurrencySelect={handleOutputSelect}
                  otherCurrency={currencies[Field.INPUT]}
                  id="swap-currency-output-2"
                  bg={"#2D383D"}
                  border={"1px solid rgba(255, 255, 255, 0.2)"}
                />
                {recipient === null && !showWrap && isExpertMode ? (
                      <LinkStyledButton  style={{color:'#C3C5CB'}} id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                        {t('addRecipient')}
                      </LinkStyledButton>
                    ) : null}
                {recipient !== null && !showWrap ? (
                  <>
                    <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                      <ArrowWrapper clickable={false} style={{background:'transparent'}}>
                        <ArrowDown size="16" color="rgb(195, 197, 203)"/>
                      </ArrowWrapper>
                      <LinkStyledButton style={{color:'#C3C5CB',justifyContent:'space-between'}}  id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                        {t('removeRecipent')}
                      </LinkStyledButton>
                    </AutoRow>
                    <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                  </>
                ) : null}

                {showWrap ? null : (
                  <Card padding={'.25rem .75rem 0 .75rem'} borderRadius={'10px'}>
                    <AutoColumn gap="4px">
                      {Boolean(trade) && (
                        <RowBetween align="center">
                          <Text fontWeight={500} fontSize={14} color={"#999999"}>
                            {t('price')}
                          </Text>
                          <TradePrice
                            price={trade?.executionPrice}
                            showInverted={showInverted}
                            setShowInverted={setShowInverted}
                          />
                        </RowBetween>
                      )}
                      {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                        <RowBetween align="center">
                          <ClickableText fontWeight={500} fontSize={14} color={"#999999"} onClick={toggleSettings}>
                            {t('slippageTolerance')}
                          </ClickableText>
                          <ClickableText fontWeight={500} fontSize={14} color={"#999999"} onClick={toggleSettings}>
                            {allowedSlippage / 100}%
                          </ClickableText>
                        </RowBetween>
                      )}
                      {(defaultGasPrice && defaultGasPrice !== INITIAL_GAS_PRICE) ? (
                        <RowBetween align="center">
                          <ClickableText fontWeight={500} fontSize={14} color={"#999999"} onClick={toggleSettings}>
                            Gas Price
                          </ClickableText>
                          <ClickableText fontWeight={500} fontSize={14} color={"#999999"} onClick={toggleSettings}>
                            {defaultGasPrice} Gwei
                          </ClickableText>
                        </RowBetween>
                      ) : null}
                    </AutoColumn>
                  </Card>
                )}
              </AutoColumnCus>
              <BottomGroupingCus showWrap={(!showWrap && Boolean(trade)) || (allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE)}>
                {!account ? (
                  <ButtonLight onClick={toggleWalletModal}><LogIn style={{marginRight:5}} size="18" color="#000000"/> {t('connectWallet')}</ButtonLight>
                ) : showWrap ? (
                  <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                    {wrapInputError ??
                      (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                  </ButtonPrimary>
                ) : noRoute && userHasSpecifiedInputOutput ? (
                  <GreyCard style={{ textAlign: 'center', background: "#4d4d4d" }}>
                    <TYPE.custom2 mb="4px" color="#999999" fontSize="20px">{t('insufficientLiquidity')}</TYPE.custom2>
                  </GreyCard>
                ) : showApproveFlow ? (
                  <RowBetween>
                    <ButtonConfirmed
                      onClick={approveCallback}
                      disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                      width="48%"
                      altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                      confirmed={approval === ApprovalState.APPROVED}
                    >
                      {approval === ApprovalState.PENDING ? (
                        <AutoRow gap="6px" justify="center">
                          {t('approving')} <Loader stroke="white" />
                        </AutoRow>
                      ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                        t('approved')
                      ) : (
                        t('approve') + ' ' + currencies[Field.INPUT]?.symbol
                      )}
                    </ButtonConfirmed>
                    <ButtonError
                      onClick={() => {
                        if (isExpertMode) {
                          handleSwap()
                        } else {
                          setSwapState({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            showConfirm: true,
                            txHash: undefined
                          })
                        }
                      }}
                      width="48%"
                      id="swap-button"
                      disabled={
                        !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                      }
                      error={isValid && priceImpactSeverity > 2}
                    >
                      <Text fontSize={16} fontWeight={500}>
                        {priceImpactSeverity > 3 && !isExpertMode
                          ? t('priceImpactHigh')
                          : t('swap') + `${priceImpactSeverity > 2 ? t('anyway') : ''}`}
                      </Text>
                    </ButtonError>
                  </RowBetween>
                ) : (
                  <ButtonError
                    onClick={() => {
                      if (isExpertMode) {
                        handleSwap()
                      } else {
                        setSwapState({
                          tradeToConfirm: trade,
                          attemptingTxn: false,
                          swapErrorMessage: undefined,
                          showConfirm: true,
                          txHash: undefined
                        })
                      }
                    }}
                    id="swap-button"
                    disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                    error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                  >
                    <Text fontSize={20} fontWeight={500}>
                      {swapInputError
                        ? swapInputError
                        : priceImpactSeverity > 3 && !isExpertMode
                        ? t('priceImpactTooHigh')
                        : t('swap') + `${priceImpactSeverity > 2 ? t('anyway') : ''}`}
                    </Text>
                  </ButtonError>
                )}
                {showApproveFlow && (
                  <Column style={{ marginTop: '1rem' }}>
                    <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
                  </Column>
                )}
                {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                {betterTradeLinkVersion ? (
                  <BetterTradeLink version={betterTradeLinkVersion} />
                ) : toggledVersion !== DEFAULT_VERSION && defaultTrade ? (
                  <DefaultVersionLink />
                ) : null}
              </BottomGroupingCus>
            </Wrapper>
          </AppBodyV2>
          <AdvancedSwapDetailsDropdown trade={trade} />
        </SwapCon>
      </FlexRow>
    </div>
  )
}

const FlexRow = styled.div`
  display: flex;
  flex-direction: ${isMobile ? 'column-reverse' : 'row'};
  width: 100%;
  justify-content: center;
`

// const Bg = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   border-radius: 12px;
//   width: 100%;
//   height: 100%;
//   filter: blur(20px);
//   background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
//   background-position: center top;
//   background-size: cover;
//   background-color: rgba(255, 255, 255, 0.05);
//   background-attachment: fixed;
//   z-index: -1;
// `

const SwapCon = styled.div`
  position: relative;
`

const AutoColumnCus = styled(AutoColumn)`
  grid-row-gap: 30px;
`

const BottomGroupingCus = styled.div<{
  showWrap: boolean
}>`
  margin-top: ${({ showWrap }) => showWrap ? '1rem' : '2rem' };
`