import React, { useCallback, useContext, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled, { ThemeContext } from 'styled-components'

import { JSBI, TokenAmount, ETHER } from '@huckleberry/sdk'
import { RouteComponentProps } from 'react-router-dom'
import CurrencyLogo from '../../components/CurrencyLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earnExcursion/styled'
import { ButtonPrimary, ButtonEmpty } from '../../components/Button'
import StakingModal from '../../components/earnExcursion/StakingModal'
import { useStakingInfo } from '../../state/excursion/hooks'
// import UnstakingModal from '../../components/earnExcursion/UnstakingModal'
import ClaimRewardModal from '../../components/earnExcursion/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import usePrevious from '../../hooks/usePrevious'
import { BIG_INT_ZERO } from '../../constants'
import { commafy3 } from '../../utils/commafy'
import arrowleft from '../../assets/images/arrowleft.png'

import UnstakingTomModal from '../../components/earnExcursion/UnstakingTomModal'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  position: relative;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 10px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: linear-gradient(90deg,rgb(0,169,157) 0%,rgb(40,171,224) 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`

const StyledBottomCard = styled(DataCard)<{ dim: any }>`
  background: #222E33;
  opacity: ${({ dim }) => (dim ? 1 : 1)};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const PoolData = styled(DataCard)`
  background: ${({ theme }) => theme.liquidityCardBg };
  border: 1px solid rgba(255, 255, 255, 0.2);;
  padding: 1rem;
  z-index: 1;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  grid-gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    grid-gap: 12px;
  `};
`

export default function ManageHive({
  match: {
    params: { currencyIdA, pid }
  },
  history
}: RouteComponentProps<{ currencyIdA: string; pid: string }>) {
  const { account, chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // get currencies and pair
  const [currencyA, currencyB] = [useCurrency(currencyIdA), useCurrency(currencyIdA)]
  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

  const stakingInfo = useStakingInfo(tokenA, pid)?.[0]
  const rewardToken = stakingInfo?.tokens[1];
  const rewardCurrency = useCurrency(rewardToken?.address);
  // detect existing unstaked LP position to show add button if none found
  // console.log('!12', stakingInfo?.stakedAmount?.token, account)
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))

  const token = currencyA === ETHER ? tokenB : tokenA
  const WETH = currencyA === ETHER ? tokenA : tokenB
  const backgroundColor = useColor(token)

  // get WETH value of staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  let valueOfTotalStakedAmountInWLSP: TokenAmount | undefined

  if (totalSupplyOfStakingToken && tokenA && stakingInfo && WETH) {

    valueOfTotalStakedAmountInWLSP = new TokenAmount(
      WETH,
      JSBI.multiply(stakingInfo.totalStakedAmount.raw, JSBI.BigInt(1))
    )
  }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <PageWrapper gap="lg" justify="center">
      <GoBack onClick={() => history.push('/excursion')}><img alt="Go Back" src={arrowleft}/></GoBack>
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }} color={theme.liquidityTxt}>
          {currencyA?.symbol} in Excursion
        </TYPE.mediumHeader>
        <CurrencyLogo currency={currencyA ?? undefined} size={'40px'} />
      </RowBetween>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.white style={{ margin: 0 }}>Total deposits</TYPE.white>
            <TYPE.body fontSize={22} fontWeight={500}>
              {`${valueOfTotalStakedAmountInWLSP ? commafy3(valueOfTotalStakedAmountInWLSP?.toSignificant(6, { groupSeparator: ',' })) : '-'} TOM`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.white style={{ margin: 0 }}>Pool Rate</TYPE.white>
            <TYPE.body fontSize={22} fontWeight={500}>
              {stakingInfo?.totalRewardRate ? commafy3(stakingInfo?.totalRewardRate
                ?.multiply((60 * 60 * 24 * 7).toString())
                ?.toFixed(0, { groupSeparator: ',' })) : '-'}
              {` ${rewardToken?.symbol ?? ''} / week`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
      </DataRow>
      {stakingInfo && (
        <>
          <StakingModal
            isOpen={showStakingModal}
            onDismiss={() => setShowStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          {/* <UnstakingModal
            isOpen={showUnstakingModal}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
          /> */}
          <ClaimRewardModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />
          <UnstakingTomModal
            isOpen={showUnstakingModal}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}

      <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
        <BottomSection gap="lg" justify="center">
          <StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showAddLiquidityButton}>
            <CardSection>
              <CardBGImage desaturate />
              <CardNoise />
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white fontWeight={600}>Your TOM deposits</TYPE.white>
                </RowBetween>
                <RowBetween style={{ alignItems: 'center', display:'flex',flexWrap:'wrap' }}>
                  <TYPE.white fontSize={36} fontWeight={600}>
                    {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                  </TYPE.white>
                  <TYPE.white>
                    {currencyA?.symbol}
                  </TYPE.white>
                </RowBetween>
              </AutoColumn>
            </CardSection>
          </StyledDataCard>
          <StyledBottomCard dim={stakingInfo?.stakedAmount?.equalTo(JSBI.BigInt(0))}>
            <CardBGImage desaturate />
            <CardNoise />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <TYPE.white>Your unclaimed{' ' + rewardToken?.symbol}</TYPE.white>
                </div>
                {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                  <ButtonEmpty
                    style={{ color: 'rgb(66, 181, 217)' }}
                    padding="8px"
                    borderRadius="8px"
                    width="fit-content"
                    onClick={() => setShowClaimRewardModal(true)}
                  >
                    Claim
                  </ButtonEmpty>
                )}
              </RowBetween>
              <RowBetween style={{ alignItems: 'center', display:'flex',flexWrap:'wrap' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                <TYPE.black fontSize={20} fontWeight={500} style={{display: 'flex', alignItems: 'center'}}>
                  {/* <span id="animate-zoom" role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span> */}

                  <CurrencyLogo currency={rewardCurrency ?? undefined} size={'26px'} style={{marginRight: '8px'}} />
                  {stakingInfo?.rewardRate
                    ?.multiply((60 * 60 * 24 * 7).toString())
                    ?.toFixed(4, { groupSeparator: ',' }) ?? '-'}
                  {` ${rewardToken?.symbol} / week`}
                </TYPE.black>
              </RowBetween>
            </AutoColumn>
          </StyledBottomCard>
        </BottomSection>
        <TYPE.white style={{ textAlign: 'center' }} fontSize={14} color={theme.liquidityTxt}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          When you withdraw, the contract will automagically claim reward on your behalf!
        </TYPE.white>

        {!showAddLiquidityButton && (
          <DataRow style={{ marginBottom: '1rem',gap:0 }}>
            <ButtonPrimary height="60px" padding="8px" borderRadius="8px" width="240px" margin="6px" onClick={handleDepositClick}>
              {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ? 'Deposit' : 'Deposit TOM Tokens'}
            </ButtonPrimary>

            {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
              <>
                <ButtonPrimary
                  margin="6px"
                  height="60px"
                  padding="8px"
                  borderRadius="8px"
                  width="240px"
                  onClick={() => setShowUnstakingModal(true)}
                >
                  Withdraw
                </ButtonPrimary>
              </>
            )}
          </DataRow>
        )}
      </PositionInfo>
    </PageWrapper>
  )
}

const GoBack = styled.div`
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  top: -35px;
  left: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({theme}) => theme.riverArrowBg};
`
