import React from 'react'
import styled, { css } from 'styled-components'
import { animated, useTransition, useSpring } from 'react-spring'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { isMobile, browserName } from 'react-device-detect'
import '@reach/dialog/styles.css'
import { transparentize } from 'polished'
import { useGesture } from 'react-use-gesture'

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`
const AnimatedDialogOverlay = animated(DialogOverlay)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDialogOverlay = styled(({overflow, ...rest}) => (<AnimatedDialogOverlay {...rest}/>))`
  &[data-reach-dialog-overlay] {
    z-index: ${isMobile ? '99' : '2'};
    background-color: transparent;
    overflow: ${({overflow}) => (overflow ? overflow : 'hidden')} !important;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme }) => theme.modalBg};
    color: #2F9CA3;
  }
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDialogOverlay2Lend = styled(AnimatedDialogOverlay)`
  &[data-reach-dialog-overlay] {
    z-index: ${isMobile ? '99' : '2'};
    overflow: hidden;
    backdrop-filter: blur(40px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2F9CA3;
    
    ${
      isMobile && css`
        padding-left: 20px;
        padding-right: 20px;
      `
    }
  }
`

const AnimatedDialogContent = animated(DialogContent)
// destructure to not pass custom props to Dialog DOM element
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDialogContent = styled(({ bg, minHeight, maxHeight, mobile, isOpen, borderBottomRadius, largest, enlarge, newEnLarge, border, overflow, ...rest }) => (
  <AnimatedDialogContent {...rest} />
)).attrs({
  'aria-label': 'dialog'
})`
  overflow-y: ${({ mobile }) => (mobile ? 'scroll' : 'hidden')};


  &[data-reach-dialog-content] {
    margin: 0 0 2rem 0;
    border: ${({ enlarge, border }) => border ? border : enlarge ? "1px solid #61696C" : "none"};
    background-color: ${({ theme }) => theme.bg1};
    box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.95, theme.shadow1)};
    padding: 0px;
    width: ${({ enlarge, newEnLarge, largest }) => largest ? largest : (enlarge || newEnLarge) ? "620px" : "50vw"};
    overflow-y: ${({ mobile, overflow }) => (mobile ? 'scroll' : overflow ? overflow : 'hidden')} !important;
    overflow-x: hidden$s;

    align-self: ${({ mobile }) => (mobile ? 'flex-end' : 'center')};
    // margin-bottom: ${({ mobile }) => (mobile ? '4.375rem !important' : '')};

    max-width: ${({ enlarge, newEnLarge, largest }) => largest ? largest : enlarge || newEnLarge ? "1000px" : "520px"};
    ${({ maxHeight }) =>
      maxHeight &&
      css`
        max-height: ${maxHeight}vh;
      `}
    ${({ minHeight }) =>
      minHeight &&
      css`
        min-height: ${minHeight}vh;
      `}
    display: flex;
    border-radius:10px;
    border-bottom-left-radius: ${({borderBottomRadius, enlarge, mobile}) => mobile ? '0px' : borderBottomRadius ? enlarge ? "20px" : borderBottomRadius : ''} !important;
    border-bottom-right-radius: ${({borderBottomRadius, enlarge, mobile}) => mobile ? '0px' : borderBottomRadius ? enlarge ? "20px" : borderBottomRadius : ''} !important;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 65vw;
      margin: 0;
    `}
    ${({bg, enlarge}) => 
      bg && 
      css`
        background-color: ${enlarge ? "black" : bg} !important;
      `
    }
    ${({ theme, mobile }) => theme.mediaWidth.upToSmall`
      width:  85vw;
      ${mobile &&
        css`
          width: 100vw;
          border-radius:10px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `}
    `}
    // backdrop-filter: blur(20px);
  }
`
// destructure to not pass custom props to Dialog DOM element
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDialogContent2Lend = styled(({ bg, positionCenter, minHeight, maxHeight, mobile, isOpen, borderBottomRadius, largest, enlarge, newEnLarge, border, ...rest }) => (
  <AnimatedDialogContent {...rest} />
)).attrs({
  'aria-label': 'dialog'
})`
  overflow-y: ${({ mobile }) => (mobile ? 'scroll' : 'hidden')};
  position: relative;

  &[data-reach-dialog-content] {
    margin: 0 0 2rem 0;
    border: ${({ border }) => border ? border : "none"};
    background-color: ${({ bg }) => bg ? bg : 'initial'};
    box-shadow: ${({ theme, bg }) => bg ? '0 4px 8px 0 ' + transparentize(0.95, theme.shadow1) : 'inherit'};
    padding: 0px;
    width: ${({ newEnLarge }) => newEnLarge ? "620px" : "50vw"};
    overflow-y: ${({ mobile }) => (mobile ? 'scroll' : 'hidden')};
    overflow-x: hidden;

    align-self: ${({ mobile, positionCenter }) => (mobile ? positionCenter ? positionCenter : 'flex-end' : 'center')};

    max-width: ${({ newEnLarge }) => newEnLarge ? "1000px" : "520px"};
    ${({ maxHeight }) =>
      maxHeight &&
      css`
        max-height: ${maxHeight}vh;
      `}
    ${({ minHeight }) =>
      minHeight &&
      css`
        min-height: ${minHeight}vh;
      `}
    display: flex;
    border-radius:10px;
    border-bottom-left-radius: ${({borderBottomRadius, enlarge, mobile}) => mobile ? '0px' : borderBottomRadius ? enlarge ? "20px" : borderBottomRadius : ''} !important;
    border-bottom-right-radius: ${({borderBottomRadius, enlarge, mobile}) => mobile ? '0px' : borderBottomRadius ? enlarge ? "20px" : borderBottomRadius : ''} !important;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 65vw;
      margin: 0;
    `}
    ${({ theme, mobile }) => theme.mediaWidth.upToSmall`
      width:  85vw;
      ${mobile &&
        css`
          width: 100vw;
          border-radius:10px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `}
    `}
  }
`

interface ModalProps {
  isOpen: boolean
  onDismiss: () => void
  minHeight?: number | false
  maxHeight?: number
  initialFocusRef?: React.RefObject<any>
  children?: React.ReactNode,
  bg?: string | false,
  borderBottomRadius?: string | false
  enlarge?: boolean
  newEnLarge?: boolean
  border?: string
  largest?: string
  positionCenter?: string
  overflow?: string
}

export default function Modal({
  isOpen,
  onDismiss,
  minHeight = false,
  maxHeight = 90,
  initialFocusRef,
  children,
  bg,
  borderBottomRadius,
  enlarge,
  border,
  newEnLarge,
  largest,
  overflow = 'hidden'
}: ModalProps) {
  const fadeTransition = useTransition(isOpen, null, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  const [{ y }, set] = useSpring(() => ({ y: 0, config: { mass: 1, tension: 210, friction: 20 } }))
  const bind = useGesture({
    onDrag: state => {
      set({
        y: state.down ? state.movement[1] : 0
      })
      if (state.movement[1] > 300 || (state.velocity > 3 && state.direction[1] > 0)) {
        onDismiss()
      }
    }
  })
  return (
    <>
      {fadeTransition.map(
        ({ item, key, props }) =>
          item && (
            <StyledDialogOverlay key={key} overflow={overflow} style={props} onDismiss={onDismiss} initialFocusRef={initialFocusRef}>
              <StyledDialogContent
                {...(isMobile
                  ? {
                      ...bind(),
                      style: { transform: y.interpolate(y => `translateY(${y > 0 ? y : 0}px)`) }
                    }
                  : {})}
                aria-label="dialog content"
                minHeight={minHeight}
                maxHeight={maxHeight}
                mobile={isMobile}
                bg={bg}
                enlarge={enlarge}
                newEnLarge={newEnLarge}
                largest={largest}
                border={border}
                borderBottomRadius={borderBottomRadius}
                overflow={overflow}
              >
                {/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
                {!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
                {children}
              </StyledDialogContent>
            </StyledDialogOverlay>
          )
      )}
    </>
  )
}
export function ModalLend({
  isOpen,
  onDismiss,
  minHeight = false,
  maxHeight = 90,
  initialFocusRef,
  children,
  bg,
  borderBottomRadius,
  enlarge,
  border,
  newEnLarge,
  largest,
  positionCenter
}: ModalProps) {
  const fadeTransition = useTransition(isOpen, null, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  const [{ y }, set] = useSpring(() => ({ y: 0, config: { mass: 1, tension: 210, friction: 20 } }))
  const bind = useGesture({
    onDrag: state => {
      set({
        y: state.down ? state.movement[1] : 0
      })
      if (state.movement[1] > 300 || (state.velocity > 3 && state.direction[1] > 0)) {
        onDismiss()
      }
    }
  })
  return (
    <>
      {fadeTransition.map(
        ({ item, key, props }) =>
          item && (
            <StyledDialogOverlay2Lend key={key} style={props} onDismiss={onDismiss} initialFocusRef={initialFocusRef}>
              {
                browserName === 'Firefox' && <Bg />
              }
              <StyledDialogContent2Lend
                {...(isMobile
                  ? {
                      ...bind(),
                      style: { transform: y.interpolate(y => `translateY(${y > 0 ? y : 0}px)`) }
                    }
                  : {})}
                aria-label="dialog content"
                minHeight={minHeight}
                maxHeight={maxHeight}
                mobile={isMobile}
                bg={bg}
                positionCenter={positionCenter}
                enlarge={enlarge}
                newEnLarge={newEnLarge}
                largest={largest}
                border={border}
                borderBottomRadius={borderBottomRadius}
              >
                {/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
                {!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
                {children}
              </StyledDialogContent2Lend>
            </StyledDialogOverlay2Lend>
          )
      )}
    </>
  )
}
