import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount } from '@huckleberry/sdk'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FINN, TOM } from '../../constants'
import { useBlockNumber } from '../application/hooks'

import { useActiveWeb3React } from '../../hooks'
import { EXCURSION_REWARD_TOKENS, EXCURSION_V1_LENGTH, FINNBAR_ADDRESS } from '../../constants/abis/bridge'
import { useSingleCallResult, useSingleContractMultipleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'
import { useExcursionContract, useExcursionContractV2 } from '../../hooks/useContract'

export const STAKING_GENESIS = 1606976660

export const REWARDS_DURATION_DAYS = 365*2


export interface StakingInfo {
  pid: number
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // when the period ends
  periodStart: Date | undefined
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount
}

export function usePoolInfo() {
  const hiveContract = useExcursionContract()
  const poolLength = useSingleCallResult(hiveContract, 'poolLength').result?.toString()
  return useSingleContractMultipleData(
    hiveContract,
    'poolInfo',
    poolLength ? new Array(Number(poolLength)).fill(poolLength).map((_v, _i) => [_i]) : []
  )
}

export function usePoolInfoV2() {
  const hiveContract = useExcursionContractV2()
  const poolLength = useSingleCallResult(hiveContract, 'poolLength').result?.toString()
  return useSingleContractMultipleData(
    hiveContract,
    'poolInfo',
    poolLength ? new Array(Number(poolLength)).fill(poolLength).map((_v, _i) => [_i]) : []
  )
}

export function useAllStakingRewardsInfo() {
  const { chainId } = useActiveWeb3React()
  const poolInfo = usePoolInfo()
  // const poolInfoV2 = usePoolInfoV2()
  // const lpTokenAddr = useMemo(() => poolInfo?.map(_v => _v.result?.lpToken).concat(poolInfoV2?.map(_v => _v.result?.lpToken)), [poolInfo, poolInfoV2])
  // const rewardTokenAddr = useMemo(() => poolInfo?.map(_v => _v.result?.rewardToken).concat(poolInfoV2?.map(_v => _v.result?.rewardToken)), [poolInfo, poolInfoV2])
  const lpTokenAddr = useMemo(() => poolInfo?.map(_v => _v.result?.lpToken), [poolInfo])
  const rewardTokenAddr = useMemo(() => poolInfo?.map(_v => _v.result?.rewardToken), [poolInfo])

  return useMemo(() => {
    const info: {
      [chainId in ChainId]?: {
        tokens: [Token, Token]
        stakingRewardAddress: string
      }[]
    } = {
      [ChainId.MOON_MAINNET]: [],
      [ChainId.MOON_TESTNET]: [],
      [ChainId.CLOVER_PARACHAIN]: []
    }
    lpTokenAddr.forEach((_v, _i) => {
      if (!_v || !chainId) return
      const ret = new Token(chainId, FINNBAR_ADDRESS[chainId], 18, 'TOM', 'TOM');
      const symbolAndDecimals = EXCURSION_REWARD_TOKENS.get(rewardTokenAddr[_i].toLowerCase());
      const symbol = symbolAndDecimals ? symbolAndDecimals[0] : '--';
      const decimals = symbolAndDecimals ? Number(symbolAndDecimals[1]) : 18;
      let ret1 = new Token(chainId, rewardTokenAddr[_i], decimals, symbol, symbol);

      if (ret) {
        info[chainId]?.push({
          tokens: [ret, ret1],
          stakingRewardAddress: lpTokenAddr[_i]
        })
      }
    })
    return info
  }, [chainId, lpTokenAddr, rewardTokenAddr])
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(token?: Token | null, pid?: string | number | null | undefined): StakingInfo[] {
  const currentBlockNumber = useBlockNumber()
  const poolInfoV1 = usePoolInfo()
  const poolInfoV2 = usePoolInfoV2()
  const poolInfo = poolInfoV1.concat(poolInfoV2)

  const { chainId, account } = useActiveWeb3React()
  const bridgeMinerContract = useExcursionContract()
  const bridgeMinerContractV2 = useExcursionContractV2()
  const allStakingRewards = useAllStakingRewardsInfo()
  const info = useMemo(() => {
    return chainId
      ? allStakingRewards[chainId]?.filter(stakingRewardInfo =>
        token === undefined
            ? true
            : token === null
            ? false
            : token.symbol === stakingRewardInfo.tokens[0].symbol
        ) ?? []
      : []
  }, [allStakingRewards, chainId, token])

  const uni = chainId ? TOM[chainId] : undefined
  const lpTokenAddr = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const userInfoParams = useMemo(() => {
    if (account && chainId) {
      return lpTokenAddr.map((_v, i) => {
        const pid = i < EXCURSION_V1_LENGTH[chainId] ? i : (i - EXCURSION_V1_LENGTH[chainId])

        return [pid.toString(), account ?? undefined]
      })
    } else {
      return []
    }
  }, [account, lpTokenAddr, chainId])
  // get all the info from the staking rewards contracts

  const earnedAmountsV1 = useSingleContractMultipleData(bridgeMinerContract, 'pendingReward', chainId ? userInfoParams.slice(0, EXCURSION_V1_LENGTH[chainId]) : [])
  const earnedAmountsV2 = useSingleContractMultipleData(bridgeMinerContractV2, 'pendingReward', chainId ? userInfoParams.slice(EXCURSION_V1_LENGTH[chainId]) : [])

  const earnedAmounts = earnedAmountsV1.concat(earnedAmountsV2)
  return useMemo(() => {
    if (!chainId || !uni) return []
    const getHypotheticalRewardRate = (
      stakedAmount: TokenAmount,
      totalStakedAmount: TokenAmount,
      totalRewardRate: TokenAmount
    ): TokenAmount => {
      return new TokenAmount(
        uni,
        JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
          ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
          : JSBI.BigInt(0)
      )
    }

    return lpTokenAddr.reduce<StakingInfo[]>((memo, rewardsAddress, i) => {
      let index = 0;
      if (!pid) {
        index = i;
      } else {
        index = Number(pid)
      }
      const rewardRates = poolInfo[index];
      const startTime = poolInfo[index] ? poolInfo[index].result?.bonusStartTimestamp : 0;
      const endTime = poolInfo[index] ? poolInfo[index].result?.bonusEndTimestamp : 0;
      const totalSupplies = poolInfo[index] ? poolInfo[index].result?.currentSupply : 0;

      const rewardTokenAddr = poolInfo[index] ? poolInfo[index].result?.rewardToken : '';
      const rewardTokenInfo = EXCURSION_REWARD_TOKENS.get(rewardTokenAddr.toLowerCase());
      const rewardToken = new Token(chainId, rewardTokenAddr, rewardTokenInfo ? Number(rewardTokenInfo[1]) : 18);

      let totalRewardRate = new TokenAmount(rewardToken, rewardRates.result?.rewardPerSecond)
      if (currentBlockNumber && (Date.now()/1000 > endTime)) {
        totalRewardRate = new TokenAmount(rewardToken, '0')
      }
      // first value of earned amount is user staked amount
      let stakedAmount = new TokenAmount(uni, JSBI.BigInt(earnedAmounts[index]?.result?.[0] ?? 0));
      let totalStakedAmount = new TokenAmount(uni, totalSupplies);
      let rewardRate = new TokenAmount(rewardToken, totalStakedAmount.greaterThan('0') ? totalRewardRate.multiply(stakedAmount).divide(totalStakedAmount).multiply((10** rewardToken.decimals).toString()).toFixed(0) : '0');
      // these two are dependent on account
      memo.push({
        pid: index,
        stakingRewardAddress: rewardsAddress,
        tokens: info[index].tokens,
        periodFinish: endTime ? new Date(endTime* 1000) : undefined,
        periodStart: startTime ? new Date(startTime* 1000) : undefined,
        earnedAmount: new TokenAmount(rewardToken, JSBI.BigInt(earnedAmounts[index]?.result?.[1] ?? 0)),
        rewardRate,
        totalRewardRate,
        stakedAmount,
        totalStakedAmount,
        getHypotheticalRewardRate
      })
      
      return memo
    }, [])
  }, [
    chainId,
    uni,
    lpTokenAddr,
    earnedAmounts,
    info,
    poolInfo,
    currentBlockNumber,
    pid
  ])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? FINN[chainId] : undefined
  const stakingInfos = useStakingInfo()

  return useMemo(() => {
    if (!uni) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(uni, '0')
      ) ?? new TokenAmount(uni, '0')
    )
  }, [stakingInfos, uni])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const { t } = useTranslation()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = t('connectWallet')
  }
  if (!parsedAmount) {
    error = error ?? t('enterAnAmount')
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = t('connectWallet')
  }
  if (!parsedAmount) {
    error = error ?? t('enterAnAmount')
  }

  return {
    parsedAmount,
    error
  }
}
