import { Interface } from '@ethersproject/abi'
import { ChainId } from '@huckleberry/sdk'
import BRIDGE_MINER_ABI from './bridge-miner.json'
import BRIDGE_TOKEN_ABI from './bridge-token.json'
import ERC20_ABI from './erc20.json'
import HIVE_ABI from './excursion.json'
import ACTIVITY_HIVE_ABI from './activity.json'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import FINNBAR_ABI from './finnBar.json'
import BUYBACK_ABI from './buyback.abi.json'

const WANV2_PAIR_INTERFACE = new Interface(IUniswapV2PairABI)
const BRIDGE_MINER_INTERFACE = new Interface(BRIDGE_MINER_ABI)
const ERC20_INTERFACE = new Interface(ERC20_ABI)
const WANV2BRIDGE_TOKEN_INTERFACE = new Interface(BRIDGE_TOKEN_ABI)
const HIVE_INTERFACE = new Interface(HIVE_ABI);
const ACTIVITY_HIVE_INTERFACE = new Interface(ACTIVITY_HIVE_ABI);

// Farming addr
const BRIDGE_MINER_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xDd4b9b3Ce03faAbA4a3839c8B5023b7792be6e2C',
  [ChainId.MOON_MAINNET]: '0x1f4b7660b6AdC3943b5038e3426B33c1c0e343E6',
  [ChainId.MOON_TESTNET]: '0xd45F54838dbfCe0F976bD430bE5f8678479E1510',
  [ChainId.CLOVER_PARACHAIN]: '0x67dc6E39A46EfCBd9ec7bb8d490EFF6DF9c9B047',
}

// FINN addr
const BRIDGE_TOKEN_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x8B9F9f4aA70B1B0d586BE8aDFb19c1Ac38e05E9a',
  [ChainId.MOON_MAINNET]: '0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  [ChainId.MOON_TESTNET]: '0x31b1644F8379A22d25f845A67F1aB346e76001aa',
  [ChainId.CLOVER_PARACHAIN]: '0xbea4928632e482a0a1241b38f596a311ad7b98b1',
}

// Excursion addr
const EXCURSION_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xa66535F73cA247e1b2E2b97149ca381BE18253A1',
  [ChainId.MOON_MAINNET]: '0xa66535F73cA247e1b2E2b97149ca381BE18253A1',
  [ChainId.MOON_TESTNET]: '0x86B2Dfa5168830823cd864C114df68f6C54e4Abf',
  [ChainId.CLOVER_PARACHAIN]: '0xddaB2443e18B171077648254991f543413d35275',
}

const ACTIVITY_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xF5dF0562B1bc9cBfaB338e2d156D18c88d3dCfdf',
  [ChainId.MOON_MAINNET]: '0xF5dF0562B1bc9cBfaB338e2d156D18c88d3dCfdf',
  [ChainId.MOON_TESTNET]: '0xa7daa95E7E8701513094bbc173fb38d3BAa9ECd7',
  [ChainId.CLOVER_PARACHAIN]: '0xa7daa95E7E8701513094bbc173fb38d3BAa9ECd7',
}

const EXCURSION_V1_LENGTH: { [chainId in ChainId]: number } = {
  [ChainId.MAINNET]: 8,
  [ChainId.MOON_MAINNET]: 8,
  [ChainId.MOON_TESTNET]: 2,
  [ChainId.CLOVER_PARACHAIN]: 100,
}

// Lend addr
const COMPTROLLER_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xcffef313b69d83cb9ba35d9c0f882b027b846ddc',
  [ChainId.MOON_MAINNET]: '0xcffef313b69d83cb9ba35d9c0f882b027b846ddc',
  [ChainId.MOON_TESTNET]: '0x6652efa0a15939e8013522ac24dc3e6cb1a1f3e1',
  [ChainId.CLOVER_PARACHAIN]: '0x6652efa0a15939e8013522ac24dc3e6cb1a1f3e1',
}

// Excursion addr
const EXCURSION_ADDRESS_V2: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x4407c6cC7075A771519B8d004adCdC786432Eb12',
  [ChainId.MOON_MAINNET]: '0x4407c6cC7075A771519B8d004adCdC786432Eb12',
  [ChainId.MOON_TESTNET]: '0x521694a43938e20B39136BC4Cb96b3e8be1e218A',
  [ChainId.CLOVER_PARACHAIN]: '0xddaB2443e18B171077648254991f543413d35275',
}

// Excursion AutoTom addr
const EXCURSION_ADDRESS_V3: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xd90a92BD23b30dC3A69403E00651ff6D08c00Fe7',
  [ChainId.MOON_MAINNET]: '0xd90a92BD23b30dC3A69403E00651ff6D08c00Fe7',
  [ChainId.MOON_TESTNET]: '0xcc3ba625f7dc7549ff0aebf966299a9be8554dc0',
  [ChainId.CLOVER_PARACHAIN]: '0x18D1A3f485D5De49654051090deae9AA550764d1',
}

const AIRDROP_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xc95a4bc5C14CC6a23AF46BA50D3785d5fd55446d',
  [ChainId.MOON_MAINNET]: '0xc95a4bc5C14CC6a23AF46BA50D3785d5fd55446d',
  [ChainId.MOON_TESTNET]: '0x4a3d88AB253a51fFe99fEe5a9BEb60bE0Ea68886',
  [ChainId.CLOVER_PARACHAIN]: '0x4a3d88AB253a51fFe99fEe5a9BEb60bE0Ea68886',
}

// TOM ADDR
const FINNBAR_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x37619cC85325aFea778830e184CB60a3ABc9210B',
  [ChainId.MOON_MAINNET]: '0x37619cC85325aFea778830e184CB60a3ABc9210B',
  [ChainId.MOON_TESTNET]: '0x4bF9D67a254bD7C8E4a164e8363FFEDeC4128444',
  [ChainId.CLOVER_PARACHAIN]: '0x03bFdda91e624A2A0238b728635A2F592A3072D7',
}

// BUYBACK_ADDRESS SC ADDR
const BUYBACK_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xA472a6e8823f68f372c765bE57c1d08905BD9Ab1',
  [ChainId.MOON_MAINNET]: '0xA472a6e8823f68f372c765bE57c1d08905BD9Ab1',
  [ChainId.MOON_TESTNET]: '0x42588054a46a074dAf2ADeEc97Cd419749A9e263',
  [ChainId.CLOVER_PARACHAIN]: '0x42588054a46a074dAf2ADeEc97Cd419749A9e263',
}

const EXCURSION_REWARD_TOKENS = new Map<string, Array<string>>([
  ['0x41616349cf1d2f8ccc7a575dfd3bc8a29d21955b', ['TEST', '18']],
  ['0x52f04c806eb82930f40d410259b7af8e18d3bdc9', ['KAFE', '18']],
  ['0xc2392dd3e3fed2c8ed9f7f0bdf6026fcd1348453', ['BEANS', '18']],
  ['0x5d4360f1be94bd6f182f09cfe5ef9832e65eb1ac', ['RivrDoge', '18']],
  ['0xc2b0435276139731d82ae2fa8928c9b9de0761c1', ['RKITTY', '18']],
  ['0x3d593056f3e34db7a4720d7f171447c489cfa195', ['RIVRSHIB', '9']],
  ['0x3516a7588c2e6ffa66c9507ef51853eb85d76e5b', ['1SWAP', '18']],
  ['0x779a405319424e9cf13280624bc89541f27aa56a', ['GOLD', '18']],
  ['0x41562ae242d194247389152acaa7a9397136b09f', ['WAN.m', '18']],
  ['0xdc151bc48a5f77288cde9ddbff2e32e6bcf4791f', ['H2O', '18']],
  ['0xfa44e0bf61c46221bffaa60f59e82e2319491929', ['movrGAS', '18']],
  ['0xffef2639b2ee39f9c284d0107e567dd2f7b20613', ['WASP.m', '18']],
  ['0xe1b9b34b03ec34b0802398b7669de6d0d43c9871', ['wSWORD', '18']],
  ['0xbecc61601c59d5afffe750d201ec98cdc70db796', ['OSHARE', '18']],
  ['0x6d6ad95425fcf315c39fa6f3226471d4f16f27b3', ['CLV', '18']],
  ['0x1bbc16260d5d052f1493b8f2aeee7888fed1e9ab', ['USDC', '6']],
  ['0xd557cc89ca98db85ef5ad09afba7c22583fe727e', ['USDT', '6']],
])

const ACTIVITY_STAKE_TOKENS = new Map<string, Array<string>>([
  ['0x0000000000000000000000000000000000000000', ['CLV', '18']],
])

export {
  BRIDGE_MINER_ABI,
  BRIDGE_MINER_ADDRESS,
  BRIDGE_TOKEN_ABI,
  BRIDGE_TOKEN_ADDRESS,
  HIVE_ABI,
  ACTIVITY_HIVE_ABI,
  EXCURSION_ADDRESS,
  ACTIVITY_ADDRESS,
  HIVE_INTERFACE,
  ACTIVITY_HIVE_INTERFACE,
  ERC20_INTERFACE,
  WANV2_PAIR_INTERFACE,
  BRIDGE_MINER_INTERFACE,
  WANV2BRIDGE_TOKEN_INTERFACE,
  AIRDROP_ADDRESS,
  FINNBAR_ADDRESS,
  FINNBAR_ABI,
  EXCURSION_REWARD_TOKENS,
  ACTIVITY_STAKE_TOKENS,
  BUYBACK_ADDRESS,
  BUYBACK_ABI,
  EXCURSION_ADDRESS_V2,
  EXCURSION_ADDRESS_V3,
  EXCURSION_V1_LENGTH,
  COMPTROLLER_ADDRESS
}
