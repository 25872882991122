import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Popover, { PopoverProps } from '../Popover'

const TooltipContainer = styled.div<{
  color?: string
}>`
  width: 228px;
  padding: 0.6rem 1rem;
  line-height: 150%;
  font-weight: 400;
  color: ${props => props.color ? props.color : "#1d1433"}
`

const TooltipContainerCus = styled.div`
  width: fit-content;
  padding: 0.6rem 1rem;
  line-height: 150%;
  font-weight: bold;
  color: #42B5D9;
  font-size: 14px;
`

interface TooltipProps extends Omit<PopoverProps, 'content'> {
  text: string,
  color?: string
}

export default function Tooltip({ text, color, ...rest }: TooltipProps) {
  return <Popover content={<TooltipContainer color={color}>{text}</TooltipContainer>} {...rest} />
}

function TooltipCus({ text, ...rest }: TooltipProps) {
  return <Popover content={<TooltipContainerCus>{text}</TooltipContainerCus>} {...rest} />
}

export function MouseoverTooltip({ children, ...rest }: Omit<TooltipProps, 'show'>) {
  const [show, setShow] = useState(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return (
    <Tooltip {...rest} show={show}>
      <div onMouseEnter={open} onMouseLeave={close}>
        {children}
      </div>
    </Tooltip>
  )
}

export function MouseoverTooltipCus({ children, ...rest }: Omit<TooltipProps, 'show'>) {
  const [show, setShow] = useState(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return (
    <TooltipCus {...rest} show={show}>
      <div onMouseEnter={open} onMouseLeave={close}>
        {children}
      </div>
    </TooltipCus>
  )
}

