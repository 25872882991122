import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled, { ThemeContext, css } from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { AppDispatch } from '../../state'
import { clearAllTransactions } from '../../state/transactions/actions'
import { shortenAddress } from '../../utils'
import { AutoRow } from '../Row'
import Copy from './Copy'
import Transaction from './Transaction'

import { SUPPORTED_WALLETS } from '../../constants'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { getEtherscanLink } from '../../utils'
import { injected } from '../../connectors'
import Identicon from '../Identicon'
import { ButtonSecondary } from '../Button'
import { ExternalLink as LinkIcon, Repeat, Trash2 } from 'react-feather'
import { ExternalLink, LinkStyledButton, TYPE } from '../../theme'
import { useTranslation } from 'react-i18next'
import Bg from '../../components/Faucet/Bg'
import { isMobile, browserName } from 'react-device-detect'

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const UpperSection = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.modalContentBg };
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  backdrop-filter: blur(30px);

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  background: ${({theme}) => theme.modalWalletCardBg};
  border-radius: 1rem;
  border: 1px solid ${({theme}) => theme.modalWalletCardBorder};
  padding: 0.625rem 1.875rem;

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
`

const AccountSection = styled.div`
  // background-color: ${({ theme }) => theme.bg1};
  padding: 0rem 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0rem 1rem 1.5rem 1rem;`};
`

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  max-width: 80%;
  min-width: 80%;
  padding: 1.5rem;
  margin: 0 auto;
  flex-grow: 1;
  overflow: auto;
  position: relative;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: ${({theme}) => theme.modalWalletDropBg};
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top: none;
  back

  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
  }

  ${
    isMobile && css`
      max-width: 100%;
      width: 100%;
      background: #F2F2F2;
    `
  }
`

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
  font-size: 0.825rem;
  color: #6C7284 !important;
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
  color: ${({ theme }) => theme.modalClose};
`

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
  display: flex;
  align-items: center;
`

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};

`

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  color: #42B5D9;
  border-color: #42B5D9 !important;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const LinkBtn = styled(LinkStyledButton)`
  text-decoration: none !important;
  border: 1px solid #fff;
  padding: 5px 0.8rem;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: #fff;

  ${
    isMobile && css`
      color: #42B5D9;
      border-color: #42B5D9;
    `
  }
`

const AccountControlCon = styled.div`
  ${
    isMobile && css`
      display: flex;
      flex-wrap: wrap;
    `
  }
`

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction loadSize={'17px'} stroke={isMobile ? '#ddd' : '#fff'} key={i} hash={hash} style={{background: isMobile ? '#fff' : 'rgba(0,0,0,0.06)', borderRadius: '16px', padding: '4px 20px', marginBottom: isMobile ? '10px' : ''}} />
      })}
    </TransactionListWrapper>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  ENSName?: string
  openOptions: () => void
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions
}: AccountDetailsProps) {
  const { chainId, account, connector } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()

  function formatConnectorName() {
    const { ethereum, clover } = window
    const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    const isMathWallet = !!(ethereum && ethereum.isMathWallet)
    const isClvWallet = !!(clover && clover.isClover)
    let name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        k =>
          SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK') || isMathWallet === (k === 'MATHWALLET'))
      )
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    let imgName = SUPPORTED_WALLETS[isMathWallet ? 'MATHWALLET' : isClvWallet ? 'CLV_CONNECT' : isMetaMask ? 'METAMASK' : 'WALLET_CONNECT']?.iconName;
    if (name === 'MetaMask' && isMathWallet) {
      name = 'MathWallet';
    }

    return <WalletName>
      <img src={require('../../assets/images/' + imgName)} style={{width: '2.75rem', marginRight: '0.5rem'}} alt="" />
      Connected with {name}
    </WalletName>
  }

  function getStatusIcon() {
    if (connector === injected) {
      return (
        <IconWrapper size={44}>
          <Identicon width={'2.75rem'} height={'2.75rem'} borderRadius={'50%'} iconSize={44} />
        </IconWrapper>
      )
    }
    return null
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow style={{fontWeight: 'bold', color: theme.modalBotomTxt}}>{t('account')}</HeaderRow>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              <AccountGroupingRow>
                {formatConnectorName()}
                <div>
                  {connector !== injected && (
                    <WalletAction
                      style={{ fontSize: '.825rem', fontWeight: 400, marginRight: '8px' }}
                      onClick={() => {
                        ;(connector as any).close()
                      }}
                    >
                      {t('disconnect')}
                    </WalletAction>
                  )}
                  <WalletAction
                    style={{ fontSize: '.825rem', fontWeight: 400, padding: '4px 0.5rem', borderRadius: '100px', textDecoration: 'none' }}
                    onClick={() => {
                      openOptions()
                    }}
                  >
                    <Repeat size={10} style={{marginRight: '0.4rem'}} />
                    {t('change')}
                  </WalletAction>
                </div>
              </AccountGroupingRow>
              <AccountGroupingRow id="web3-account-identifier-row" style={{flexDirection: 'column'}}>
                <AccountControl style={{paddingBottom: '1.25rem', borderBottom: '1px solid rgba(0, 0, 0, 0.04)', marginBottom: '1.25rem'}}>
                  {ENSName ? (
                    <>
                      <div>
                        {getStatusIcon()}
                        <p> {ENSName}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        {getStatusIcon()}
                        <p> {account && shortenAddress(account)}</p>
                      </div>
                    </>
                  )}
                </AccountControl>
                <div style={{width: '100%'}}>
                  {ENSName ? (
                    <>
                      <AccountControl>
                        <AccountControlCon>
                          {account && (
                            <Copy toCopy={account.toLowerCase()}>
                              <span style={{ marginLeft: '4px' }}>{t('copyAddress')}</span>
                            </Copy>
                          )}
                          {chainId && account && (
                            <AddressLink
                              
                              hasENS={!!ENSName}
                              isENS={true}
                              href={chainId && getEtherscanLink(chainId, ENSName, 'address')}
                            >
                              <LinkIcon size={16} />
                              <span style={{ marginLeft: '4px' }}>{t('viewOn')}</span>
                            </AddressLink>
                          )}
                        </AccountControlCon>
                      </AccountControl>
                    </>
                  ) : (
                    <>
                      <AccountControl>
                        <div>
                          {account && (
                            <Copy toCopy={account.toLowerCase()}>
                              <span style={{ marginLeft: '4px' }}>{t('copyAddress')}</span>
                            </Copy>
                          )}
                          {chainId && account && (
                            <AddressLink
                              hasENS={!!ENSName}
                              isENS={false}
                              href={getEtherscanLink(chainId, account, 'address')}
                            >
                              <LinkIcon size={16} />
                              <span style={{ marginLeft: '4px' }}>{t('viewOn')}</span>
                            </AddressLink>
                          )}
                        </div>
                      </AccountControl>
                    </>
                  )}
                </div>
              </AccountGroupingRow>
            </InfoCard>
          </YourAccount>
        </AccountSection>
      </UpperSection>
      {!!pendingTransactions.length || !!confirmedTransactions.length ? (
        <LowerSection>
          { browserName === 'Firefox' && <Bg /> }
          <AutoRow mb={'1rem'} style={{ justifyContent: 'space-between' }}>
            <TYPE.white color={isMobile ? '#000' : ''}>{t('recentTransactions')}</TYPE.white>
            <LinkBtn onClick={clearAllTransactionsCallback}>
              <Trash2 size={16} style={{marginRight: '6px'}} />
              {t('clearAll')}
            </LinkBtn>
          </AutoRow>
          {renderTransactions(pendingTransactions)}
          {renderTransactions(confirmedTransactions)}
        </LowerSection>
      ) : (
        <LowerSection>
          <TYPE.main color={theme.text3} fontSize={16} textAlign={'center'}>{t('yourTransactions')}</TYPE.main>
        </LowerSection>
      )}
    </>
  )
}
