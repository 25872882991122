import { ChainId, Currency, currencyEquals, JSBI, Price, Token, WETH } from '@huckleberry/sdk'
import { useMemo } from 'react'
import { USDT1, FINN, USDC2 } from '../constants'
import { PairState, usePairs } from '../data/Reserves'
import { useActiveWeb3React } from '../hooks'
import { wrappedCurrency } from './wrappedCurrency'

/**
 * Returns the price in USD of the input currency
 * @param currency currency to compute the USD price of
 */
export default function useUSDCPrice(currency?: Currency): Price | undefined {
  const { chainId } = useActiveWeb3React()
  const wrapped = wrappedCurrency(currency, chainId)
  const USD = useMemo(() => (chainId === ChainId.CLOVER_PARACHAIN ? USDT1 : USDT1), [chainId])
  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [
        chainId && wrapped && currencyEquals(WETH[chainId], wrapped) ? undefined : currency,
        chainId ? WETH[chainId] : undefined
      ],
      // [wrapped?.equals(USD) ? undefined : wrapped, !(chainId === ChainId.MAINNET) ? USD : undefined],
      // [chainId ? WETH[chainId] : undefined, !(chainId === ChainId.MAINNET) ? USD : undefined],
      [wrapped?.equals(USD) ? undefined : wrapped, USD],
      [chainId ? WETH[chainId] : undefined, undefined],
      [currency, chainId ? FINN[chainId]: undefined],
      [chainId ? WETH[chainId] : undefined, chainId ? FINN[chainId]: undefined],
    ],
    [USD, chainId, currency, wrapped]
  )

  const [[ethPairState, ethPair], [usdcPairState, usdcPair], [usdcEthPairState, usdcEthPair], [finnPairState, finnPair], [finnEthPairState, finnEthPair]] = usePairs(tokenPairs)

  return useMemo(() => {
    if (!currency || !wrapped || !chainId) {
      return undefined
    }
    // handle weth/eth
    if (wrapped.equals(WETH[chainId])) {
      if (usdcPair) {
        const price = usdcPair.priceOf(WETH[chainId])
        return new Price(currency, USD, price.denominator, price.numerator)
      } else {
        return undefined
      }
    }


    // handle usdc
    if (wrapped.equals(USD)) {
      return new Price(USD, USD, '1', '1')
    }

    if (wrapped.equals(USDC2)) {
      return new Price(currency, USD, '1', '1')
    }

    const ethPairETHAmount = ethPair?.reserveOf(WETH[chainId])
    const ethPairTokenAmount = ethPair?.reserveOf(currency as Token)
    const ethPairETHUSDCValue: JSBI =
      ethPairETHAmount && usdcEthPair ? usdcEthPair.priceOf(WETH[chainId]).quote(ethPairETHAmount).raw : JSBI.BigInt(0)

    // all other tokens
    // first try the usdc pair
    // console.debug('usdcPairState', usdcPairState);


    if (finnPairState === PairState.EXISTS && finnPair &&
      finnEthPairState === PairState.EXISTS && finnEthPair &&
      usdcEthPairState === PairState.EXISTS && usdcEthPair) {
      // console.log('!useUSDCPrice', currency?.symbol, 5, finnPair.reserveOf(currency as Token).toExact(), ethPairETHUSDCValue.toString(), ethPair?.reserveOf(WETH[chainId]).toExact());

      if (Number(finnPair.reserveOf(currency as Token).toExact()) > Number(ethPairTokenAmount ? ethPairTokenAmount?.toExact() : 0) && usdcEthPair.reserveOf(USD).greaterThan('0') && finnEthPair.reserveOf(WETH[chainId]).greaterThan('0')) {
        // console.log('!useUSDCPrice', currency?.symbol, 6);
        
        const ethUsdcPrice = usdcEthPair.priceOf(USD)
        const currencyFinnPrice = finnPair.priceOf(FINN[chainId])
        const finnEthPrice = finnEthPair.priceOf(WETH[chainId])

        const usdcPrice = ethUsdcPrice.multiply(finnEthPrice).multiply(currencyFinnPrice).invert()
        return new Price(currency, USD, usdcPrice.denominator, usdcPrice.numerator)
      }
    }

    if (usdcPairState === PairState.EXISTS && usdcPair 
      && 
      usdcPair.reserveOf(USD).greaterThan(
       ethPairETHUSDCValue
       )
     ) {
      const price = usdcPair.priceOf(wrapped)
      // console.log('!useUSDCPrice', currency?.symbol, 7);

      return new Price(currency, USD, price.denominator, price.numerator)
    }

    if (ethPairState === PairState.EXISTS && ethPair && usdcEthPairState === PairState.EXISTS && usdcEthPair) {
      // console.log('!useUSDCPrice', currency?.symbol, 8);
      if (usdcEthPair.reserveOf(USD).greaterThan('0') && ethPair.reserveOf(WETH[chainId]).greaterThan('0')) {
        // console.log('!useUSDCPrice', currency?.symbol, 9);
        const ethUsdcPrice = usdcEthPair.priceOf(USD)
        const currencyEthPrice = ethPair.priceOf(WETH[chainId])
        const usdcPrice = ethUsdcPrice.multiply(currencyEthPrice).invert()
        return new Price(currency, USD, usdcPrice.denominator, usdcPrice.numerator)
      }
    }
    return undefined
  }, [chainId, currency, ethPair, ethPairState, usdcEthPair, usdcEthPairState, usdcPair, usdcPairState, wrapped, USD, finnPairState, finnPair, finnEthPairState, finnEthPair])
}
