import { JSBI, Pair, Percent } from '@huckleberry/sdk'
import { darken } from 'polished'
import React, { useContext, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { useTotalSupply } from '../../data/TotalSupply'

import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { 
  // ExternalLink, 
  TYPE } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonPrimary, 
  // ButtonSecondary, 
  ButtonEmpty } from '../Button'
import { transparentize } from 'polished'
import { CardNoise } from '../earn/styled'

import { useColor } from '../../hooks/useColor'

import Card, { GreyCard, LightCard } from '../Card'
import { AutoColumn } from '../Column'
// import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`
const FixedHeightRowMobile = styled(RowBetween)`
  ${({theme}) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
  `}
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
const StyledPositionCard = styled(LightCard)<{ bgColor: any }>`
  border: none;
  background: ${({ theme, bgColor }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${transparentize(0.8, bgColor)} 0%, ${theme.bg3} 100%) `};
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.liquidityCardBg };
  border: 1px solid ${({ theme }) => theme.liquidityBorder };
`

interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  const { t } = useTranslation()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]
  return (
    <>
      <Bg />
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, JSBI.BigInt(0)) ? (
        <GreyCardCus style={{ background: theme.liquidityCardBg, borderRadius: "0px 0px 24px 24px" }} border={border}>
          <AutoColumn gap="12px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={400} fontSize={14} color={"#fff"}>
                  {t('yourPosition')}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRowMobile onClick={() => setShowMore(!showMore)}>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={30} />
                <Text fontWeight={400} fontSize={16} color={"#fff"}>
                  {currency0.symbol}/{currency1.symbol}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontWeight={"bold"} fontSize={16} color={"#fff"} style={{marginTop: isMobile ? '0.5rem' : ''}}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRowMobile>
            <AutoColumn gap="4px">
              <FixedHeightRowCus>
                <Text fontSize={16} fontWeight={400} color={"#fff"}>
                  {t('yourPoolShare2')}
                </Text>
                <Text fontSize={16} fontWeight={"bold"} color={"#fff"}>
                  {poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
                </Text>
              </FixedHeightRowCus>
              <FixedHeightRowCus>
                <Text fontSize={16} fontWeight={400} color={"#fff"}>
                  {currency0.symbol}:
                </Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={"bold"} marginLeft={'6px'} color={"#fff"}>
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRowCus>
              <FixedHeightRowCus style={{marginBottom: '-1.25rem', border: 'none'}}>
                <Text fontSize={16} fontWeight={400} color={"#fff"}>
                  {currency1.symbol}:
                </Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={"bold"} marginLeft={'6px'} color={"#fff"}>
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRowCus>
            </AutoColumn>
          </AutoColumn>
        </GreyCardCus>
      ) : (
        <LightCardCus style={{boxShadow:'2px 2px 8px #0000002e',background:'white'}}>
          <TYPE.subHeader style={{ color: "#fff", fontSize: "14px", lineHeight: "22px" }}>
            {/* <span role="img" aria-label="wizard-icon">
              ⭐️
            </span>{' '} */}
            By adding liquidity you&apos;ll earn 0.25% of all trades on this pair proportional to your share of the pool.
            Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
          </TYPE.subHeader>
        </LightCardCus>
      )}
    </>
  )
}

export function MinimalPositionCardV1({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const { t } = useTranslation()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, JSBI.BigInt(0)) ? (
        <GreyCardCus style={{ background: "rgba(255, 255, 255, 0.05)", borderRadius: "0px 0px 24px 24px", position: "absolute", top: "332px", width: "90%", left: "5%" }} border={border}>
          <AutoColumn gap="12px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={400} fontSize={14} color={"#fff"}>
                  {t('yourPosition')}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow onClick={() => setShowMore(!showMore)}>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={30} />
                <Text fontWeight={400} fontSize={16} color={"#fff"}>
                  {currency0.symbol}/{currency1.symbol}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontWeight={"bold"} fontSize={16} color={"#fff"}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRowCus>
                <Text fontSize={16} fontWeight={400} color={"#fff"}>
                  {t('yourPoolShare2')}
                </Text>
                <Text fontSize={16} fontWeight={"bold"} color={"#fff"}>
                  {poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
                </Text>
              </FixedHeightRowCus>
              <FixedHeightRowCus>
                <Text fontSize={16} fontWeight={400} color={"#fff"}>
                  {currency0.symbol}:
                </Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={"bold"} marginLeft={'6px'} color={"#fff"}>
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRowCus>
              <FixedHeightRowCus>
                <Text fontSize={16} fontWeight={400} color={"#fff"}>
                  {currency1.symbol}:
                </Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={"bold"} marginLeft={'6px'} color={"#fff"}>
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRowCus>
            </AutoColumn>
          </AutoColumn>
        </GreyCardCus>
      ) : (
        <LightCardCus style={{boxShadow:'2px 2px 8px #0000002e',background:'white'}}>
          <TYPE.subHeader style={{ color: "#fff", fontSize: "14px", lineHeight: "22px" }}>
            {/* <span role="img" aria-label="wizard-icon">
              ⭐️
            </span>{' '} */}
            By adding liquidity you&apos;ll earn 0.25% of all trades on this pair proportional to your share of the pool.
            Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
          </TYPE.subHeader>
        </LightCardCus>
      )}
    </>
  )
}

const LightCardCus = styled(LightCard)`
  background: ${({theme}) => theme.lighCardBg} !important;
  border-radius: 0px 0px 24px 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
`

const GreyCardCus = styled(GreyCard)`
  border: 1px solid rgba(255, 255, 255, 0.2);
`

const FixedHeightRowCus = styled(FixedHeightRow)`
  min-height: 40px;
  border-bottom: 1px solid #39464A;
`

const FixedHeightRowMobileMr = styled(FixedHeightRow)`
  margin-bottom: ${ isMobile ? '1rem' : ''}
`

const TextEndMobile = styled(Text)`
  text-align: ${ isMobile ? 'end' : ''}
`

export default function FullPositionCard({ pair, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]

  const backgroundColor = useColor(pair?.token0)

  return (
    <StyledPositionCard border={border} bgColor={backgroundColor}>
      <CardNoise />
      <AutoColumn gap="12px">
        <FixedHeightRow>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={36} />
            <Text fontWeight={500} fontSize={16} color={"#fff"}>
              {!currency0 || !currency1 ? <Dots>{t('loading')}</Dots> : `${currency0.symbol}/${currency1.symbol}`}
            </Text>
          </RowFixed>

          <RowFixed gap="8px">
            <ButtonEmpty
              color={"#fff"}
              padding="6px 8px"
              borderRadius="12px"
              width="fit-content"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? (
                <>
                  {' '}
                  <TYPE.white color={"#fff"}>{t('manage')}</TYPE.white>
                  <ChevronUp size="20" style={{ marginLeft: '10px', color: '#fff' }} />
                </>
              ) : (
                <>
                  <TYPE.white color={"#fff"}>{t('manage')}</TYPE.white>
                  <ChevronDown size="20" style={{ marginLeft: '10px', color: "fff" }} />
                </>
              )}
            </ButtonEmpty>
          </RowFixed>
        </FixedHeightRow>

        {showMore && (
          <AutoColumn gap="8px" style={{paddingTop: isMobile ? '1rem' : ''}}>
            <Bg />
            <FixedHeightRowMobileMr>
              <Text fontSize={16} fontWeight={500}>
                {t('yourPoolTokens')}
              </Text>
              <TextEndMobile fontSize={16} fontWeight={"bold"}>
                {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
              </TextEndMobile>
            </FixedHeightRowMobileMr>
            <FixedHeightRowMobileMr>
              <RowFixed>
                <Text fontSize={16} fontWeight={500} color={"#fff"}>
                  {t('pooled')} {currency0.symbol}:
                </Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <TextEndMobile fontSize={16} fontWeight={"bold"} marginLeft={'6px'}>
                    {token0Deposited?.toSignificant(6)} <span style={{ fontSize: "14px", color: "#BDC1C2" }}>{currency0.symbol}</span>
                  </TextEndMobile>
                  {/* <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} /> */}
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRowMobileMr>

            <FixedHeightRowMobileMr>
              <RowFixed>
                <Text fontSize={16} fontWeight={500} color={"#fff"}>
                {t('pooled')} {currency1.symbol}:
                </Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <TextEndMobile fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {token1Deposited?.toSignificant(6)} <span style={{ fontSize: "14px", color: "#BDC1C2" }}>{currency1.symbol}</span>
                  </TextEndMobile>
                  {/* <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} /> */}
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRowMobileMr>

            <FixedHeightRowMobileMr>
              <Text fontSize={16} fontWeight={500} color={"#FFC70B"}>
                {t('yourPoolShare')}
              </Text>
              <TextEndMobile fontSize={16} fontWeight={"bold"} color={"#FFC70B"}>
                {poolTokenPercentage ? poolTokenPercentage.toFixed(2) + '%' : '-'}
              </TextEndMobile>
            </FixedHeightRowMobileMr>

            {/* <ButtonSecondary padding="8px" borderRadius="8px">
              <ExternalLink
                style={{ width: '100%', textAlign: 'center' }}
                href={`https://uniswap.info/account/${account}`}
              >
                View accrued fees and analytics<span style={{ fontSize: '11px' }}>↗</span>
              </ExternalLink>
            </ButtonSecondary> */}
            <RowBetween marginTop="10px">
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                as={Link}
                to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                width="48%"
              >
                {t('add')}
              </ButtonPrimary>
              <ButtonPrimaryCus
                padding="8px"
                borderRadius="8px"
                as={Link}
                width="48%"
                to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
              >
                {t('remove')}
              </ButtonPrimaryCus>
            </RowBetween>
          </AutoColumn>
        )}
      </AutoColumn>
    </StyledPositionCard>
  )
}

const ButtonPrimaryCus = styled(ButtonPrimary)`
  background-color: rgba(66, 181, 217, 0);
  border: 1px solid #42B5D9;
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`