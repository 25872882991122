import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { Trade, TokenAmount, CurrencyAmount, ETHER } from '@huckleberry/sdk'
import { useCallback, useMemo, useState, useEffect } from 'react'
import { ROUTER_ADDRESS, SUGGEST_GAS_PRICE } from '../constants'
import { useTokenAllowance } from '../data/Allowances'
import { getTradeVersion, useV1TradeExchangeAddress } from '../data/V1'
import { Field } from '../state/swap/actions'
import { useTransactionAdder, useHasPendingApproval } from '../state/transactions/hooks'
import { computeSlippageAdjustedAmounts } from '../utils/prices'
import { calculateGasMargin } from '../utils'
import { useTokenContract } from './useContract'
import { useActiveWeb3React } from './index'
import { Version } from './useToggledVersion'
import useLend, { DepositMarketsItem, MarketItem } from '../state/lend/hooks'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount,
  spender?: string
): [ApprovalState, () => Promise<void>] {
  const { account } = useActiveWeb3React()
  const token = amountToApprove instanceof TokenAmount ? amountToApprove.token : undefined
  const currentAllowance = useTokenAllowance(token, account ?? undefined, spender)
  const pendingApproval = useHasPendingApproval(token?.address, spender)
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency === ETHER) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pendingApproval, spender])

  const tokenContract = useTokenContract(token?.address)
  const addTransaction = useTransactionAdder()

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }
    if (!token) {
      console.error('no token')
      return
    }

    if (!tokenContract) {
      console.error('tokenContract is null')
      return
    }

    if (!amountToApprove) {
      console.error('missing amount to approve')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    let useExact = false
    const estimatedGas = await tokenContract.estimateGas.approve(spender, MaxUint256).catch(() => {
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return tokenContract.estimateGas.approve(spender, amountToApprove.raw.toString())
    })

    return tokenContract
      .approve(spender, useExact ? amountToApprove.raw.toString() : MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas), gasPrice: SUGGEST_GAS_PRICE()
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Approve ' + amountToApprove.currency.symbol,
          approval: { tokenAddress: token.address, spender: spender }
        })
      })
      .catch((error: Error) => {
        console.debug('Failed to approve token', error)
        throw error
      })
  }, [approvalState, token, tokenContract, amountToApprove, spender, addTransaction])

  return [approvalState, approve]
}

// wraps useApproveCallback in the context of a swap
export function useApproveCallbackFromTrade(trade?: Trade, allowedSlippage = 0) {
  const amountToApprove = useMemo(
    () => (trade ? computeSlippageAdjustedAmounts(trade, allowedSlippage)[Field.INPUT] : undefined),
    [trade, allowedSlippage]
  )
  const tradeIsV1 = getTradeVersion(trade) === Version.v1
  const v1ExchangeAddress = useV1TradeExchangeAddress(trade)
  return useApproveCallback(amountToApprove, tradeIsV1 ? v1ExchangeAddress : ROUTER_ADDRESS)
}

export function useLendApproveCallback(info: DepositMarketsItem | MarketItem): [ApprovalState, () => Promise<void>] {
  const { personalAccount, isAuthorized } = useLend();
  const [approvalState, setApproveState] = useState<ApprovalState>(ApprovalState.PENDING)
  const max = `0x${'f'.repeat(64)}`;
  const pendingApproval = useHasPendingApproval(info?.token_address, info?.token_address)
  useEffect(() => {
    if (info.underlying_symbol === 'CLV') {
      setApproveState(ApprovalState.APPROVED);
      return;
    }

    if (!personalAccount) {
      setApproveState(ApprovalState.PENDING);
      return;
    };
    isAuthorized(personalAccount, info)
      .then(res => {
        // console.log(info, res, ApprovalState)
        if (res) {
          setApproveState(ApprovalState.APPROVED)
        } else {
          if (pendingApproval) {
            setApproveState(ApprovalState.PENDING);
          } else {
            setApproveState(ApprovalState.NOT_APPROVED)
          }
        }
      })
      .catch(err => {
        setApproveState(ApprovalState.UNKNOWN)
      })
  }, [info, isAuthorized, pendingApproval, personalAccount]);

  const tokenContract = useTokenContract(info?.underlying_symbol === 'CLV' ? void 0 : info?.underlying_address);
  const addTransaction = useTransactionAdder()

  const approveCallback = useCallback(async (): Promise<void> => {
    if (!tokenContract) {
      console.error('tokenContract is null');
      return;
    }
    if (!info) {
      console.error('no token')
      return
    }
    const estimatedGas = await tokenContract.estimateGas.approve(info.token_address, MaxUint256).catch(() => {
      // general fallback for tokens who restrict approval amounts
      return tokenContract.estimateGas.approve(info.token_address, max)
    })
    return tokenContract
      .approve(info.token_address, max, {
        gasLimit: calculateGasMargin(estimatedGas), gasPrice: SUGGEST_GAS_PRICE()
      })
      .then((response: TransactionResponse) => {
        console.log('response', response)
        addTransaction(response, {
          summary: 'Approve ' + info.underlying_symbol,
          approval: { tokenAddress: info.token_address, spender: info.token_address }
        })
      })
      .catch((error: Error) => {
        console.debug('Failed to approve token', error)
        throw error
      })
  }, [addTransaction, info, max, tokenContract])

  return [approvalState, approveCallback]
}
// `0x${'f'.repeat(64)}`
