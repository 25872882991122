/* eslint-disable react/jsx-pascal-case */
import React, { Dispatch, SetStateAction, useMemo, useState, useEffect, useContext } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled, { ThemeContext } from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
import { useBridgeMinerContract } from '../../hooks/useContract'
import { SubmittedView, LoadingView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { calculateGasMargin } from '../../utils'
import { TokenAmount } from '@huckleberry/sdk'
import DoubleCurrencyLogo from '../DoubleLogo'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { POOLNAME } from './PoolCard'
import { SUGGEST_GAS_PRICE } from '../../constants'
import { isMobile } from 'react-device-detect'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 2rem;
  /* overflow-y: scroll; */
  cursor: pointer;
  background: ${({ theme }) => theme.lighCardBg}
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfos: StakingInfo[]
}

export default function ClaimRewardModal({ isOpen, onDismiss, stakingInfos }: StakingModalProps) {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string[] | undefined>()
  const [attempting, setAttempting] = useState(false)
  const [finish, setFinish] = useState(false)

  function wrappedOnDismiss() {
    setFinish(false)
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const bridgeMinerContract = useBridgeMinerContract()

  const filterStakingInfos = useMemo(() => stakingInfos.filter((stakingInfo, index) => {
    const token0 = stakingInfo.tokens[0]
    const token1 = stakingInfo.tokens[1]
  
    const currency0 = unwrappedToken(token0)
    const currency1 = unwrappedToken(token1)
    stakingInfo.name = index < POOLNAME.length ? POOLNAME[index] : `${currency0.symbol} / ${currency1.symbol}`
    return !!stakingInfo.earnedAmount && stakingInfo.earnedAmount.greaterThan('0')
  }), [stakingInfos])
  // @ts-ignore
  const [claimObj, setClaimObj] = useState<{ [key: string]: boolean }[]>(filterStakingInfos.map(i => ({ [i.name]: true })))
  // @ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterStakingInfosChecked = useMemo(() => filterStakingInfos.filter((item, index) => claimObj[index][item.name]), [claimObj])

  // const selectAll = useMemo(() => {
  //   const values =  claimObj.map(i => Object.values(i))
  //   return values.flat().every(i => i)
  // }, [claimObj])

  async function onClaimReward() {
    let cancelTx = 0;
    if (bridgeMinerContract && unclaimedFINN) {
      setAttempting(true)
      let hashArr: string[] = [];
      const promiseAll = filterStakingInfosChecked.map(async stakingInfo => {
        let gas = await bridgeMinerContract.estimateGas['withdraw'](stakingInfo.pid, '0x0');
        return bridgeMinerContract.withdraw(stakingInfo.pid, '0x0', {gasLimit: calculateGasMargin(gas), gasPrice: SUGGEST_GAS_PRICE()}).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Claim accumulated FINN rewards`
          })
          hashArr.push(response.hash)
          if (cancelTx + hashArr.length === filterStakingInfosChecked.length) {
            setFinish(true)
          }
        }).catch(() => {
          ++cancelTx;
          if (cancelTx === filterStakingInfosChecked.length) {
            wrappedOnDismiss()
            return;
          }
          if (hashArr.length + cancelTx === filterStakingInfosChecked.length) {
            setFinish(true)
          }
        })
      })
      await Promise.all(promiseAll).then(() => {
        //@ts-ignore
        setHash(hashArr)
        setAttempting(false)
      })
    }
  }

  const unclaimedFINN = useMemo(() => {
    if (stakingInfos[0]?.rewardRate.token) {
      let sum: TokenAmount = new TokenAmount(stakingInfos[0].rewardRate.token, '0')
      stakingInfos.forEach(val =>  sum = val.earnedAmount.add(sum))
      return sum
    } else {
      return '0'
    }
  }, [stakingInfos])

  const unclaimedFINNChecked = useMemo(() => {
    if (filterStakingInfos[0]?.rewardRate.token) {
      let sum: TokenAmount = new TokenAmount(filterStakingInfos[0].rewardRate.token, '0')
      filterStakingInfos.forEach((val, index) => {
        if (val.name && claimObj[index] && claimObj[index][val.name]) {
          sum = val.earnedAmount.add(sum)
        }
      })
      return sum
    } else {
      return '0'
    }
  }, [claimObj, filterStakingInfos])

  const allStakedAmount = useMemo(() => {
    return stakingInfos.some(i => !!i?.stakedAmount)
  }, [stakingInfos])

  let error: string | undefined
  if (!account) {
    error = t('connectWallet')
  }

  if (!allStakedAmount) {
    error = error ?? t('enterAnAmount')
  }

  const SelectAll = (e: any) => {
    let newObj = claimObj.map(i => {
      return { [Object.keys(i)[0]]: e.target.checked }
    });
    setClaimObj(newObj)
  }

  const AllCheckedStatus = useMemo(() => {
    let tmp = claimObj.map(i => Object.values(i)[0])
    return tmp.every(i => i)
  }, [claimObj])

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90} bg={"transparent"} border={"1px solid rgba(255, 255, 255, 0.2)"}>
      <Bg />
      {!attempting && !(hash && hash.length !== 0) && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader color={"#fff"}>Claim</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          {unclaimedFINN && (
              <RowBetweenCus>
                <RowBetween>
                  <TYPE.body color={"#fff"}>Selected Unclaimed FINN</TYPE.body>
                  <Input type="checkbox" checked={AllCheckedStatus} onClick={(e) => SelectAll(e)}/>
                </RowBetween>
                <Gap />
                <RowBetween>
                  <TYPE.body>
                    {unclaimedFINNChecked === '0' ? '0' : unclaimedFINNChecked.toSignificant(6)} FINN
                  </TYPE.body>
                  <TYPE.body color={"#999"}>Selected All</TYPE.body>
                </RowBetween>
              </RowBetweenCus>
          )}

          <Contai>
          {
            filterStakingInfos.length > 0 && (
              filterStakingInfos?.map((stakingInfo, index) => {
                return <ClaimPoolCard key={index} claimObj={claimObj} setClaimObj={setClaimObj} index={index} stakingInfo={stakingInfo}/>
              })
            )
          }
          <div style={{ height: "5px" }}></div>
          </Contai>
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            When you claim without withdrawing your liquidity remains in the mining pool.
          </TYPE.subHeader>
          <ButtonError disabled={!!error || unclaimedFINNChecked === '0' || unclaimedFINN === '0' || !unclaimedFINNChecked.greaterThan('0') || !unclaimedFINN.greaterThan('0')} error={!!error && allStakedAmount} onClick={onClaimReward}>
            {error ?? 'Claim'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !(hash && hash.length === filterStakingInfosChecked.length) && (
        <LoadingView onDismiss={wrappedOnDismiss} bg={theme.finnbarModalBg}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Claiming {unclaimedFINNChecked === '0' ? '0' : unclaimedFINNChecked.toSignificant(6)} FINN</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (hash.length === filterStakingInfosChecked.length || finish) && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={undefined} bg={theme.finnbarModalBg}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.white color={theme.navDownTxt} fontSize={20}>Claimed FINN!</TYPE.white>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}

const ClaimPoolCard = function({ index, stakingInfo, setClaimObj, claimObj, key }: { key: number, stakingInfo: StakingInfo, index: number, claimObj: object[], setClaimObj: Dispatch<SetStateAction<{}[]>>}) {
  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]

  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)

  const handleClick = (e: any) => {
    // @ts-ignore
    claimObj[index] = { [stakingInfo.name]: e.target.checked }
    setClaimObj(claimObj.map(i => i))
  }

  useEffect(() => {
    // @ts-ignore
    claimObj[index] = { [stakingInfo.name]: true }
    setClaimObj(claimObj.map(i => i))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkedValue = useMemo(() => {
    if (claimObj && claimObj[index] && stakingInfo.name) {
      //@ts-ignore
      return !!claimObj[index][stakingInfo.name]
    } else {
      return false
    }
  }, [claimObj, index, stakingInfo.name])

  return (
    <ConWrap>
      <RowBetween>
        <TYPE.white fontFamily={"Pacifico-Regular"} fontWeight={400} fontSize={24} >
          <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={36} />
          <span style={{ display: "inline-block", marginLeft: "10px", position: "relative", top: "-8px" }}>
            { stakingInfo.name }
          </span>
        </TYPE.white>
        <span><Input type="checkbox" checked={checkedValue} onClick={e => handleClick(e)} /></span>
      </RowBetween>
      <Gap />
      <RowBetween>
        <TYPE.body color={"#999"}>Unclaimed FINN</TYPE.body>
        <TYPE.body>{stakingInfo.earnedAmount.toSignificant(6)} FINN</TYPE.body>
      </RowBetween>
    </ConWrap>
  )
}

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  filter: blur(50px);
  background-image: url('./images/${isMobile && 'mobile_'}christmas_bg.svg');
  background-position: center top;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.05);
  background-attachment: fixed;
  z-index: -1;
`

const RowBetweenCus = styled(RowBetween)`
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1rem;
  border-radius: 16px;
  flex-direction: column;
`

const ConWrap = styled.div`
  background: rgba(0, 0, 0, 0);
  border-radius: 16px;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 10px;
`
const Gap = styled.div`
  height: 10px;
`

const Input = styled.input`
  position: relative;
  background-color: transparent;
  width: 15px;
  height: 15px;
  border: 2px solid #637479;
  outline: 0;
  border-radius: 2px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  &[type="checkbox"]:checked {
    background-color: #41B5D8;
    text-align: center;
    border: none;
    background-clip: padding-box;
    color: #fff;
    cursor: pointer;
  }
  &[type="checkbox"]:checked:after {
    cursor: pointer;
    content: "✓";
    font-size: 20px;
    top: -4.5px;
    position: relative;
    left: -1px;
  }
`
const Contai = styled.div`
  overflow-y: scroll
`