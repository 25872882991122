import { ChainId, TokenAmount } from '@huckleberry/sdk'
import React, { useEffect, useMemo, useState } from 'react'
import { X } from 'react-feather'
import styled, { css } from 'styled-components'
import tokenLogo from '../../assets/png/FINN/02.png'

import { FINN } from '../../constants'
import { useTotalBurned } from '../../data/TotalSupply'
// import { useTotalSupply, useTotalBurned, useNotMined } from '../../data/TotalSupply'
import { useActiveWeb3React } from '../../hooks'
import useCurrentBlockTimestamp from '../../hooks/useCurrentBlockTimestamp'
import { useTotalUniEarned } from '../../state/stake/hooks'
import { useAggregateUniBalance, useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, TYPE, UniTokenAnimated } from '../../theme'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { AutoColumn } from '../Column'
import { RowBetween, AutoRow } from '../Row'
import { Break, CardBGImage, CardNoise, CardSection, DataCard } from '../earn/styled'
import { useTranslation } from 'react-i18next'
import { ButtonConfirmed } from '../Button'
import { BRIDGE_TOKEN_ADDRESS } from '../../constants/abis/bridge'
// import useReflectionAPR from '../../hooks/reflection/useReflectionAPY'
import { isMobile } from 'react-device-detect' 
import { useMedia } from 'react-use'
import axios from 'axios';

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
`

const MobileCustom2 = styled(TYPE.custom2)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    ${isMobile && css`
      color: #000 !important;
      font-size: 0.875rem;
    `}
  `}
`

const MobileBlack = styled(TYPE.black_main)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    ${isMobile && css`
      color: #42B5D9 !important;
      font-size: 0.875rem;
    `}
  `}
`

const MobileBorderRowBetween = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    ${isMobile && css`
      padding-bottom: 0.9rem;
      border-bottom: 1px solid rgba(0, 0, 0, .05);
    `}
  `}
`

const MobileAutoColumn = styled(AutoColumn)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    ${isMobile && css`
      background: rgba(66, 181, 217, 0.06);
      border-radius: 0.75rem;
      padding: 1rem 1.25rem;
    `}
  `}
`

const ModalUpper = styled(DataCard)`
  box-shadow: rgba(0, 0, 0, 0.18) 10px 8px 15px 5px, rgba(255, 255, 255, 0.565) -8px -10px 15px 5px;
  background: #fff;
  padding: 0.5rem;
`

const StyledClose = styled(X)`
  position: absolute;
  right: 16px;
  top: 16px;

  :hover {
    cursor: pointer;
  }
`

/**
 * Content for balance stats modal
 */
export default function UniBalanceContent({ setShowUniBalanceModal }: { setShowUniBalanceModal: any }) {
  const { account, chainId } = useActiveWeb3React()
  const uni = chainId ? FINN[chainId] : undefined

  const { t } = useTranslation()
  const bellow720 = useMedia('(max-width: 720px)')

  const total = useAggregateUniBalance()
  const uniBalance: TokenAmount | undefined = useTokenBalance(account ?? undefined, uni)
  const uniToClaim: TokenAmount | undefined = useTotalUniEarned()

  // const totalSupply: TokenAmount | undefined = useTotalSupply(uni)
  const totalBurned: TokenAmount | undefined = useTotalBurned(uni)
  // const notMined: TokenAmount | undefined = useNotMined(uni)
  const uniPrice = useUSDCPrice(uni)
  const blockTimestamp = useCurrentBlockTimestamp()
  // const unclaimedUni = useTokenBalance(useMerkleDistributorContract()?.address, uni)
  // const circulation: TokenAmount | undefined = useMemo(
  //   () =>
  //     blockTimestamp && uni && chainId === ChainId.CLOVER_PARACHAIN
  //       ? totalSupply?.subtract(totalBurned ? totalBurned : new TokenAmount(uni!, '0'))?.subtract(notMined ? notMined : new TokenAmount(uni!, '0'))
  //       : totalSupply?.subtract(totalBurned ? totalBurned : new TokenAmount(uni!, '0'))?.subtract(notMined ? notMined : new TokenAmount(uni!, '0')),
  //   [blockTimestamp, chainId, totalSupply, uni, totalBurned, notMined]
  // )

  const burned: TokenAmount | undefined = useMemo(
    () =>
      blockTimestamp && uni && chainId === ChainId.CLOVER_PARACHAIN
        ? totalBurned
        : totalBurned,
    [blockTimestamp, chainId, totalBurned, uni]
  )

  const [circleMoonriver, setCircleMoonriver] = useState('0');
  const [circleClover, setCircleClover] = useState('0');

  useEffect(() => {
    (async () => {
      let ret = await axios.get('https://api.huckleberry.finance/api/clover/circulatesupply');
      setCircleClover(ret.data);
      ret = await axios.get('https://api.huckleberry.finance/api/moonriver/circulatesupply');
      setCircleMoonriver(ret.data);
    })();
  }, []);

  // const apy = useReflectionAPR();

  return (
    <ContentWrapper gap="lg">
      <ModalUpper>
        <CardBGImage />
        <CardNoise />
        <CardSectionCus gap="md">
          <RowBetween style={{ justifyContent: 'left' }}>
            <TYPE.black_main style={{ fontSize: 20, fontWeight: 'bold' }}>{t('breakdown')}</TYPE.black_main>
            <StyledCloseCus stroke="black" onClick={() => setShowUniBalanceModal(false)} />
          </RowBetween>
        </CardSectionCus>
        <Break />
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto'}}>
          {account && (
            <>
              <CardSection gap="sm">
                <AutoColumn gap="md" justify="center">
                  <div style={{ position: 'relative' }}>
                    <img src={tokenLogo} alt={'icon'} style={{ width: 120, height: 120, position: 'absolute', filter: 'drop-shadow(rgba(0, 0, 0, 0.3) 8px 8px 10px) drop-shadow(rgba(255, 255, 255, 0.565) -5px -5px 10px)' }} />
                    <UniTokenAnimated width="120px" src={tokenLogo} />{' '}
                  </div>
                  <TYPE.custom1 fontSize={48} fontWeight={600} >
                    {total?.toFixed(2, { groupSeparator: ',' })}
                  </TYPE.custom1>
                </AutoColumn>
                <MobileAutoColumn gap="md">
                  <MobileBorderRowBetween>
                    <MobileCustom2>{t('balance2')}</MobileCustom2>
                    <MobileBlack >{uniBalance?.toFixed(2, { groupSeparator: ',' })}</MobileBlack>
                  </MobileBorderRowBetween>
                  <RowBetween>
                    <MobileCustom2>{t('unclaimed')}:</MobileCustom2>
                    <MobileBlack>
                      {uniToClaim?.toFixed(2, { groupSeparator: ',' })}{' '}
                    </MobileBlack>
                  </RowBetween>
                </MobileAutoColumn>
              </CardSection>
              {
                bellow720 && isMobile ?
                  null
                :
                  <Break />
              }
            </>
          )}
          <CardSection gap="sm" style={{marginTop: bellow720 && isMobile ? '-3rem' : ''}}>
            <MobileAutoColumn gap="md">
              {/* <RowBetween>
                <TYPE.custom2>{t('finnPrice')}</TYPE.custom2>
                <TYPE.black_main>${uniPrice?.toFixed(4) ?? '-'}</TYPE.black_main>
              </RowBetween> */}
              {/* <MobileBorderRowBetween>
                <MobileCustom2>{t('FINN Passive Sailing APY')}:</MobileCustom2>
                <MobileBlack>{isNaN(Number(apy)) ? 'Loading...' : (Number(apy).toFixed(0) + '%')}</MobileBlack>
              </MobileBorderRowBetween> */}
              <MobileBorderRowBetween>
                <MobileCustom2>{t('FINN Market Cap')}:</MobileCustom2>
                <MobileBlack>${uniPrice && circleClover && new TokenAmount(uni!, ((Number(circleClover) + Number(circleMoonriver)) * Number(uniPrice?.toFixed(4))).toFixed(0)).multiply('1000000000000000000').toFixed(0, { groupSeparator: ',' })}</MobileBlack>
              </MobileBorderRowBetween>
              <MobileBorderRowBetween>
                <MobileCustom2>{'FINN in Circulation (on CLV):'}</MobileCustom2>
                <MobileBlack>{uni && circleClover && (new TokenAmount(uni, circleClover)).multiply('1000000000000000000').toFixed(0, { groupSeparator: ',' })}</MobileBlack>
              </MobileBorderRowBetween>
              <MobileBorderRowBetween>
                <MobileCustom2>{'FINN in Circulation (on Moonriver):'}</MobileCustom2>
                <MobileBlack>{uni && circleMoonriver && (new TokenAmount(uni, circleMoonriver)).multiply('1000000000000000000').toFixed(0, { groupSeparator: ',' })}</MobileBlack>
              </MobileBorderRowBetween>
              <MobileBorderRowBetween>
                <MobileCustom2>{t('FINN Max Supply')}:</MobileCustom2>
                <MobileBlack>100,000,000</MobileBlack>
              </MobileBorderRowBetween>
              <RowBetween>
                <MobileCustom2>{t('FINN Emission Rate')} (on CLV):</MobileCustom2>
                <MobileBlack>22.8K per day</MobileBlack>
              </RowBetween>
              <RowBetween>
                <MobileCustom2>{t('FINN Emission Rate')} (on Moonriver):</MobileCustom2>
                <MobileBlack>54.8K per day</MobileBlack>
              </RowBetween>
              <RowBetween>
                <MobileCustom2>{t('FINN Burn Rate')} (on Moonriver):</MobileCustom2>
                <MobileBlack>22.8K per day</MobileBlack>
              </RowBetween>
              
              {false && <RowBetween>
                <MobileCustom2>{t('finnBurned')}</MobileCustom2>
                <MobileBlack>{burned?.toFixed(0, { groupSeparator: ',' })}</MobileBlack>
              </RowBetween>}
              {/* <RowBetween>
                <TYPE.black_main>{t('totalSupply')}</TYPE.black_main>
                <TYPE.black_main>{totalSupply?.toFixed(0, { groupSeparator: ',' })}</TYPE.black_main>
              </RowBetween> */}
              {uni && uni.chainId === ChainId.CLOVER_PARACHAIN ? (
                <AutoRow>
                  <ExternalLink href={`https://clvscan.com/token/${uni.address}`}>View FINN Token Information</ExternalLink>
                  <ExternalLink href='https://www.coingecko.com/en/coins/huckleberry'>
                    <img style={{width: '24px', height: '24px', marginLeft: '10px'}} src='./images/coingecko.png' alt=""  />
                  </ExternalLink>
                </AutoRow>
              ) : null}
            </MobileAutoColumn>
            <ButtonConfirmed style={{marginTop: "10px"}} onClick={()=>{
              const tokenAddress = uni ? BRIDGE_TOKEN_ADDRESS[uni?.chainId] : BRIDGE_TOKEN_ADDRESS[1024];
              const tokenSymbol = 'FINN';
              const tokenDecimals = 18;
              const tokenImage = 'https://token-icons.vercel.app/icon/others/FINN.png';
              if (!window.ethereum) {
                return;
              }
              // wasAdded is a boolean. Like any RPC method, an error may be thrown.
              (window.ethereum as any).request({
                method: 'wallet_watchAsset',
                params: {
                  type: 'ERC20', // Initially only supports ERC20, but eventually more!
                  options: {
                    address: tokenAddress, // The address that the token is at.
                    symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                    decimals: tokenDecimals, // The number of decimals in the token
                    image: tokenImage, // A string url of the token logo
                  },
                },
              }).then((ret:any)=>{
                console.log(ret);
              }).catch((err:any)=>{
                console.error(err);
              });
            }} >Add FINN to Metamask</ButtonConfirmed>
          </CardSection>
        </div>
      </ModalUpper>
    </ContentWrapper>
  )
}

const CardSectionCus = styled(CardSection)`
  padding: 1rem;
`

const StyledCloseCus = styled(StyledClose)`
  top: 23px;
`