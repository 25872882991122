import React, { useEffect, useRef } from 'react'

import styled from 'styled-components'

import { useActiveWeb3React } from '../../hooks'
import Jazzicon from 'jazzicon'

const StyledIdenticonContainer = styled.div<{width?: string, height?: string, borderRadius?: string}>`
  height: ${({ height }) => height ? height : '1rem'};
  width: ${({ width }) => width ? width : '1rem'};
  border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '10px'};
  background-color: ${({ theme }) => theme.bg4};
`

export default function Identicon({width, height, borderRadius, iconSize}:{width?: string, height?: string, borderRadius?: string, iconSize?: number}) {
  const ref = useRef<HTMLDivElement>()
  const { account } = useActiveWeb3React()

  useEffect(() => {
    if (account && ref.current) {
      ref.current.innerHTML = ''
      const size = iconSize ? Number(iconSize) : 16
      ref.current.appendChild(Jazzicon(size, parseInt(account.slice(2, 10), size)))
    }
  }, [account, iconSize])
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
  return <StyledIdenticonContainer width={width} height={height} borderRadius={borderRadius} ref={ref as any} />
}
